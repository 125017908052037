import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography, useTheme } from '@mui/material';

import { ReactComponent as SvgEnergy } from '../../assets/symbols/energy.svg';

export const XpGauge = ({
  xp,
  max,
  xpProps,
  maxProps,
  iconProps,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center" {...props}>
      <SvgEnergy
        width={24}
        height={24}
        color={theme.palette.icterine}
        {...iconProps}
      />
      <Typography
        ml={0.5}
        fontSize={32}
        lineHeight={1.25}
        color="white"
        {...xpProps}
      >
        {xp + (max === undefined ? ' XP' : '')}
      </Typography>
      {max !== undefined && (
        <Typography
          fontSize={32}
          lineHeight={1.25}
          color="shadowBlue"
          {...maxProps}
        >
          &nbsp;/&nbsp;{max} XP
        </Typography>
      )}
    </Stack>
  );
};

XpGauge.propTypes = {
  xp: PropTypes.any,
  max: PropTypes.any,
  xpProps: PropTypes.any,
  maxProps: PropTypes.any,
  iconProps: PropTypes.any,
};

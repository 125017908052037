import React, { useCallback, useEffect, useState } from 'react';
import { Scaffold } from '../../../components/layouts/Scaffold';
import {
  Box,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SelectChoiceGroup } from '../../../components/inputs/SelectChoiceGroup';
import { XpIndicator } from '../../../components/core/XpIndicator';
import { ArrowLeftIcon } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import {
  apiAnswerDemoQuestion,
  apiAnswerQuestion,
  apiGetChapters,
  apiGetDemoChapters,
  apiGetDemoLessons,
  apiGetDemoQuestions,
  apiGetLessons,
  apiGetQuestions,
} from '../../../apis/l2e';

export const L2ELessonQuestionsPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const chapterIndex = params?.chapterIndex
    ? parseInt(params.chapterIndex)
    : null;
  const lessonIndex = params?.lessonIndex ? parseInt(params.lessonIndex) : null;

  const isAnonymousLogin = useSelector((state) => state.auth.isAnonymousLogin);

  const { data: chapter } = useQuery({
    queryKey: ['l2e', 'chapters', chapterIndex],
    queryFn: async () => {
      const data = isAnonymousLogin
        ? await apiGetDemoChapters()
        : await apiGetChapters();
      if (data && data.length > chapterIndex) {
        return data[chapterIndex];
      } else {
        return null;
      }
    },
  });

  const { data: lesson } = useQuery({
    queryKey: ['l2e', 'chapters', chapterIndex, 'lessons', lessonIndex],
    queryFn: async () => {
      const data = isAnonymousLogin
        ? await apiGetDemoLessons(chapter._id)
        : await apiGetLessons(chapter._id);
      if (data && data.length > lessonIndex) {
        return data[lessonIndex];
      } else {
        return null;
      }
    },
    enabled: !!(chapter && chapter._id),
  });

  const { data: questions } = useQuery({
    queryKey: [
      'l2e',
      'chapters',
      chapterIndex,
      'lessons',
      lessonIndex,
      'questions',
    ],
    queryFn: async () => {
      const data = isAnonymousLogin
        ? await apiGetDemoQuestions(lesson._id)
        : await apiGetQuestions(lesson._id);
      return data;
    },
    enabled: !!(lesson && lesson._id),
  });

  const [page, setPage] = useState(0);
  const [values, setValues] = useState(Array(10).fill(null));
  const [loading, setLoading] = useState(false);
  const [xp, setXp] = useState(0);
  const [errors, setErrors] = useState([]);

  const handleBack = useCallback(() => {
    navigate(`/l2e/chapters/${chapterIndex}`, {
      replace: true,
    });
  }, [navigate, chapterIndex]);

  const handleSelectChoice = useCallback(
    (value) => {
      setValues((currentValues) => {
        const newValues = [...currentValues];
        newValues[page] = value;
        return newValues;
      });
    },
    [page],
  );

  const handleContinue = useCallback(async () => {
    const data = {
      questionId: questions[page]._id,
      answers: questions[page].answers.map((item) => ({
        _id: item._id,
        checked: values[page] === item._id,
      })),
    };

    setLoading(true);
    const result = isAnonymousLogin
      ? await apiAnswerDemoQuestion(data)
      : await apiAnswerQuestion(data);

    if (result === 'recaptcha') {
      enqueueSnackbar({
        message:
          'We have detected some bot activities in your session, please try again later',
        variant: 'error',
      });
    } else {
      if (result) {
        setXp(xp + result);
        setErrors([]);
        if (page < questions.length - 1) {
          setPage((prev) => prev + 1);
        } else {
          navigate('../rewards', {
            replace: true,
            state: {
              xp: xp,
            },
          });
        }
      } else {
        setErrors((prev) => [...prev, values[page]]);
      }
    }
    setLoading(false);
  }, [navigate, isAnonymousLogin, page, questions, values, xp]);

  useEffect(() => {
    if (questions) {
      if (questions[page].answered) {
        if (page < questions.length - 1) {
          setPage((prev) => prev + 1);
        } else {
          navigate('../rewards', {
            replace: true,
          });
        }
      }
    }
  }, [navigate, questions, page]);

  return (
    <Scaffold bgcolor="raisinBlack" height="100vh">
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={1}
          pb={2}
          minHeight={56}
          spacing={2}
        >
          <IconButton color="isabelline" size="small" onClick={handleBack}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography fontSize={10} lineHeight={1.6} color="isabelline">
            {page + 1} of {questions?.length ?? 1}
          </Typography>
          <Box flex={1}>
            <LinearProgress
              variant="determinate"
              value={questions ? ((page + 1) / questions.length) * 100 : 0}
              color="dark"
            />
          </Box>
          <XpIndicator />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        {questions ? (
          <Box px={3}>
            <Typography fontSize={32} lineHeight={1.25} color="white">
              {questions[page].question}
            </Typography>
            <Box mt={2}>
              <SelectChoiceGroup
                value={values[page]}
                options={questions[page].answers.map((answer) => ({
                  value: answer._id,
                  label: answer.answer,
                  error: errors.includes(answer._id),
                }))}
                onChange={handleSelectChoice}
              />
            </Box>
          </Box>
        ) : (
          <Box px={3}>
            <Skeleton variant="rounded" height={32} />
            <Skeleton
              variant="rounded"
              height={32}
              width="60%"
              sx={{
                mt: 1,
              }}
            />
            <Skeleton
              variant="rounded"
              height="40vh"
              sx={{
                mt: 2,
              }}
            />
          </Box>
        )}
      </Scaffold.Content>
      <Scaffold.Footer>
        <Stack direction="row" pb={2} px={3} pt={2} spacing={2}>
          <LoadingButton
            variant="contained"
            color="icterine"
            sx={{ flex: 2 }}
            loading={loading}
            disabled={values[page] === null}
            onClick={handleContinue}
          >
            Continue
          </LoadingButton>
        </Stack>
      </Scaffold.Footer>
    </Scaffold>
  );
};

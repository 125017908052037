import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Scaffold } from '../layouts/Scaffold';
import { XIcon } from 'lucide-react';
import { router } from '../../pages/router';

import imgOily from '../../assets/onboarding/oily-level-7.png';
import imgBadge from '../../assets/badges/badge-lvl7.png';
import imgBadgeNext from '../../assets/badges/badge-lvl8.png';

export const LevelUp7Dialog = ({ open, onClose }) => {
  const handleContinue = useCallback(() => {
    router.navigate('/about/token-launch');
    onClose();
  }, [onClose]);

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Scaffold bgcolor="levels.7.bgcolor" height="100vh">
        <Confetti recycle={false} numberOfPieces={1000} />
        <Scaffold.Header>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={2}
            pt={1}
            pb={2}
            minHeight={56}
          >
            <Box width={26} />
            <Typography fontSize={14} color="isabelline" lineHeight={1}>
              Level Completed
            </Typography>
            <IconButton color="isabelline" size="small" onClick={onClose}>
              <XIcon />
            </IconButton>
          </Stack>
        </Scaffold.Header>
        <Scaffold.Content>
          <Stack direction="row" justifyContent="center">
            <img src={imgOily} alt="Oily" />
          </Stack>
          <Box px={3}>
            <Typography
              mt={3}
              fontSize={32}
              lineHeight={1.25}
              color="isabelline"
            >
              Outstanding!
              <br />
              You&apos;ve Reached
            </Typography>
            <Typography fontSize={32} lineHeight={1.25} color="beauBlue">
              Level 7: UCO Champion
            </Typography>
            <Stack direction="row" alignItems="flex-start" spacing={2} mt={2}>
              <img
                src={imgBadge}
                alt="Badge"
                style={{
                  width: 72,
                  height: 72,
                }}
              />
              <Typography
                flex={1}
                fontSize={20}
                lineHeight={1.4}
                color="isabelline"
              >
                Your efforts are shaping the map and the community—this is the
                champion&apos;s path!
              </Typography>
            </Stack>

            <Typography
              mt={4}
              fontSize={20}
              fontWeight={500}
              lineHeight={1.4}
              color="beauBlue"
            >
              Your Achievements:
            </Typography>
            <Typography
              mt={1}
              fontSize={18}
              fontWeight={700}
              lineHeight={1.75}
              color="isabelline"
            >
              Mapped
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="isabelline"
              component="div"
            >
              <ul>
                <li>20+ verified restaurants (verified by the team)</li>
              </ul>
            </Typography>
            <Typography
              mt={1}
              fontSize={18}
              fontWeight={700}
              lineHeight={1.75}
              color="isabelline"
            >
              Added
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="isabelline"
              component="div"
            >
              <ul>
                <li>30+ verified restaurants (verified by the team)</li>
              </ul>
            </Typography>
            <Typography
              mt={1}
              fontSize={18}
              fontWeight={700}
              lineHeight={1.75}
              color="isabelline"
            >
              Unlocked
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="isabelline"
              component="div"
            >
              <ul>
                <li>Full access to Map and Earn with no restrictions</li>
                <li>
                  Expanded mapping radius: map restaurants even up to&nbsp;
                  <b>5km away</b>
                </li>
                <li>Access to Oily TGE</li>
              </ul>
            </Typography>
            <Typography
              mt={4}
              fontSize={20}
              fontWeight={500}
              lineHeight={1.4}
              color="beauBlue"
            >
              What&apos;s Next on Your Path?
            </Typography>
            <Stack mt={1} direction="row" alignItems="flex-start" spacing={2}>
              <img
                src={imgBadgeNext}
                alt="Oily"
                style={{
                  width: 32,
                  height: 32,
                }}
              />
              <Typography fontSize={16} lineHeight={1.75} color="isabelline">
                <b>Level 8: UCO Legend</b>
                <br />
                Step into leadership! Map 50+ verified locations to unlock the
                ability to add WMOs and gain access to exclusive Oily Giveaways
                + XP multiplier 1.2.
              </Typography>
            </Stack>
          </Box>
          <Box mt={5} px={4} pb={9}>
            <Button
              variant="contained"
              color="icterine"
              fullWidth
              onClick={handleContinue}
            >
              Explore Token Launch
            </Button>
          </Box>
        </Scaffold.Content>
      </Scaffold>
    </Dialog>
  );
};

LevelUp7Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiTwitterCallback } from '../../apis/auth';

export const TwitterCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    apiTwitterCallback();
    navigate('/profile', {
      replace: true,
    });
  }, [navigate]);

  return (
    <div>
      <blank></blank>
    </div>
  );
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Scaffold } from '../layouts/Scaffold';
import { XIcon } from 'lucide-react';
import { useSelector } from 'react-redux';
import { router } from '../../pages/router';

import imgOily from '../../assets/onboarding/oily-level-1.png';
import imgBadge from '../../assets/badges/badge-lvl1.png';
import imgBadgeNext from '../../assets/badges/badge-lvl2.png';

export const LevelUp1Dialog = ({ open, onClose }) => {
  const chainName = useSelector((state) => state.wallet.chainName);

  const handleContinue = useCallback(() => {
    if (chainName === 'vechain') {
      router.navigate('/progress?tab=raffles');
    }
    onClose();
  }, [onClose, chainName]);

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Scaffold bgcolor="levels.1.bgcolor" height="100vh">
        <Confetti recycle={false} numberOfPieces={1000} />
        <Scaffold.Header>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={2}
            pt={1}
            pb={2}
            minHeight={56}
          >
            <Box width={26} />
            <Typography fontSize={14} color="isabelline" lineHeight={1}>
              Level Completed
            </Typography>
            <IconButton color="isabelline" size="small" onClick={onClose}>
              <XIcon />
            </IconButton>
          </Stack>
        </Scaffold.Header>
        <Scaffold.Content>
          <Stack direction="row" justifyContent="center">
            <img src={imgOily} alt="Oily" />
          </Stack>
          <Box px={3}>
            <Typography
              mt={3}
              fontSize={32}
              lineHeight={1.25}
              color="isabelline"
            >
              Congratulations!
              <br />
              You&apos;ve Reached
            </Typography>
            <Typography fontSize={32} lineHeight={1.25} color="beauBlue">
              Level 1: Oily Initiate
            </Typography>
            <Stack direction="row" alignItems="flex-start" spacing={2} mt={2}>
              <img
                src={imgBadge}
                alt="Badge"
                style={{
                  width: 72,
                  height: 72,
                }}
              />
              <Typography
                flex={1}
                fontSize={20}
                lineHeight={1.4}
                color="isabelline"
              >
                You&apos;ve unlocked a new milestone on your Oily journey!
              </Typography>
            </Stack>
            <Typography
              mt={4}
              fontSize={20}
              fontWeight={500}
              lineHeight={1.4}
              color="beauBlue"
            >
              Your Achievements:
            </Typography>
            <Typography
              mt={1}
              fontSize={18}
              fontWeight={700}
              lineHeight={1.75}
              color="isabelline"
            >
              Completed
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="isabelline"
              component="div"
            >
              <ul>
                <li>3 Learn and Earn Challenges</li>
              </ul>
            </Typography>
            {chainName === 'vechain' ? (
              <>
                <Typography
                  mt={1}
                  fontSize={18}
                  fontWeight={700}
                  lineHeight={1.75}
                  color="isabelline"
                >
                  Unlocked
                </Typography>
                <Typography
                  fontSize={14}
                  lineHeight={1.75}
                  color="isabelline"
                  component="div"
                >
                  <ul>
                    <li>Oily B3TR Raffles</li>
                  </ul>
                </Typography>
              </>
            ) : null}
            <Typography
              mt={4}
              fontSize={20}
              fontWeight={500}
              lineHeight={1.4}
              color="beauBlue"
            >
              What&apos;s Next on Your Path?
            </Typography>
            <Stack mt={1} direction="row" alignItems="flex-start" spacing={2}>
              <img
                src={imgBadgeNext}
                alt="Oily"
                style={{
                  width: 32,
                  height: 32,
                }}
              />
              <Typography fontSize={16} lineHeight={1.75} color="isabelline">
                <b>Level 2: Green Apprentice</b>
                <br />
                Push forward! Complete 7 Learn and Earn Challenges and login for
                3 days to unlock Mint Oily Ambassador NFT.
              </Typography>
            </Stack>
          </Box>
          <Box mt={5} px={4} pb={9}>
            <Button
              variant="contained"
              color="icterine"
              fullWidth
              onClick={handleContinue}
            >
              {chainName === 'vechain' ? 'Explore Raffles' : 'Continue'}
            </Button>
          </Box>
        </Scaffold.Content>
      </Scaffold>
    </Dialog>
  );
};

LevelUp1Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, Typography } from '@mui/material';

export const LockedLessonModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
          Locked lesson
        </Typography>
        <Typography fontSize={16} lineHeight={1.75} color="raisinBlack" mt={3}>
          Complete the previous lesson to unlock.
        </Typography>
        <Box mt={11}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            Ok, I understand
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

LockedLessonModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

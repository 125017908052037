export const MuiSwitch = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: 56,
      height: 24,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 2,
        margin: 0,
        transitionDuration: '200ms',
        '&.Mui-checked': {
          transform: 'translateX(31px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.icterine,
            opacity: 1,
            border: 0,
            '&::before': {
              display: 'block',
            },
            '&::after': {
              display: 'none',
            },
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.pennBlue,
          },
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 20,
        height: 20,
        backgroundColor: `${theme.palette.isabelline}88`,
      },
      '& .MuiSwitch-track': {
        borderRadius: 999,
        backgroundColor: theme.palette.charcoal,
        opacity: 1,
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          width: 16,
          height: 10,
          fontSize: 10,
          fontWeight: 700,
          lineHeight: '10px',
        },
        '&::before': {
          left: 8,
          content: "'YES'",
          color: theme.palette.pennBlue,
          display: 'none',
        },
        '&::after': {
          right: 10,
          content: "'NO'",
          color: theme.palette.isabelline,
          opacity: 0.7,
          display: 'block',
        },
      },
    }),
  },
};

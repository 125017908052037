import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Modal, Stack, Typography } from '@mui/material';

export const GlobalLoadingModal = ({ open }) => {
  return (
    <Modal open={open}>
      <Box
        className="centered"
        sx={{
          width: 'auto !important',
          height: 'auto !important',
          padding: '24px 48px !important',
        }}
      >
        <Stack direction="column" alignItems="center">
          <CircularProgress
            size={32}
            sx={{
              '&::before': {
                display: 'none !important',
              },
            }}
            color="raisinBlack"
          />
          <Typography
            mt={2}
            fontSize={16}
            fontWeight={500}
            color="raisinBlack"
            lineHeight={1}
          >
            Processing
          </Typography>
        </Stack>
      </Box>
    </Modal>
  );
};

GlobalLoadingModal.propTypes = {
  open: PropTypes.bool,
};

import React, { useCallback } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { ArrowLeftIcon } from 'lucide-react';
import { Scaffold } from '../../components/layouts/Scaffold';
import { NavLink, useNavigate } from 'react-router-dom';

import imgGuide from '../../assets/guides/oily-guide.png';

export const AboutOilyPage = () => {
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Scaffold bgcolor="raisinBlack" height="100vh">
      <Scaffold.Header>
        <Stack
          px={3}
          pb={1}
          pt={3}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton size="small" color="isabelline" onClick={handleBack}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography
            color="isabelline"
            fontSize={14}
            lineHeight={1}
            flex={1}
            textAlign="center"
          >
            About Oily Dapp
          </Typography>
          <Box width={26} />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content mt={1} px={1} pt={2}>
        <img
          src={imgGuide}
          alt="branding"
          style={{
            width: '100%',
          }}
        />
        <Box mt={3} px={3}>
          <Typography fontSize={32} lineHeight={1.25} color="isabelline">
            Become an Ambassador for Sustainable Change
          </Typography>
          <Typography mt={2} color="isabelline" fontSize={16} lineHeight={1.75}>
            Oily empowers you to make a real impact in the Used Cooking Oil
            (UCO) recycling movement. As an Oily Ambassador, you&apos;ll play a
            crucial role in expanding the global UCO recycling network while
            earning rewards for your contributions.
          </Typography>
          <Box component="ul" mt={2} mb={0}>
            <Typography
              component="li"
              fontSize={16}
              lineHeight={1.75}
              color="isabelline"
            >
              <b>Level Up:</b> Start as a Novice and progress through ranks by
              completing activities and adding quality data
            </Typography>
            <Typography
              component="li"
              fontSize={16}
              lineHeight={1.75}
              color="isabelline"
            >
              <b>Unlock Multipliers:</b> Gain higher rewards as you prove your
              expertise and commitment
            </Typography>
            <Typography
              component="li"
              fontSize={16}
              lineHeight={1.75}
              color="isabelline"
            >
              <b>Access Special Features:</b> Advanced levels unlock additional
              data fields, verification capabilities & redeem options
            </Typography>
            <Typography
              component="li"
              fontSize={16}
              lineHeight={1.75}
              color="isabelline"
            >
              <b>Earn Recognition:</b> Build your reputation as a trusted
              sustainability advocate and see it evolving on your SoulBound NFT
            </Typography>
          </Box>
          <Typography mt={2} fontSize={26} color="isabelline">
            Map & Impact
          </Typography>
          <Typography
            mt={1}
            fontSize={16}
            color="isabelline"
            lineHeight={1.75}
            component="ul"
          >
            <Typography
              component="li"
              fontSize={16}
              lineHeight={1.75}
              color="isabelline"
            >
              Scout restaurants and identify UCO recycling opportunities
            </Typography>
            <Typography
              component="li"
              fontSize={16}
              lineHeight={1.75}
              color="isabelline"
            >
              Track your impact saving the world
            </Typography>
            <Typography
              component="li"
              fontSize={16}
              lineHeight={1.75}
              color="isabelline"
            >
              Add specialized data as you level up
            </Typography>
            <Typography
              component="li"
              fontSize={16}
              lineHeight={1.75}
              color="isabelline"
            >
              Compete with other Ambassadors for special perks!
            </Typography>
          </Typography>
          <Typography mt={2} fontSize={26} color="isabelline">
            Why Every Mapping Matters
          </Typography>
          <Typography mt={1} fontSize={16} color="isabelline" lineHeight={1.75}>
            Your contributions help create a comprehensive database of UCO
            collection opportunities. Each restaurant you map brings us closer
            to 100% UCO recycling rate - turning waste into sustainable fuel and
            data.
          </Typography>
          <Box mt={6} mb={9}>
            <Button
              variant="contained"
              color="icterine"
              fullWidth
              component={NavLink}
              to="/progress"
            >
              View my progress
            </Button>
          </Box>
        </Box>
      </Scaffold.Content>
      <Scaffold.Footer></Scaffold.Footer>
    </Scaffold>
  );
};

/* eslint-disable no-undef */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Scaffold } from '../../../components/layouts/Scaffold';
import { AppBottomNavigationBar } from '../../../components/layouts/AppLayout';
import {
  Box,
  Button,
  ButtonBase,
  FilledInput,
  IconButton,
  Modal,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { GoogleMap, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import { M2EOptionsDrawer } from './M2EOptionsDrawer';
import { ClaimRestaurantDisabledModal } from '../../../components/modals/ClaimRestaurantDisabledModal';
import { AddRestaurantDisabledModal } from '../../../components/modals/AddRestaurantDisabledModal';
import { AddWmoDisabledModal } from '../../../components/modals/AddWmoDisabledModal';
import { XpGauge } from '../../../components/core/XpGauge';
import {
  CircleCheckIcon,
  CircleXIcon,
  ListIcon,
  MapIcon,
  MapPinIcon,
  MoreVerticalIcon,
  SearchIcon,
  UtensilsIcon,
} from 'lucide-react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useGeolocated } from 'react-geolocated';
import { useDebounce } from 'use-debounce';
import { useSelector } from 'react-redux';
import { apiGetM2ESummary, apiGetPlaces } from '../../../apis/m2e';

import imgOilyHappy from '../../../assets/onboarding/oily-7.png';
import imgOilyUnhappy from '../../../assets/onboarding/oily-6.png';

export const OptionButton = ({ title, subtitle, xp, onClick }) => {
  const theme = useTheme();

  return (
    <ButtonBase
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left',
        py: 1.5,
        px: 2,
        borderRadius: 4,
        bgcolor: `${theme.palette.beauBlue}30`,
      }}
      onClick={onClick}
    >
      <Typography fontSize={22} lineHeight={1.5} color="white">
        {title}
      </Typography>
      <Typography fontSize={14} lineHeight={1.7} color="beauBlue">
        {subtitle}
      </Typography>
      <Box mt={1}>
        <XpGauge
          xp={xp}
          iconProps={{
            width: 16,
            height: 16,
          }}
          xpProps={{
            fontSize: 14,
            lineHeight: 1.7,
            color: 'isabelline',
          }}
        />
      </Box>
    </ButtonBase>
  );
};

const PlaceMarker = ({ isSelected, isMapped, isMuted, onClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transform: 'translateY(-100%)',
        boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          width: 14,
          height: 14,
          borderRadius: 6,
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: isSelected ? 'green.600' : 'white',
          bgcolor: isMuted
            ? 'spanishGray'
            : isMapped
              ? 'icterine'
              : 'royalBlueDark',
        }}
      />
      <Box
        sx={{
          width: 2,
          height: 8,
          borderRadius: '100%',
          mt: '-2px',
          bgcolor: isSelected ? 'green.600' : 'white',
        }}
      />
    </Box>
  );
};

const PlaceListItem = ({ item, distance, onClick }) => {
  const theme = useTheme();

  return (
    <ButtonBase
      sx={{
        flexDirection: 'row',
        textAlign: 'left',
        bgcolor:
          item.status && !item.claimed
            ? 'gray.200'
            : `${theme.palette.shadowBlue}30`,
        borderRadius: 4,
        p: 1,
        pl: 2,
      }}
      onClick={!item.status || item.status === 'approved' ? onClick : null}
    >
      <Stack
        minHeight={80}
        flex={1}
        mr={2}
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {item.status && !item.claimed ? (
          <Stack
            direction="row"
            alignItems="center"
            spacing={0.5}
            mb={1}
            px={1}
            py={0.5}
            sx={{
              borderRadius: 2,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor:
                item.status === 'pending'
                  ? 'gray.800'
                  : item.status === 'approved'
                    ? 'green.700'
                    : 'gray.500',
              color:
                item.status === 'pending'
                  ? 'gray.800'
                  : item.status === 'approved'
                    ? 'green.700'
                    : 'gray.500',
            }}
          >
            {item.status === 'disapproved' ? (
              <CircleXIcon width={16} height={16} />
            ) : (
              <CircleCheckIcon width={16} height={16} />
            )}
            <Typography fontSize={14} lineHeight={1.7}>
              {item.status === 'pending'
                ? 'Under review'
                : item.status === 'approved'
                  ? 'Restaurant approved'
                  : 'Restaurant disapproved'}
            </Typography>
          </Stack>
        ) : null}
        <Typography
          fontSize={18}
          lineHeight={1.3}
          color={item.status && !item.claimed ? 'pennBlue' : 'isabelline'}
          pt={1}
          sx={{
            flex: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineClamp: 2,
          }}
        >
          {item.name}
        </Typography>
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontSize={14} lineHeight={1.7} color="shadowBlue">
            {distance ? distance.toFixed(2) : '0'} km away
          </Typography>
          <XpGauge
            xp={item.total_xp}
            iconProps={{
              width: 16,
              height: 16,
            }}
            xpProps={{
              fontSize: 14,
              lineHeight: 1.7,
              color: 'shadowBlue',
            }}
          />
        </Stack>
      </Stack>
      {item.photo_url ? (
        <img
          src={item.photo_url}
          alt="restaurant"
          style={{
            width: 80,
            height: 80,
            borderRadius: 16,
          }}
        />
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          width={80}
          height={80}
          borderRadius={4}
          overflow="hidden"
          color="icterine"
          bgcolor="royalBlueDark"
        >
          <UtensilsIcon />
        </Stack>
      )}
    </ButtonBase>
  );
};

const LoadingPlaceListItem = () => {
  return <Skeleton variant="rounded" height={96} />;
};

export const M2EPlacesPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
  });
  const { isGeolocationEnabled, isGeolocationAvailable, coords } =
    useGeolocated();

  const { level } = useSelector((state) => state.profile);

  const qLat = params.get('lat') ? parseFloat(params.get('lat')) : null;
  const qLng = params.get('lng') ? parseFloat(params.get('lng')) : null;

  const [map, setMap] = useState(null);
  const [view, setView] = useState('list');
  const [zoom, setZoom] = useState(16);
  const [center, setCenter] = useState({
    lat: qLat ?? 0,
    lng: qLng ?? 0,
  });
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [search, setSearch] = useState('');
  const [filter] = useDebounce(search, 300);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [modals, setModals] = useState({
    addRestaurantDisabled: false,
    addWmoDisabled: false,
    claimRestaurantDisabled: false,
    mapped10Restaurants: false,
  });

  const { data: places, isLoading } = useQuery({
    queryKey: ['m2e', 'places', coords?.latitude, coords?.longitude],
    queryFn: async () => {
      if (coords && (coords.latitude !== 0 || coords?.longitude !== 0)) {
        return await apiGetPlaces({
          latitude: coords.latitude,
          longitude: coords.longitude,
          radius: 500,
        });
      }
      return [];
    },
  });
  const { data: summary } = useQuery({
    queryKey: ['m2e', 'summary'],
    queryFn: async () => {
      return await apiGetM2ESummary();
    },
  });

  const handleToggleMap = useCallback(() => {
    setView((value) => {
      setSelectedPlace(null);
      if (value === 'map' || value === 'highlight') {
        return 'list';
      } else {
        return 'map';
      }
    });
  }, []);

  const handleToggleDrawer = useCallback(() => {
    setIsDrawerOpened((prev) => {
      return !prev;
    });
  }, []);

  const handleToggleModal = useCallback((name) => {
    setModals((prev) => {
      return {
        ...prev,
        [name]: !prev[name],
      };
    });
  }, []);

  const deg2rad = useCallback((deg) => {
    return deg * (Math.PI / 180);
  }, []);

  const getDistance = useCallback(
    (from) => {
      if (coords && coords.latitude !== 0 && coords.longitude !== 0) {
        const R = 6371;
        const dLat = deg2rad(coords?.latitude - from.lat);
        const dLng = deg2rad(coords?.longitude - from.lng);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(deg2rad(from.lat)) *
            Math.cos(deg2rad(coords?.latitude)) *
            Math.sin(dLng / 2) *
            Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
        return distance;
      }
    },
    [deg2rad, coords],
  );

  const onMapMount = useCallback((map) => {
    setMap(map);
  }, []);

  const onMapUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const onZoomChanged = useCallback(() => {
    if (map) {
      setZoom(map.getZoom() ?? 16);
      setCenter({
        lat: map.getCenter()?.lat() ?? 0,
        lng: map.getCenter()?.lng() ?? 0,
      });
    }
  }, [map]);

  const onCenterChanged = useCallback(() => {
    if (map) {
      setCenter({
        lat: map.getCenter()?.lat() ?? 0,
        lng: map.getCenter()?.lng() ?? 0,
      });
    }
  }, [map]);

  const onSelectPlace = useCallback(
    (place) => {
      if (level < 5) {
        handleToggleModal('claimRestaurantDisabled');
        return;
      }
      if (level === 5 && summary?.count_mapped_locations >= 10) {
        handleToggleModal('mapped10Restaurants');
        return;
      }

      setSelectedPlace(place);
      setCenter({
        lat: place.latitude,
        lng: place.longitude,
      });
      setView('highlight');
    },
    [level, summary?.count_mapped_locations, handleToggleModal],
  );

  const onChoosePlace = useCallback(() => {
    if (level < 5) {
      handleToggleModal('claimRestaurantDisabled');
      return;
    }
    if (level === 5 && summary?.count_mapped_locations >= 10) {
      handleToggleModal('mapped10Restaurants');
      return;
    }

    if (selectedPlace) {
      if (selectedPlace.claimed) {
        navigate(`/m2e/places/${selectedPlace._id}/view`, {
          state: {
            ...selectedPlace,
          },
        });
      } else {
        navigate(`/m2e/places/${selectedPlace._id}/details`, {
          state: {
            ...selectedPlace,
          },
        });
      }
    }
  }, [
    navigate,
    selectedPlace,
    level,
    summary?.count_mapped_locations,
    handleToggleModal,
  ]);

  const onAddRestaurant = useCallback(() => {
    if (level < 6) {
      handleToggleModal('addRestaurantDisabled');
    } else {
      navigate('/m2e/add-restaurant');
    }
  }, [handleToggleModal, level, navigate]);

  const onAddWmo = useCallback(() => {
    if (level < 7) {
      handleToggleModal('addWmoDisabled');
    } else {
      navigate('/m2e/add-wmo');
    }
  }, [handleToggleModal, level, navigate]);

  useEffect(() => {
    if (center.lat !== 0 || center.lng !== 0) {
      return;
    }
    if (
      coords &&
      (coords.latitude !== center.lat || coords.longitude !== center.lng)
    ) {
      setCenter({
        lat: coords.latitude,
        lng: coords.longitude,
      });
    }
  }, [coords, center]);

  useEffect(() => {
    if (places && places.length > 0) {
      setFilteredPlaces(
        places.filter((item) =>
          item.name.toLowerCase().includes(filter.toLowerCase()),
        ),
      );
    } else {
      setFilteredPlaces([]);
    }
  }, [places, filter]);

  return (
    <Scaffold height="100vh">
      <Scaffold.Header>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          px={2}
          sx={{
            position: 'fixed',
            maxWidth: 624,
            width: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            top: 24,
            zIndex: 10,
            boxSizing: 'border-box',
          }}
        >
          <FilledInput
            placeholder="Search"
            endAdornment={<SearchIcon color={theme.palette.charcoal} />}
            value={search}
            onChange={(ev) => setSearch(ev.currentTarget.value)}
            sx={{
              flex: 1,
            }}
          />
          <IconButton
            color="isabelline"
            bgcolor="raisinBlack"
            onClick={handleToggleDrawer}
          >
            <MoreVerticalIcon />
          </IconButton>
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Stack direction="column" height="100%">
          <Box
            bgcolor="white"
            sx={{
              height:
                view === 'list'
                  ? '36vh'
                  : view === 'map'
                    ? '100vh'
                    : 'calc(100vh - 144px)',
            }}
          >
            {isGeolocationAvailable ? (
              isGeolocationEnabled && isLoaded ? (
                <GoogleMap
                  center={center}
                  zoom={zoom}
                  options={{
                    zoomControl: false,
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                  }}
                  onLoad={onMapMount}
                  onUnmount={onMapUnmount}
                  onDragEnd={onCenterChanged}
                  onZoomChanged={onZoomChanged}
                  mapContainerStyle={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {selectedPlace ? (
                    <OverlayView
                      key={`place-${selectedPlace._id}`}
                      position={
                        new google.maps.LatLng(
                          selectedPlace.latitude,
                          selectedPlace.longitude,
                        )
                      }
                      mapPaneName="overlayMouseTarget"
                    >
                      <PlaceMarker
                        isSelected={true}
                        isMapped={selectedPlace.claimed}
                        isMuted={false}
                        onClick={() => null}
                      />
                    </OverlayView>
                  ) : (
                    filteredPlaces?.map((item) => {
                      const pos = new google.maps.LatLng(
                        item.latitude,
                        item.longitude,
                      );
                      return (
                        <OverlayView
                          key={`place-${item._id}`}
                          position={pos}
                          mapPaneName="overlayMouseTarget"
                        >
                          <PlaceMarker
                            isSelected={false}
                            isMapped={item.claimed}
                            isMuted={item.status === 'pending'}
                            onClick={() => onSelectPlace(item)}
                          />
                        </OverlayView>
                      );
                    })
                  )}
                </GoogleMap>
              ) : (
                <Stack
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography fontSize={14} color="raisinBlack">
                    Please enable geolocation service.
                  </Typography>
                </Stack>
              )
            ) : (
              <Stack height="100%" justifyContent="center" alignItems="center">
                <Typography fontSize={14} color="raisinBlack">
                  Your browser does not support geolocation service.
                </Typography>
              </Stack>
            )}
          </Box>
          <Box
            bgcolor="raisinBlack"
            position="relative"
            pt={view === 'map' ? 0 : 3}
            px={view === 'map' ? 0 : 3}
            boxSizing="border-box"
            overflow="auto"
            sx={{
              transition: 'all 0.2s',
              height: view === 'list' ? '64vh' : view === 'map' ? 0 : 144,
            }}
          >
            {!filteredPlaces?.length && !isLoading ? (
              <React.Fragment>
                <Stack direction="column" alignItems="center">
                  <img src={imgOilyUnhappy} alt="Oily" />
                  <Typography
                    fontSize={14}
                    lineHeight={1.5}
                    color="shadowBlue"
                    mt={1}
                    maxWidth={220}
                    textAlign="center"
                  >
                    There are no restaurants mapped in this region yet.
                  </Typography>
                </Stack>
                <Stack direction="column" mt={4} spacing={2}>
                  <OptionButton
                    title="Add a Restaurant to Map"
                    subtitle="Helps us map the unmapped restaurants"
                    xp={100}
                    onClick={onAddRestaurant}
                  />
                  <OptionButton
                    title="Add a WMO"
                    subtitle="Gives us a direct contact of a WMO"
                    xp={500}
                    onClick={onAddWmo}
                  />
                </Stack>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {!selectedPlace && (
                  <Stack direction="column" alignItems="center">
                    <img src={imgOilyHappy} alt="Oily" />
                    <Typography
                      fontSize={14}
                      lineHeight={1.5}
                      color="shadowBlue"
                      mt={1}
                      textAlign="center"
                    >
                      {level <= 5
                        ? summary?.count_mapped_locations !== undefined
                          ? `${summary.count_mapped_locations} of 10 restaurants mapped`
                          : 'Limited to 10 mappings'
                        : null}
                      {level > 5 && 'You have unlimited mapping access'}
                    </Typography>
                  </Stack>
                )}
                <Stack
                  direction="column"
                  mt={selectedPlace ? 0 : 1}
                  spacing={1}
                >
                  {isGeolocationEnabled ? (
                    <>
                      {selectedPlace ? (
                        <PlaceListItem
                          item={selectedPlace}
                          distance={getDistance({
                            lat: selectedPlace.latitude,
                            lng: selectedPlace.longitude,
                          })}
                          onClick={() => onChoosePlace(selectedPlace)}
                        />
                      ) : filteredPlaces && filteredPlaces.length > 0 ? (
                        filteredPlaces.map((item) => (
                          <PlaceListItem
                            key={`place-${item._id}`}
                            item={item}
                            distance={getDistance({
                              lat: item.latitude,
                              lng: item.longitude,
                            })}
                            onClick={() => onSelectPlace(item)}
                          />
                        ))
                      ) : (
                        <>
                          {isLoading ? (
                            Array(5)
                              .fill(true)
                              .map((_, index) => (
                                <LoadingPlaceListItem
                                  key={`loading-${index}`}
                                />
                              ))
                          ) : (
                            <Typography
                              pt={4}
                              textAlign="center"
                              alignSelf="center"
                              fontSize={14}
                              color="isabelline"
                            >
                              There are no restaurants mapped in this region
                              yet.
                            </Typography>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Box color="isabelline" alignSelf="center" pt={4}>
                        <MapPinIcon />
                      </Box>
                      <Typography
                        textAlign="center"
                        alignSelf="center"
                        fontSize={14}
                        color="isabelline"
                      >
                        Please enable geolocation service.
                      </Typography>
                    </>
                  )}
                </Stack>
              </React.Fragment>
            )}
            <Box height={150} />
          </Box>
          <Button
            variant="contained"
            color="raisinBlack"
            endIcon={view === 'list' ? <MapIcon /> : <ListIcon />}
            sx={{
              height: 40,
              position: 'absolute',
              left: '50%',
              bottom:
                view === 'list'
                  ? 'calc(64vh + 16px)'
                  : view === 'highlight'
                    ? 'calc(144px + 16px)'
                    : '16px',
              transform: 'translateX(-50%)',
            }}
            onClick={handleToggleMap}
          >
            {view === 'list' ? 'Map' : 'List'}
          </Button>
        </Stack>
        <M2EOptionsDrawer
          open={isDrawerOpened}
          onClose={handleToggleDrawer}
          onAddRestaurant={onAddRestaurant}
          onAddWmo={onAddWmo}
        />
        <ClaimRestaurantDisabledModal
          open={modals.claimRestaurantDisabled}
          onClose={() => handleToggleModal('claimRestaurantDisabled')}
        />
        <AddRestaurantDisabledModal
          open={modals.addRestaurantDisabled}
          onClose={() => handleToggleModal('addRestaurantDisabled')}
        />
        <AddWmoDisabledModal
          open={modals.addWmoDisabled}
          onClose={() => handleToggleModal('addWmoDisabled')}
        />
        <Modal
          open={modals.mapped10Restaurants}
          onClose={() => handleToggleModal('mapped10Restaurants')}
        >
          <Box>
            <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
              Pending Validation for Mapped Restaurants!
            </Typography>
            <Typography
              mt={2}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              You&apos;ve successfully mapped and submitted 10 restaurants for
              review. Once validated, you&apos;ll be able to continue mapping
              more locations. Please check the status of your submissions in the
              My Contributions page.
              <br />
              <br />
              Thank you for your patience!
            </Typography>
            <Box mt={11}>
              <Button
                variant="outlined"
                color="raisinBlack"
                component={NavLink}
                to="/progress?tab=contributions"
              >
                Go to My Contributions
              </Button>
            </Box>
          </Box>
        </Modal>
      </Scaffold.Content>
      <Scaffold.Footer>
        <AppBottomNavigationBar
          sx={view === 'map' || view === 'highlight' ? { display: 'none' } : {}}
        />
      </Scaffold.Footer>
    </Scaffold>
  );
};

OptionButton.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  xp: PropTypes.number,
  onClick: PropTypes.func,
};

PlaceMarker.propTypes = {
  isSelected: PropTypes.bool,
  isMapped: PropTypes.bool,
  isMuted: PropTypes.bool,
  onClick: PropTypes.func,
};

PlaceListItem.propTypes = {
  item: PropTypes.any,
  distance: PropTypes.any,
  onClick: PropTypes.func,
};

import axios from 'axios';
import { load } from '@fingerprintjs/botd';
import { store } from '../redux/store';
import { updateProfile } from '../redux/profile.slice';

export const apiRunFingerprintjs = async () => {
  try {
    const response = await load();
    const result = response.detect();
    if (result && result.bot) {
      await axios.put('/api/client/v1/user/mark-scammer');
      store.dispatch(updateProfile({ scammed: true }));
    }
    return true;
  } catch {
    return false;
  }
};

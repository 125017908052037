import React, { useCallback } from 'react';
import { Box, IconButton, Stack, Switch, Typography } from '@mui/material';
import { ArrowLeftIcon } from 'lucide-react';
import { Scaffold } from '../../components/layouts/Scaffold';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppStatus } from '../../redux/app.slice';
import { apiUpdateProfile } from '../../apis/profile';

import imgGuide from '../../assets/guides/oily-guide.png';

export const AboutTokenLaunchPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { tgeOptIn } = useSelector((state) => state.profile);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleChange = useCallback(
    async (ev, checked) => {
      dispatch(
        updateAppStatus({
          isGlobalLoading: true,
        }),
      );
      await apiUpdateProfile({
        tge_opt_in: checked,
      });
      dispatch(
        updateAppStatus({
          isGlobalLoading: false,
        }),
      );
    },
    [dispatch],
  );

  return (
    <Scaffold bgcolor="raisinBlack" height="100vh">
      <Scaffold.Header>
        <Stack
          px={3}
          pb={1}
          pt={3}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton size="small" color="isabelline" onClick={handleBack}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography
            color="isabelline"
            fontSize={14}
            lineHeight={1}
            flex={1}
            textAlign="center"
          >
            $OILY Token Launch
          </Typography>
          <Box width={26} />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content mt={1} px={1} pt={2}>
        <img
          src={imgGuide}
          alt="branding"
          style={{
            width: '100%',
          }}
        />
        <Box mt={3} px={3}>
          <Typography fontSize={32} lineHeight={1.25} color="isabelline">
            $OILY Token Launch
          </Typography>
          <Stack
            mt={3}
            p={2}
            bgcolor="#1B1A23"
            direction="row"
            borderRadius={2}
          >
            <Typography
              fontSize={18}
              lineHeight={1.75}
              color="isabelline"
              flex={1}
              mr={2}
            >
              Want to participate on $OILY TGE Airdrop?
            </Typography>
            <Switch checked={tgeOptIn} onChange={handleChange} />
          </Stack>
          <Typography mt={3} fontSize={26} color="isabelline">
            What is $OILY
          </Typography>
          <Typography
            mt={1}
            mb={9}
            fontSize={16}
            color="isabelline"
            lineHeight={1.75}
          >
            $OILY token represents the future of sustainable cooking oil
            management and circular economy!
            <br />
            <br />
            As a Level 7 community member, you&apos;re among the first to have
            the opportunity to participate in our token generation event!Your
            commitment to sustainability is about to be rewarded!
          </Typography>
        </Box>
      </Scaffold.Content>
      <Scaffold.Footer></Scaffold.Footer>
    </Scaffold>
  );
};

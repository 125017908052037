import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { IdentityKitProvider } from '@nfid/identitykit/react';
import { IdentityKitAuthType } from '@nfid/identitykit';

import { AppProvider } from './components/providers/AppProvider';
import { persistor, store } from './redux/store';
import { router } from './pages/router';
import { theme } from './configs/theme';
import { snackbar } from './configs/snackbar';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider {...snackbar}>
              <AppProvider>
                <IdentityKitProvider authType={IdentityKitAuthType.ACCOUNTS}>
                  <RouterProvider router={router} />
                </IdentityKitProvider>
              </AppProvider>
            </SnackbarProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  places: {},
};

export const placesSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
    setPlaces: (state, action) => {
      return action.payload;
    },
    insertPlace: (state, action) => {
      state.places[action.payload.id] = action.payload;
    },
    updatePlace: (state, action) => {
      if (action.payload.id) {
        const place = state.places[action.payload.id];
        if (place) {
          state.places[action.payload.id] = { ...place, ...action.payload };
        }
      }
    },
    deletePlace: (state, action) => {
      delete state.places[action.payload];
    },
  },
});

export const { setPlaces, deletePlace, updatePlace, insertPlace } =
  placesSlice.actions;

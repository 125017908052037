import React, { useCallback, useEffect, useState } from 'react';
import { Scaffold } from '../../../components/layouts/Scaffold';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Modal,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ArrowLeftIcon, XIcon } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { updatePlace } from '../../../redux/places.slice';
import * as yup from 'yup';

const schema = yup
  .object({
    contact_name: yup.string().required('Please fill contact name'),
    contact_email: yup
      .string()
      .email('Please fill valid email address')
      .required(),
    contact_phone_number: yup
      .string()
      .matches(/^\+?[0-9]+$/, 'Please enter a valid phone number')
      .required('Please fill contact phone number'),
    charge_name: yup.string().required('Please fill person name'),
    charge_email: yup
      .string()
      .email('Please fill valid email address')
      .required('Please fill email address'),
    spoken_language: yup.string().required('Please fill spoken language'),
  })
  .required();

export const M2EEditRestaurantDetailsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.placeId;

  const data = useSelector((state) => (id ? state.places.places[id] : null));

  const [modals, setModals] = useState({
    discard: false,
  });

  const { reset, control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      contact_name: '',
      contact_email: '',
      contact_phone_number: '',
      charge_email: '',
      charge_name: '',
      spoken_language: '',
    },
    resolver: yupResolver(schema),
  });

  const [isSameContactPerson, setIsSameContactPerson] = useState(true);
  const [isEnglishSpeaker, setIsEnglishSpeaker] = useState(true);
  const [contact_name, contact_email] = watch([
    'contact_name',
    'contact_email',
  ]);

  const handleToggleModal = useCallback((name) => {
    setModals((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  }, []);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleDiscard = useCallback(() => {
    handleToggleModal('discard');
  }, [handleToggleModal]);

  const handleSave = useCallback(
    (values) => {
      if (!id) {
        return;
      }

      dispatch(
        updatePlace({
          id: id,
          general: {
            contact_name: values.contact_name,
            contact_email: values.contact_email,
            contact_phone_number: values.contact_phone_number,
            charge_name: values.charge_name,
            charge_email: values.charge_email,
            spoken_language: values.spoken_language,
          },
        }),
      );
      navigate(-1);
    },
    [id, dispatch, navigate],
  );

  useEffect(() => {
    if (!id) {
      navigate('/m2e/places');
      return;
    }
    if (data) {
      reset({
        contact_name: data.general?.contact_name ?? '',
        contact_email: data.general?.contact_email ?? '',
        contact_phone_number: data.general?.contact_phone_number ?? '',
        charge_name: data.general?.charge_name ?? '',
        charge_email: data.general?.charge_email ?? '',
        spoken_language: data.general?.spoken_language ?? '',
      });
      if (
        data.general?.contact_name &&
        data.general.contact_name === data.general?.charge_name
      ) {
        if (
          data.general?.contact_email &&
          data.general.contact_email === data.general?.charge_email
        ) {
          setIsSameContactPerson(true);
        }
      }
      if (data.general?.spoken_language) {
        if (data.general.spoken_language === 'English') {
          setIsEnglishSpeaker(true);
        } else {
          setIsEnglishSpeaker(false);
        }
      }
    } else {
      navigate('/mappings');
    }
  }, [data, id, navigate, reset]);

  useEffect(() => {
    if (isSameContactPerson) {
      setValue('charge_name', contact_name);
      setValue('charge_email', contact_email);
    } else {
      setValue('charge_name', '');
      setValue('charge_email', '');
    }
  }, [isSameContactPerson, contact_name, contact_email, setValue]);

  useEffect(() => {
    if (isEnglishSpeaker) {
      setValue('spoken_language', 'English');
    } else {
      setValue('spoken_language', '');
    }
  }, [isEnglishSpeaker, setValue]);

  return (
    <Scaffold height="100vh" bgcolor="raisinBlack">
      <Scaffold.Header>
        <Stack
          direction="row"
          alignItems="center"
          px={2}
          pt={1}
          pb={2}
          minHeight={56}
        >
          <IconButton color="isabelline" size="small" onClick={handleDiscard}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography
            fontSize={14}
            color="isabelline"
            flex={1}
            mx={2}
            textAlign="center"
          >
            Restaurant Details
          </Typography>
          <IconButton color="isabelline" size="small" onClick={handleDiscard}>
            <XIcon />
          </IconButton>
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Box px={3} pt={6}>
          <Typography fontSize={32} lineHeight={1.25} color="isabelline" mb={4}>
            Restaurant Details
          </Typography>
          <Box mb={4}>
            <Controller
              control={control}
              name="contact_name"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  variant="standard"
                  color="dark"
                  label="NAME OF THE RESTAURANT"
                  placeholder="E.g.: Bounty cool burger"
                  required
                  fullWidth
                  {...field}
                  error={!!error?.message}
                />
              )}
            />
          </Box>
          <Box mb={4}>
            <Controller
              control={control}
              name="contact_email"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  variant="standard"
                  color="dark"
                  label="EMAIL"
                  placeholder="E.g.: email@domain.com"
                  required
                  fullWidth
                  {...field}
                  error={!!error?.message}
                />
              )}
            />
          </Box>
          <Box mb={4}>
            <Controller
              control={control}
              name="contact_phone_number"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  variant="standard"
                  color="dark"
                  label="PHONE NUMBER"
                  placeholder="E.g.: +14844578454"
                  required
                  fullWidth
                  {...field}
                  error={!!error?.message}
                />
              )}
            />
          </Box>
          <Typography
            fontSize={32}
            lineHeight={1.25}
            color="isabelline"
            mt={6}
            mb={4}
          >
            Language
          </Typography>
          <Stack direction="row" spacing={2} mb={4}>
            <Typography
              fontSize={18}
              lineHeight={1.75}
              color="isabelline"
              flex={1}
            >
              English speaker?
            </Typography>
            <Box pt={0.5}>
              <Switch
                checked={isEnglishSpeaker}
                onChange={(ev, checked) => setIsEnglishSpeaker(checked)}
              />
            </Box>
          </Stack>
          <Collapse in={!isEnglishSpeaker}>
            <Box mb={4}>
              <Controller
                control={control}
                name="spoken_language"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    variant="standard"
                    color="dark"
                    label="SPOKEN LANGUAGES"
                    placeholder="E.g.: French, Italian"
                    required
                    fullWidth
                    {...field}
                    error={!!error?.message}
                  />
                )}
              />
            </Box>
          </Collapse>
          <Typography
            fontSize={32}
            lineHeight={1.25}
            color="isabelline"
            mt={6}
            mb={4}
          >
            Contact Person
          </Typography>
          <Stack direction="row" spacing={2} mb={4}>
            <Typography
              fontSize={18}
              lineHeight={1.75}
              color="isabelline"
              flex={1}
            >
              Does anyone wants to leave their name as a contact?
            </Typography>
            <Box pt={0.5}>
              <Switch
                checked={!isSameContactPerson}
                onChange={(ev, checked) => setIsSameContactPerson(!checked)}
              />
            </Box>
          </Stack>
          <Collapse in={!isSameContactPerson}>
            <Box mb={4}>
              <Controller
                control={control}
                name="charge_name"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    variant="standard"
                    color="dark"
                    label="NAME"
                    placeholder="E.g.: John Smith"
                    required
                    fullWidth
                    {...field}
                    error={!!error?.message}
                  />
                )}
              />
            </Box>
            <Box mb={4}>
              <Controller
                control={control}
                name="charge_email"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    variant="standard"
                    color="dark"
                    label="EMAIL"
                    placeholder="E.g.: email@domain.com"
                    required
                    fullWidth
                    {...field}
                    error={!!error?.message}
                  />
                )}
              />
            </Box>
          </Collapse>
        </Box>
        <Modal
          open={modals.discard}
          onClose={() => handleToggleModal('discard')}
        >
          <Box>
            <Typography fontSize={32} color="raisinBlack" lineHeight={1.25}>
              Inserted data will be discarded permanently.
            </Typography>
            <Stack mt={11} direction="row" spacing={1}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={() => handleToggleModal('discard')}
              >
                Close
              </Button>
              <Button variant="contained" color="danger" onClick={handleBack}>
                Discard changes
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Scaffold.Content>
      <Scaffold.Footer p={3}>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            color="isabelline"
            sx={{
              flex: 1,
            }}
            onClick={handleDiscard}
          >
            Discard
          </Button>
          <Button
            variant="contained"
            color="isabelline"
            sx={{
              flex: 2,
            }}
            onClick={handleSubmit(handleSave)}
          >
            Save
          </Button>
        </Stack>
      </Scaffold.Footer>
    </Scaffold>
  );
};

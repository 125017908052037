import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import { AppLayout } from '../components/layouts/AppLayout';
import { OnboardingLayout } from '../components/layouts/OnboardingLayout';
import { L2ELayout } from '../components/layouts/L2ELayout';
import { ProtectedRoute } from '../components/ProtectedRoute';

/** onboarding flow */
import { WelcomePage } from './onboarding/WelcomePage';
import { WalkthroughPage } from './onboarding/WalkthroughPage';
import { QuestionsPage } from './onboarding/QuestionsPage';
import { CongratulationsPage } from './onboarding/CongratulationsPage';
import { SettingsPage } from './onboarding/SettingsPage';
import { ReadyPage } from './onboarding/ReadyPage';

/** main flow */
import { HomePage } from './home/HomePage';
import { ProgressPage } from './progress/ProgressPage';
import { RedeemPage } from './redeem/RedeemPage';
import { ProfilePage } from './profile/ProfilePage';
import { EditIdentityPage } from './profile/EditIdentityPage';
import { EditZealyIdPage } from './profile/EditZealyIdPage';
import { ArticlesPage } from './articles/ArticlesPage';
import { LeaderboardPage } from './leaderboard/LeaderboardPage';

/** challenges */
import { ChallengesPage } from './challenges/ChallengesPage';
import { L2EChaptersPage } from './challenges/l2e/L2EChaptersPage';
import { L2ELessonsPage } from './challenges/l2e/L2ELessonsPage';
import { L2ELessonContentsPage } from './challenges/l2e/L2ELessonContentsPage';
import { L2ELessonQuestionsPage } from './challenges/l2e/L2ELessonQuestionsPage';
import { L2ELessonRewardPage } from './challenges/l2e/L2ELessonRewardPage';
import { M2EPlacesPage } from './challenges/m2e/M2EPlacesPage';
import { M2EClaimedPlacePage } from './challenges/m2e/M2EClaimedPlacePage';
import { M2EPlaceDetailsPage } from './challenges/m2e/M2EPlaceDetailsPage';
import { M2EEditUcoDetailsPage } from './challenges/m2e/M2EEditUcoDetailsPage';
import { M2EEditRestaurantDetailsPage } from './challenges/m2e/M2EEditRestaurantDetailsPage';
import { M2EPlaceContributedPage } from './challenges/m2e/M2EPlaceContributedPage';
import { M2EAddRestaurantPage } from './challenges/m2e/M2EAddRestaurantPage';
import { M2EAddWmoPage } from './challenges/m2e/M2EAddWmoPage';

/** guides */
import { AboutM2EPage } from './info/AboutM2EPage';
import { AboutUCOPage } from './info/AboutUCOPage';
import { AboutOilyPage } from './info/AboutOilyPage';
import { AboutTokenLaunchPage } from './info/AboutTokenLaunchPage';

/** callbacks */
import { TwitterCallback } from './callbacks/TwitterCallback';

export const router = createBrowserRouter([
  {
    path: '/welcome',
    element: <WelcomePage />,
  },
  {
    path: '/onboarding',
    element: <OnboardingLayout />,
    children: [
      {
        path: 'walkthrough',
        element: <WalkthroughPage />,
      },
      {
        path: 'questions',
        element: <QuestionsPage />,
      },
      {
        path: 'congrats',
        element: <CongratulationsPage />,
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
      {
        path: 'ready',
        element: <ReadyPage />,
      },
    ],
  },
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            <Navigate to="/home" />
          </ProtectedRoute>
        ),
      },
      {
        path: 'home',
        element: <HomePage />,
      },
      {
        path: 'challenges',
        element: <ChallengesPage />,
      },
      {
        path: 'progress',
        element: <ProgressPage />,
      },
    ],
  },
  {
    path: '/about',
    children: [
      {
        path: 'm2e',
        element: <AboutM2EPage />,
      },
      {
        path: 'uco-network',
        element: <AboutUCOPage />,
      },
      {
        path: 'oily-dapp',
        element: <AboutOilyPage />,
      },
      {
        path: 'token-launch',
        element: <AboutTokenLaunchPage />,
      },
    ],
  },
  {
    path: '/l2e',
    element: <L2ELayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/l2e/chapters" />,
      },
      {
        path: 'chapters',
        element: <L2EChaptersPage />,
      },
      {
        path: 'chapters/:chapterIndex',
        element: <L2ELessonsPage />,
      },
    ],
  },
  {
    path: '/l2e/chapters/:chapterIndex/lessons/:lessonIndex',
    children: [
      {
        path: '',
        element: <Navigate to="contents" />,
      },
      {
        path: 'contents',
        element: <L2ELessonContentsPage />,
      },
      {
        path: 'questions',
        element: <L2ELessonQuestionsPage />,
      },
      {
        path: 'rewards',
        element: <L2ELessonRewardPage />,
      },
    ],
  },
  {
    path: '/m2e',
    children: [
      {
        path: '',
        element: <Navigate to="places" replace />,
      },
      {
        path: 'places',
        element: <M2EPlacesPage />,
      },
      {
        path: 'places/:placeId/view',
        element: <M2EClaimedPlacePage />,
      },
      {
        path: 'places/:placeId/details',
        element: <M2EPlaceDetailsPage />,
      },
      {
        path: 'places/:placeId/edit/uco',
        element: <M2EEditUcoDetailsPage />,
      },
      {
        path: 'places/:placeId/edit/restaurant',
        element: <M2EEditRestaurantDetailsPage />,
      },
      {
        path: 'places/:placeId/contributed',
        element: <M2EPlaceContributedPage />,
      },
      {
        path: 'add-restaurant',
        element: <M2EAddRestaurantPage />,
      },
      {
        path: 'add-wmo',
        element: <M2EAddWmoPage />,
      },
    ],
  },
  {
    path: '/redeem',
    element: <RedeemPage />,
  },
  {
    path: '/profile',
    children: [
      {
        path: '',
        element: <ProfilePage />,
      },
      {
        path: 'identity',
        element: <EditIdentityPage />,
      },
      {
        path: 'zealy-id',
        element: <EditZealyIdPage />,
      },
    ],
  },
  {
    path: '/articles',
    element: <ArticlesPage />,
  },
  {
    path: '/leaderboard',
    element: <LeaderboardPage />,
  },
  {
    path: '/auth/x/callback',
    element: <TwitterCallback />,
  },
  {
    path: '*',
    element: <Navigate to="/home" replace />,
  },
]);

import axios from 'axios';

export const apiGetLeaderboardData = async ({ year, month, skip, limit }) => {
  try {
    const response = await axios.post('/api/client/v1/user/leaderboard', {
      year,
      month,
      skip,
      limit,
    });
    return response.data.data;
  } catch {
    return {
      data: [],
      first3: [],
      me: null,
    };
  }
};

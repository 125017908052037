import { createTheme } from '@mui/material';
import { MuiButton, MuiButtonBase } from './components/MuiButton';
import { MuiIconButton } from './components/MuiIconButton';
import { MuiLinearProgress } from './components/MuiLinearProgress';
import { MuiCircularProgress } from './components/MuiCircularProgress';
import { MuiSwitch } from './components/MuiSwitch';
import { MuiDialog, MuiModal } from './components/MuiModal';
import { MuiSkeleton } from './components/MuiSkeleton';
import { MuiCheckbox } from './components/MuiCheckbox';
import {
  MuiFilledInput,
  MuiInputBase,
  MuiTextField,
} from './components/MuiInput';
import { MuiSlider } from './components/MuiSlider';
import { MuiAutocomplete, MuiSelect } from './components/MuiSelect';

export const theme = createTheme({
  typography: {
    fontFamily: 'inherit',
  },
  palette: {
    icterine: '#F8C71D',
    raisinBlack: '#23222D',
    isabelline: '#F3F4F7',
    purpleNavy: '#475985',
    white: '#FFFFFF',
    beauBlue: '#B4C7D6',
    pennBlue: '#031952',
    shadowBlue: '#7783A1',
    charcoal: '#464A53',
    royalBlueDark: '#00195A',
    spanishGray: '#9F9E9E',
    greyishBlue: '#C0D4E4',
    green: {
      400: '#B2E8B8',
      500: '#2CB53B',
      600: '#088715',
      700: '#2CB53B',
    },
    yellow: {
      400: '#FCEAA9',
      500: '#F8C71D',
      700: '#A78407',
    },
    gray: {
      200: '#F8F8F8',
      300: '#ECFEEE',
      500: '#C9C9C9',
      700: '#6D6D6D',
      800: '#282828',
    },
    error: {
      600: '#A60814',
      500: '#D64C56',
      400: '#EEB7BB',
    },
    greyishRed: '#EAA28B',
    levels: {
      0: {
        indicator: '#138B57',
        gradient: 'linear-gradient(180deg, #D3F6E7 0%, #90CAB1 100%)',
      },
      1: {
        indicator: '#D3A72A',
        gradient: 'linear-gradient(180deg, #F5EDD4 0%, #E7C75D 100%)',
        bgcolor: '#4F3E00',
      },
      2: {
        indicator: '#885FE2',
        gradient: 'linear-gradient(180deg, #E6D7FB 0%, #A172E0 100%)',
        bgcolor: '#230251',
      },
      3: {
        indicator: '#1464F5',
        gradient: 'linear-gradient(180deg, #DDE7F8 0%, #6E9DF2 100%)',
        bgcolor: '#022362',
      },
      4: {
        indicator: '#DD448E',
        gradient: 'linear-gradient(180deg, #FFD3E9 0%, #EC7FB4 100%)',
        bgcolor: '#51002A',
      },
      5: {
        indicator: '#6E8F2D',
        gradient: 'linear-gradient(180deg, #F4FFE0 0%, #ABBE85 100%)',
        bgcolor: '#274107',
      },
      6: {
        indicator: '#FF9809',
        gradient: 'linear-gradient(180deg, #FFF4E5 0%, #FFD192 100%)',
        bgcolor: '#6D3F00',
      },
      7: {
        indicator: '#B70AB7',
        gradient: 'linear-gradient(180deg, #FBDDFB 0%, #E152E1 100%)',
        bgcolor: '#3A033A',
      },
      8: {
        indicator: '#5A5ADE',
        gradient: 'linear-gradient(180deg, #EDEDFE 0%, #AEAEE6 100%)',
        bgcolor: '#1D1D56',
      },
      9: {
        indicator: '#896937',
        gradient: 'linear-gradient(180deg, #FFF3E0 0%, #E4C89E 100%)',
        bgcolor: '#3A2B14',
      },
      10: {
        indicator: '#B5B511',
        gradient: 'linear-gradient(180deg, #FFE 0%, #FFFF98 100%)',
        bgcolor: '#393904',
      },
    },
  },
  components: {
    MuiButton: MuiButton,
    MuiButtonBase: MuiButtonBase,
    MuiIconButton: MuiIconButton,
    MuiLinearProgress: MuiLinearProgress,
    MuiCircularProgress: MuiCircularProgress,
    MuiSwitch: MuiSwitch,
    MuiModal: MuiModal,
    MuiDialog: MuiDialog,
    MuiSkeleton: MuiSkeleton,
    MuiInputBase: MuiInputBase,
    MuiFilledInput: MuiFilledInput,
    MuiCheckbox: MuiCheckbox,
    MuiTextField: MuiTextField,
    MuiSelect: MuiSelect,
    MuiAutocomplete: MuiAutocomplete,
    MuiSlider: MuiSlider,
  },
});

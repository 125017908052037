import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, Stack, Typography, styled } from '@mui/material';
import { useSelector } from 'react-redux';

import imgBadgeLvl0 from '../../assets/badges/badge-lvl0.png';
import imgBadgeLvl1 from '../../assets/badges/badge-lvl1.png';
import imgBadgeLvl2 from '../../assets/badges/badge-lvl2.png';
import imgBadgeLvl3 from '../../assets/badges/badge-lvl3.png';
import imgBadgeLvl4 from '../../assets/badges/badge-lvl4.png';
import imgBadgeLvl5 from '../../assets/badges/badge-lvl5.png';
import imgBadgeLvl6 from '../../assets/badges/badge-lvl6.png';
import imgBadgeLvl7 from '../../assets/badges/badge-lvl7.png';
import imgBadgeLvl8 from '../../assets/badges/badge-lvl8.png';
import imgBadgeLvl9 from '../../assets/badges/badge-lvl9.png';
import imgBadgeLvl10 from '../../assets/badges/badge-lvl10.png';

import imgOily from '../../assets/onboarding/oily-locked-account.png';
import imgBubble from '../../assets/onboarding/bubble-6.png';

const BadgeImage = styled('img')({
  width: 80,
  height: 82,
});

const OilyImage = styled('img')({
  width: 120,
  height: 133,
});

const BubbleImage = styled('img')({
  position: 'absolute',
  top: -28,
  right: -56,
  width: 96,
  height: 91,
});

const SmallBadgeImage = styled('img')({
  width: 32,
  height: 33,
});

export const AccountLvl0InfoModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <BadgeImage alt="Badge" src={imgBadgeLvl0} />
        </Stack>
        <Typography
          mt={3}
          fontSize={20}
          fontWeight={700}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
        >
          Level 0: Newcomer
        </Typography>
        <Typography
          fontSize={16}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
          component="div"
        >
          <Typography component="ul" my={0}>
            <li>Sign up for Oily</li>
            <li>Complete basic profile setup/settings</li>
          </Typography>
          <br />
          <b>Unlock:</b>
          <Typography component="ul" my={0}>
            <li>First Learn and Earn Challenge</li>
          </Typography>
        </Typography>
        <Box mt={4}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const AccountLvl1InfoModal = ({ open, onClose }) => {
  const { chainName } = useSelector((state) => state.wallet);

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <BadgeImage alt="Badge" src={imgBadgeLvl1} />
        </Stack>
        <Typography
          mt={3}
          fontSize={20}
          fontWeight={700}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
        >
          Level 1: Initiate
        </Typography>
        <Typography
          fontSize={16}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
          component="div"
        >
          <Typography component="ul" my={0}>
            <li>Complete 3 Learn and Earn Challenges</li>
          </Typography>
          <br />
          {chainName === 'vechain' ? (
            <>
              <b>Unlock:</b>
              <br />
              <Typography component="ul" my={0}>
                <li>Oily $B3TR Raffles!</li>
              </Typography>
            </>
          ) : null}
        </Typography>
        <Box mt={4}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const LockedAccountLvl1InfoModal = ({ open, onClose }) => {
  const { chainName } = useSelector((state) => state.wallet);

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <Box position="relative">
            <OilyImage src={imgOily} alt="Oily" />
            <BubbleImage src={imgBubble} alt="Unlock this Level!" />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} mt={3}>
          <Box>
            <SmallBadgeImage src={imgBadgeLvl1} alt="Badge" />
          </Box>
          <Box>
            <Typography
              fontSize={20}
              fontWeight={700}
              lineHeight={1.5}
              color="raisinBlack"
              px={1}
            >
              Level 1: Initiate
            </Typography>
            <Typography
              fontSize={16}
              lineHeight={1.5}
              color="raisinBlack"
              px={1}
              mt={1}
              component="div"
            >
              <Typography component="ul" my={0}>
                <li>Complete 3 Learn and Earn Challenges</li>
              </Typography>
              <br />
              {chainName === 'vechain' ? (
                <>
                  <b>Unlock:</b> Oily $B3TR Lottery!
                </>
              ) : null}
            </Typography>
          </Box>
        </Stack>
        <Box mt={4}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const AccountLvl2InfoModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <BadgeImage alt="Badge" src={imgBadgeLvl2} />
        </Stack>
        <Typography
          mt={3}
          fontSize={20}
          fontWeight={700}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
        >
          Level 2: Green Apprentice
        </Typography>
        <Typography
          fontSize={16}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
          component="div"
        >
          <Typography component="ul" my={0}>
            <li>Complete 7 Learn and Earn Challenges</li>
            <li>Achieve 3 days of consecutive logins</li>
          </Typography>
          <br />
          <b>Unlock:</b>
          <Typography component="ul" my={0}>
            <li>Mint Ambassador Challenge NFT</li>
          </Typography>
        </Typography>
        <Box mt={4}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const LockedAccountLvl2InfoModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <Box position="relative">
            <OilyImage src={imgOily} alt="Oily" />
            <BubbleImage src={imgBubble} alt="Unlock this Level!" />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} mt={3}>
          <Box>
            <SmallBadgeImage src={imgBadgeLvl2} alt="Badge" />
          </Box>
          <Box>
            <Typography
              fontSize={20}
              fontWeight={700}
              lineHeight={1.5}
              color="raisinBlack"
              px={1}
            >
              Level 2: Green Apprentice
            </Typography>
            <Typography
              fontSize={16}
              lineHeight={1.5}
              color="raisinBlack"
              px={1}
              mt={1}
              component="div"
            >
              <Typography component="ul" my={0}>
                <li>Complete 7 Learn and Earn Challenges</li>
                <li>Achieve 3 days of consecutive logins</li>
              </Typography>
              <br />
              <b>Unlock:</b> Mint Ambassador Challenge NFT
            </Typography>
          </Box>
        </Stack>
        <Box mt={4}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const AccountLvl3InfoModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <BadgeImage alt="Badge" src={imgBadgeLvl3} />
        </Stack>
        <Typography
          mt={3}
          fontSize={20}
          fontWeight={700}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
        >
          Level 3: Sustainability Explorer
        </Typography>
        <Typography
          fontSize={16}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
          component="div"
        >
          <Typography component="ul" my={0}>
            <li>Complete 12 Learn and Earn Challenges</li>
          </Typography>
          <br />
          <b>Unlock:</b>
          <Typography component="ul" my={0}>
            <li>Redeem Feature</li>
          </Typography>
        </Typography>
        <Box mt={4}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const LockedAccountLvl3InfoModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <Box position="relative">
            <OilyImage src={imgOily} alt="Oily" />
            <BubbleImage src={imgBubble} alt="Unlock this Level!" />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} mt={3}>
          <Box>
            <SmallBadgeImage src={imgBadgeLvl3} alt="Badge" />
          </Box>
          <Box>
            <Typography
              fontSize={20}
              fontWeight={700}
              lineHeight={1.5}
              color="raisinBlack"
              px={1}
            >
              Level 3: Sustainability Explorer
            </Typography>
            <Typography
              fontSize={16}
              lineHeight={1.5}
              color="raisinBlack"
              px={1}
              mt={1}
              component="div"
            >
              <Typography component="ul" my={0}>
                <li>Complete 12 Learn and Earn Challenges</li>
              </Typography>
              <br />
              <b>Unlock:</b> Redeem Feature
            </Typography>
          </Box>
        </Stack>
        <Box mt={4}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const AccountLvl4InfoModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <BadgeImage alt="Badge" src={imgBadgeLvl4} />
        </Stack>
        <Typography
          mt={3}
          fontSize={20}
          fontWeight={700}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
        >
          Level 4: UCO Enthusiast
        </Typography>
        <Typography
          fontSize={16}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
          component="div"
        >
          <Typography component="ul" my={0}>
            <li>Complete all 16 Learn and Earn Chapters</li>
            <li>Achieve 14 days on the platform</li>
          </Typography>
          <br />
          <b>Unlock:</b>
          <Typography component="ul" my={0}>
            <li>Ability to apply for Map and Earn</li>
          </Typography>
        </Typography>
        <Box mt={4}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const LockedAccountLvl4InfoModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <Box position="relative">
            <OilyImage src={imgOily} alt="Oily" />
            <BubbleImage src={imgBubble} alt="Unlock this Level!" />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} mt={3}>
          <Box>
            <SmallBadgeImage src={imgBadgeLvl4} alt="Badge" />
          </Box>
          <Box>
            <Typography
              fontSize={20}
              fontWeight={700}
              lineHeight={1.5}
              color="raisinBlack"
              px={1}
            >
              Level 4: UCO Enthusiast
            </Typography>
            <Typography
              fontSize={16}
              lineHeight={1.5}
              color="raisinBlack"
              px={1}
              mt={1}
              component="div"
            >
              <Typography component="ul" my={0}>
                <li>Complete all 16 Learn and Earn Chapters</li>
                <li>Achieve 14 days on the platform</li>
              </Typography>
              <br />
              <b>Unlock:</b> Ability to apply for Map and Earn
            </Typography>
          </Box>
        </Stack>
        <Box mt={4}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const AccountLvl5InfoModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <BadgeImage alt="Badge" src={imgBadgeLvl5} />
        </Stack>
        <Typography
          mt={3}
          fontSize={20}
          fontWeight={700}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
        >
          Level 5: Map Explorer
        </Typography>
        <Typography
          fontSize={16}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
          component="div"
        >
          Pre-requisites met:
          <Typography component="ul" my={0}>
            <li>+5000 XP</li>
            <li>Own Oily Ambassador NFT</li>
            <li>Lock 3000 XP</li>
          </Typography>
          <br />
          <b>Unlock:</b>
          <Typography component="ul" my={0}>
            <li>Map and Earn limited feature</li>
          </Typography>
        </Typography>
        <Box mt={4}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const LockedAccountLvl5InfoModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <Box position="relative">
            <OilyImage src={imgOily} alt="Oily" />
            <BubbleImage src={imgBubble} alt="Unlock this Level!" />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} mt={3}>
          <Box>
            <SmallBadgeImage src={imgBadgeLvl5} alt="Badge" />
          </Box>
          <Box>
            <Typography
              fontSize={20}
              fontWeight={700}
              lineHeight={1.5}
              color="raisinBlack"
              px={1}
            >
              Level 5: Map Explorer
            </Typography>
            <Typography
              fontSize={16}
              lineHeight={1.5}
              color="raisinBlack"
              px={1}
              mt={1}
              component="div"
            >
              Pre-requisites met:
              <Typography component="ul" my={0}>
                <li>+5000 XP</li>
                <li>Own Oily Ambassador NFT</li>
                <li>Lock 3000 XP</li>
              </Typography>
              <br />
              <b>Unlock:</b> Map and Earn limited feature - limited to 10
              mappings
            </Typography>
          </Box>
        </Stack>
        <Box mt={4}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const AccountLvl6InfoModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <BadgeImage alt="Badge" src={imgBadgeLvl6} />
        </Stack>
        <Typography
          mt={3}
          fontSize={20}
          fontWeight={700}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
        >
          Level 6: Verified Mapper
        </Typography>
        <Typography
          fontSize={16}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
          component="div"
        >
          <Typography component="ul" my={0}>
            <li>Successfully map and verify 10 restaurants</li>
            <li>Receive locked 3000 XP back + XP for mapped locations</li>
          </Typography>
          <br />
          <b>Unlock:</b>
          <Typography component="ul" my={0}>
            <li>
              Full access to Map and Earn feature (possibility to add pictures)
            </li>
            <li>Ability to ADD new restaurants to map</li>
            <li>Unlock referral url</li>
            <li>XP multiplier 1.1</li>
          </Typography>
        </Typography>
        <Box mt={4}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const AccountLvl7InfoModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <BadgeImage alt="Badge" src={imgBadgeLvl7} />
        </Stack>
        <Typography
          mt={3}
          fontSize={20}
          fontWeight={700}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
        >
          Level 7: UCO Champion
        </Typography>
        <Typography
          fontSize={16}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
          component="div"
        >
          <Typography component="ul" my={0}>
            <li>Map 20+ verified restaurants - verified by the team</li>
            <li>Added 30+ verified restaurants - verified by the team</li>
          </Typography>
          <br />
          <b>Unlock:</b>
          <Typography component="ul" my={0}>
            <li>Full Access to Map and Earn, including Add WMO</li>
            <li>Possibility to map restaurant even if 5kms away</li>
            <li>Unlock access to $Oily page</li>
          </Typography>
        </Typography>
        <Box mt={4}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const AccountLvl8InfoModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <BadgeImage alt="Badge" src={imgBadgeLvl8} />
        </Stack>
        <Typography
          mt={3}
          fontSize={20}
          fontWeight={700}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
        >
          Level 8: UCO Legend
        </Typography>
        <Typography
          fontSize={16}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
          component="div"
        >
          <Typography component="ul" my={0}>
            <li>Map 50+ verified locations</li>
          </Typography>
          <br />
          <b>Unlock:</b>
          <Typography component="ul" my={0}>
            <li>Access to Ambassador Lottery</li>
            <li>XP multiplier 1.2</li>
          </Typography>
        </Typography>
        <Box mt={4}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const AccountLvl9InfoModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <BadgeImage alt="Badge" src={imgBadgeLvl9} />
        </Stack>
        <Typography
          mt={3}
          fontSize={20}
          fontWeight={700}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
        >
          Level 9: Mapping Master
        </Typography>
        <Typography
          fontSize={16}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
          component="div"
        >
          <Typography component="ul" my={0}>
            <li>Map 100+ verified locations</li>
            <li>Consistently high-quality mapping data (95% accuracy)</li>
          </Typography>
          <br />
          <b>Unlock:</b>{' '}
          <Typography component="ul" my={0}>
            <li>Beta: Recycle & Earn feature</li>
            <li>
              Gain ability to add detailed notes and communication history to
              mapped locations
            </li>
          </Typography>
        </Typography>
        <Box mt={4}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const AccountLvl10InfoModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <BadgeImage alt="Badge" src={imgBadgeLvl10} />
        </Stack>
        <Typography
          mt={3}
          fontSize={20}
          fontWeight={700}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
        >
          Level 10: Oily Ambassador
        </Typography>
        <Typography
          fontSize={16}
          lineHeight={1.5}
          color="raisinBlack"
          px={1}
          component="div"
        >
          <Typography component="ul" my={0}>
            <li>Map 150+ verified locations</li>
          </Typography>
          <br />
          <b>Unlock:</b>
          <Typography component="ul" my={0}>
            <li>Exclusive rewards for posting on Socials</li>
            <li>Ability to Redeem an Oily Ambassador Kit</li>
            <li>XP multiplier 1.3</li>
          </Typography>
        </Typography>
        <Box mt={4}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

AccountLvl0InfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
AccountLvl1InfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
LockedAccountLvl1InfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
AccountLvl2InfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
LockedAccountLvl2InfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
AccountLvl3InfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
LockedAccountLvl3InfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
AccountLvl4InfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
LockedAccountLvl4InfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
AccountLvl5InfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
LockedAccountLvl5InfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
AccountLvl6InfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
AccountLvl7InfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
AccountLvl8InfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
AccountLvl9InfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
AccountLvl10InfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

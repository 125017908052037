import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { Scaffold } from '../layouts/Scaffold';
import { XIcon } from 'lucide-react';
import { apiGetAccountLevel } from '../../apis/profile';

import imgOily from '../../assets/onboarding/oily-9.png';
import { LoadingButton } from '@mui/lab';

export const CompleteNftDialog = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);

  const handleContinue = useCallback(async () => {
    setLoading(true);
    await apiGetAccountLevel();
    onClose();
    setLoading(false);
  }, [onClose]);

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Scaffold bgcolor="royalBlueDark" height="100vh">
        <Scaffold.Header>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={2}
            pt={1}
            pb={2}
            minHeight={56}
          >
            <Box width={26} />
            <Typography fontSize={14} color="isabelline" lineHeight={1}>
              Mint NFT
            </Typography>
            <IconButton color="isabelline" size="small" onClick={onClose}>
              <XIcon />
            </IconButton>
          </Stack>
        </Scaffold.Header>
        <Scaffold.Content>
          <Stack py={2} direction="row" justifyContent="center">
            <img src={imgOily} alt="oily" />
          </Stack>
          <Box mt={2} px={3}>
            <Typography fontSize={32} lineHeight={1.25} color="isabelline">
              Congratulations!
            </Typography>
            <Typography fontSize={32} lineHeight={1.25} color="beauBlue">
              You&apos;ve successfully minted your NFT! Your new NFT is now in
              your Wallet. Keep earning XP to unlock even more rewards! 🚀
            </Typography>
          </Box>
          <Box mt={6} px={3}>
            <LoadingButton
              variant="contained"
              color="icterine"
              fullWidth
              loading={loading}
              onClick={handleContinue}
            >
              Continue
            </LoadingButton>
          </Box>
        </Scaffold.Content>
      </Scaffold>
    </Dialog>
  );
};

CompleteNftDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export const MuiLinearProgress = {
  styleOverrides: {
    root: {
      height: 4,
      borderRadius: 8,
    },
    bar: ({ theme }) => ({
      background: theme.palette.icterine,
    }),
  },
  variants: [
    {
      props: {
        variant: 'determinate',
        color: 'light',
      },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.isabelline,
      }),
    },
    {
      props: {
        variant: 'determinate',
        color: 'dark',
      },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.charcoal,
      }),
    },
  ],
  defaultProps: {
    color: 'light',
  },
};

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  RedditShareButton,
  RedditIcon,
} from 'react-share';
import { apiUploadFile } from '../../apis/files';

import { ReactComponent as SvgLogo } from '../../assets/brands/dark-brand-logo.svg';
import imgLogo from '../../assets/brands/dark-brand-logo.png';

export const ShareOptionsModal = ({ url, title, open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
          Choose where to share
        </Typography>
        <Box mt={4}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              '& button': {
                flex: 1,
                borderRadius: 2,
                overflow: 'hidden',
                aspectRatio: 1,
                '& svg': {
                  width: '100%',
                  height: '100%',
                },
              },
            }}
          >
            <TelegramShareButton url={url} title={title}>
              <TelegramIcon />
            </TelegramShareButton>
            <WhatsappShareButton url={url} title={title}>
              <WhatsappIcon />
            </WhatsappShareButton>
            <TwitterShareButton url={url} title={title}>
              <TwitterIcon />
            </TwitterShareButton>
            <FacebookShareButton url={url} title={title}>
              <FacebookIcon />
            </FacebookShareButton>
            <LinkedinShareButton url={url} title={title}>
              <LinkedinIcon />
            </LinkedinShareButton>
            <RedditShareButton url={url} title={title}>
              <RedditIcon />
            </RedditShareButton>
          </Stack>
        </Box>
        <Box mt={8}>
          <Button variant="outlined" color="raisinBlack" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const SharePreviewModal = ({ amount, open, onClose }) => {
  const [shareOptionsOpen, setShareOptionsOpen] = useState(false);
  const [url, setUrl] = useState(null);
  const [title, setTitle] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleToggleModal = useCallback(() => {
    setShareOptionsOpen((prev) => !prev);
  }, []);

  const handleShare = useCallback(() => {
    setLoading(true);

    const canvas = document.createElement('canvas');
    canvas.width = 360;
    canvas.height = 460;

    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.fillStyle = '#EBF155';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.font = '500 32px Manrope';
      ctx.fillStyle = '#00195A';
      ctx.fillText('I’ve just earn', 32, 224);
      ctx.fillText(`${amount.toFixed(0)} XP with UCO`, 32, 264);
      ctx.fillText('Community app.', 32, 304);

      ctx.font = '700 18px Manrope';
      ctx.fillStyle = '#00195A';
      ctx.fillText('Join now!', 32, 381);

      ctx.font = '18px Manrope';
      ctx.fillStyle = '#00195A';
      ctx.fillText('Start earning rewards', 32, 408);

      const img = new Image();
      img.src = imgLogo;
      img.onload = async () => {
        ctx.drawImage(img, 32, 32, 208, 64);

        const file = canvas.toDataURL('image/png');
        const blob = await (await fetch(file)).blob();

        const result = await apiUploadFile({
          key: 'file',
          files: [blob],
        });
        if (result && result.length > 0) {
          const [url] = result;

          setTitle('Join UCO.Network');
          setUrl(
            `https://welcome.oily.uco.network/share?content=${url}&title=${encodeURIComponent('Join UCO.Network')}&message=${encodeURIComponent(`I’ve just earn ${amount.toFixed(0)} XP with UCO Community app.`)}&return=${window.location.origin}`,
          );
          handleToggleModal();
        }
        setLoading(false);
      };
      img.onerror = () => {
        setLoading(false);
      };
    } else {
      setLoading(false);
    }
  }, [handleToggleModal, amount]);

  return (
    <Modal open={open} onClose={onClose}>
      <Stack
        direction="column"
        justifyContent="space-between"
        width="100vw"
        height="100vh"
        boxSizing="border-box"
      >
        <Box bgcolor="icterine" borderRadius={6} p={3} m={1}>
          <SvgLogo />
          <Typography
            mt={11}
            fontSize={32}
            lineHeight={1.25}
            color="raisinBlack"
          >
            I&apos;ve just earn {amount} XP with UCO Community app.
          </Typography>
          <Typography mt={3} fontSize={18} lineHeight={1.5} color="raisinBlack">
            <b>Join now!</b>
            <br />
            Start earning rewards
          </Typography>
        </Box>
        <Stack direction="row" spacing={2} p={3}>
          <Button
            variant="contained"
            color="charcoal"
            sx={{ flex: 1 }}
            onClick={onClose}
          >
            Close
          </Button>
          <LoadingButton
            variant="contained"
            color="icterine"
            loading={loading}
            sx={{ flex: 2 }}
            onClick={handleShare}
          >
            Share now
          </LoadingButton>
        </Stack>
        <ShareOptionsModal
          url={url}
          title={title}
          open={shareOptionsOpen}
          onClose={() => {
            handleToggleModal();
            onClose();
          }}
        />
      </Stack>
    </Modal>
  );
};

SharePreviewModal.propTypes = {
  amount: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

ShareOptionsModal.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

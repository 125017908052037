import axios from 'axios';

export const apiGetArticles = async (params) => {
  try {
    const response = await axios.post('/api/client/v1/user/news', params);
    return response.data.data;
  } catch {
    return [];
  }
};

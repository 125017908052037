import axios from 'axios';

export const apiUploadFile = async (data) => {
  try {
    const formData = new FormData();
    formData.append('key', data.key);
    for (const file of data.files) {
      formData.append('files', file);
    }
    const response = await axios.post('/api/common/v1/file/upload', formData);
    return response.data.data;
  } catch (e) {
    return null;
  }
};

import React, { useCallback, useEffect } from 'react';
import { Box, ButtonBase, IconButton, Stack, Typography } from '@mui/material';
import { Scaffold } from '../../components/layouts/Scaffold';
import { XpIndicator } from '../../components/core/XpIndicator';
import { ProgressPart } from './partials/ProgressPart';
import { ContributionsPart } from './partials/ContributionsPart';
import { RafflesPart } from './partials/RafflesPart';
import { ArrowLeftIcon } from 'lucide-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import imgOily from '../../assets/onboarding/oily-5.png';

export const ProgressPage = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();

  const { chainName } = useSelector((state) => state.wallet);

  const qTab = query.get('tab');

  const handleChangeTab = useCallback(
    (tabIndex) => {
      return () => {
        const values = new URLSearchParams(query);
        values.set('tab', tabIndex);
        setQuery(values);
      };
    },
    [query, setQuery],
  );

  const handleBack = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  useEffect(() => {
    if (qTab !== 'progress' && qTab !== 'contributions' && qTab !== 'raffles') {
      const values = new URLSearchParams(query);
      values.set('tab', 'progress');
      setQuery(values);
    }
  }, [qTab, query, setQuery]);

  return (
    <Scaffold bgcolor="raisinBlack" height="100vh">
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={1}
          pb={2}
          minHeight={56}
        >
          <IconButton
            color="isabelline"
            size="small"
            sx={{ mr: 2 }}
            onClick={handleBack}
          >
            <ArrowLeftIcon />
          </IconButton>
          <XpIndicator />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Box
          bgcolor="#1D1C27"
          py={5}
          px={3}
          sx={{
            backgroundImage: `url(${imgOily})`,
            backgroundPosition: 'right bottom',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Typography fontSize={32} lineHeight={1.25} color="isabelline">
            Become an Oily
          </Typography>
          <Typography fontSize={32} lineHeight={1.25} color="beauBlue">
            Ambassador
          </Typography>
        </Box>
        <Box mt={2} px={2} mb={2}>
          <Stack
            direction="row"
            p={1}
            spacing={1}
            bgcolor="charcoal"
            borderRadius={2}
          >
            <ButtonBase
              sx={{
                flex: 1,
                py: '10px',
                borderRadius: 2,
                bgcolor: qTab === 'progress' ? 'raisinBlack' : 'transparent',
              }}
              onClick={handleChangeTab('progress')}
            >
              <Typography color="isabelline" fontSize={14} fontWeight={500}>
                Progress
              </Typography>
            </ButtonBase>
            <ButtonBase
              sx={{
                flex: 1,
                py: '10px',
                borderRadius: 2,
                bgcolor:
                  qTab === 'contributions' ? 'raisinBlack' : 'transparent',
              }}
              onClick={handleChangeTab('contributions')}
            >
              <Typography color="isabelline" fontSize={14} fontWeight={500}>
                Contributions
              </Typography>
            </ButtonBase>
            {chainName === 'vechain' && (
              <ButtonBase
                sx={{
                  flex: 1,
                  py: '10px',
                  borderRadius: 2,
                  bgcolor: qTab === 'raffles' ? 'raisinBlack' : 'transparent',
                }}
                onClick={handleChangeTab('raffles')}
              >
                <Typography color="isabelline" fontSize={14} fontWeight={500}>
                  Raffles
                </Typography>
              </ButtonBase>
            )}
          </Stack>
        </Box>
        {qTab === 'progress' ? <ProgressPart /> : null}
        {qTab === 'contributions' ? <ContributionsPart /> : null}
        {qTab === 'raffles' && chainName === 'vechain' ? <RafflesPart /> : null}
        <Box height={100} />
      </Scaffold.Content>
    </Scaffold>
  );
};

export const MuiCircularProgress = {
  defaultProps: {},
  styleOverrides: {},
  variants: [
    {
      props: {
        color: 'icterine',
      },
      style: ({ theme }) => ({
        color: theme.palette.icterine,
      }),
    },
    {
      props: {
        color: 'raisinBlack',
      },
      style: ({ theme }) => ({
        color: theme.palette.raisinBlack,
      }),
    },
    {
      props: {
        color: 'icterine',
        bgcolor: 'purpleNavy',
      },
      style: ({ theme }) => ({
        color: theme.palette.icterine,
        '&::before': {
          content: '""',
          position: 'absolute',
          display: 'block',
          width: '100%',
          height: '100%',
          borderRadius: '100%',
          border: `3.6px solid ${theme.palette.purpleNavy}`,
          boxSizing: 'border-box',
          zIndex: -1,
        },
      }),
    },
    {
      props: {
        color: 'icterine',
        bgcolor: 'royalBlueDark',
      },
      style: ({ theme }) => ({
        color: theme.palette.icterine,
        '&::before': {
          content: '""',
          position: 'absolute',
          display: 'block',
          width: '100%',
          height: '100%',
          borderRadius: '100%',
          border: `3.6px solid ${theme.palette.royalBlueDark}`,
          boxSizing: 'border-box',
          zIndex: -1,
        },
      }),
    },
  ],
};

export const MuiModal = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& > .MuiBox-root': {
        position: 'absolute',
        bottom: 8,
        left: '50%',
        transform: 'translateX(-50%)',
        width: 'calc(100vw - 16px)',
        height: 'auto',
        maxHeight: '90vh',
        backgroundColor: theme.palette.isabelline,
        borderRadius: 24,
        boxSizing: 'border-box',
        padding: 24,
        paddingTop: 48,
        overflow: 'auto',
        maxWidth: 624,

        '&.centered': {
          top: '50%',
          transform: 'translate(-50%, -50%)',
          bottom: 'unset',
        },
      },
    }),
  },
};

export const MuiDialog = {
  styleOverrides: {
    root: {
      '& .MuiPaper-root.MuiDialog-paper': {
        maxWidth: 640,
      },
    },
  },
};

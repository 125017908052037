import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Stack, Typography, useTheme } from '@mui/material';
import { CheckIcon, XIcon } from 'lucide-react';

export const SelectChoice = ({ checked, label, error, onChange }) => {
  const theme = useTheme();

  const handleChange = useCallback(() => {
    if (onChange) {
      onChange(!checked);
    }
  }, [checked, onChange]);

  return (
    <ButtonBase
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        p: 1,
        mb: 1,
        borderRadius: 2,
        bgcolor: error
          ? `${theme.palette.error[400]}20`
          : checked
            ? `${theme.palette.icterine}20`
            : 'transparent',
        '& .MuiTouchRipple-child': {
          backgroundColor: 'icterine',
        },
      }}
      onClick={handleChange}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          width: 24,
          height: 24,
          borderWidth: 2,
          borderRadius: 24,
          borderStyle: 'solid',
          borderColor: error
            ? 'error.400'
            : checked
              ? 'icterine'
              : `${theme.palette.isabelline}80`,
          bgcolor: error ? 'error.400' : checked ? 'icterine' : 'transparent',
          color: error ? 'error.500' : 'raisinBlack',
        }}
      >
        {error ? (
          <XIcon size={16} strokeWidth={4} />
        ) : checked ? (
          <CheckIcon size={16} strokeWidth={4} />
        ) : null}
      </Stack>
      <Typography
        fontSize={18}
        fontWeight={500}
        lineHeight={1.5}
        color="isabelline"
        ml={1.5}
        flex={1}
        textAlign="left"
      >
        {label}
      </Typography>
    </ButtonBase>
  );
};

SelectChoice.propTypes = {
  checked: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

import React, { useEffect, useState } from 'react';
import { Box, ButtonBase, Stack, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ArrowRightIcon } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { apiGetRaffles } from '../../apis/raffles';

import imgConfetti from '../../assets/backgrounds/transparent-confetti-bg.png';
import imgOily from '../../assets/onboarding/oily-13.png';

export const RafflesSection = () => {
  const { account, chainName } = useSelector((state) => state.wallet);

  const [status, setStatus] = useState('');

  const { data, isLoading } = useQuery({
    queryKey: ['raffles'],
    queryFn: async () => {
      return await apiGetRaffles();
    },
  });

  useEffect(() => {
    if (data) {
      if (data.data && data.data.length) {
        if (
          data.data[0].wallet_address === account &&
          data.data[0].me_voted &&
          data.data[0].transaction_id
        ) {
          setStatus(data.data[0].type);
          return;
        }
      }
    }
    setStatus('');
  }, [data, account]);

  if (chainName !== 'vechain') {
    return null;
  }

  if (isLoading || !data) {
    return null;
  }

  if (status) {
    return (
      <Box p={3} bgcolor="#1D1C27">
        <Box
          mb={3}
          sx={{
            background: `url(${imgConfetti})`,
            backgroundRepeat: 'repeat',
          }}
        >
          <Typography fontSize={32} lineHeight={1.25} color="isabelline">
            Congratulations!
          </Typography>
          <Typography
            fontSize={32}
            fontWeight={500}
            lineHeight={1.25}
            color="icterine"
          >
            You&apos;re the winner of the{' '}
            {status === 'four_week' ? '4-week' : 'weekly'} raffle!
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2.5} mt={2}>
            <img src={imgOily} alt="oily" />
            <Typography
              flex={1}
              fontSize={16}
              lineHeight={1.75}
              color="isabelline"
            >
              Your prize has been sent to your wallet.
            </Typography>
          </Stack>
        </Box>
        <ButtonBase component={NavLink} to="/">
          <Stack direction="row" alignItems="center">
            <Typography fontSize={32} lineHeight={1} color="isabelline" mr={2}>
              Check my Raffles
            </Typography>
            <Box color="isabelline">
              <ArrowRightIcon />
            </Box>
          </Stack>
        </ButtonBase>
      </Box>
    );
  } else {
    return null;
  }
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { XpGauge } from '../../../components/core/XpGauge';
import { CircleCheckIcon, CopyIcon, XCircleIcon } from 'lucide-react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { apiGetMyLocations } from '../../../apis/m2e';

import imgOily from '../../../assets/onboarding/oily-8.png';

const ContributionItem = ({ item }) => {
  const { m2eCreatePlaceXp, m2eMapPlaceXp } = useSelector(
    (state) => state.app.constants,
  );

  return (
    <Box
      sx={{
        borderRadius: 4,
        bgcolor:
          item.status === 'pending'
            ? 'yellow.400'
            : item.status === 'approved'
              ? 'green.400'
              : 'error.400',
        width: '100%',
        boxSizing: 'border-box',
        px: 2,
        py: 2,
        textAlign: 'left',
      }}
    >
      <Stack
        display="inline-flex"
        direction="row"
        alignItems="center"
        px={1}
        py={0.5}
        fontSize={14}
        lineHeight={1.75}
        color={
          item.status === 'pending'
            ? 'raisinBlack'
            : item.status === 'approved'
              ? 'green.700'
              : 'error.600'
        }
        bgcolor="white"
        spacing={0.5}
        sx={{
          borderRadius: 2,
          borderColor:
            item.status === 'pending'
              ? 'raisinBlack'
              : item.status === 'approved'
                ? 'green.500'
                : 'error.600',
          borderStyle: 'solid',
          borderWidth: 1,
        }}
      >
        {item.status === 'disapproved' ? (
          <XCircleIcon width={16} height={16} />
        ) : (
          <CircleCheckIcon width={16} height={16} />
        )}
        <span>
          {item.status === 'pending'
            ? 'Under review'
            : item.status === 'approved'
              ? 'Approved'
              : 'Disapproved'}
        </span>
      </Stack>
      <Typography my={1} fontSize={18} lineHeight={1.3} color="pennBlue">
        {item.type === 'mapped'
          ? 'Mapped restaurant'
          : item.type === 'added'
            ? 'Added restaurant'
            : ''}
        :&nbsp;
        {item.name}
      </Typography>
      {item.status === 'disapproved' ? (
        <Typography fontSize={14} lineHeight={1.25} color="shadowBlue">
          {item.reject_reason}
        </Typography>
      ) : (
        <XpGauge
          xp={
            item.type === 'added'
              ? m2eCreatePlaceXp
              : item.type === 'mapped'
                ? m2eMapPlaceXp
                : 0
          }
          iconProps={{
            width: 16,
            height: 16,
          }}
          xpProps={{
            fontSize: 14,
            lineHeight: 1,
            color: 'shadowBlue',
          }}
        />
      )}
    </Box>
  );
};

export const ContributionsPart = () => {
  const {
    xp: currentXp,
    lockedXp,
    ratio,
  } = useSelector((state) => state.profile);
  const { account } = useSelector((state) => state.wallet);
  const xp = currentXp - lockedXp;

  const { data, status, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ['m2e', 'my-locations'],
      queryFn: async ({ pageParam }) => {
        return await apiGetMyLocations({
          skip: pageParam,
          limit: 10,
        });
      },
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < 10) {
          return undefined;
        }
        return allPages.length * 10;
      },
    });

  const handleCopyWallet = useCallback(() => {
    if (account) {
      copy(account);
      enqueueSnackbar({
        message: 'Your wallet address copied to clipboard',
        variant: 'success',
      });
    }
  }, [account]);

  return (
    <Box px={2}>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1.5}
      >
        {status === 'pending' ? (
          Array(5)
            .fill(true)
            .map((_, index) => (
              <Skeleton
                key={`loading-${index}`}
                variant="rounded"
                width="100%"
                height={80}
                sx={{
                  borderRadius: 4,
                }}
              />
            ))
        ) : data?.pages?.length > 0 && data?.pages[0].length > 0 ? (
          data.pages.map((items, p) =>
            items.map((item, q) => (
              <ContributionItem key={`item-${p}-${q}`} item={item} />
            )),
          )
        ) : (
          <>
            <Box height={24} />
            <img src={imgOily} alt="oily" />
            <Typography
              mt={3}
              fontSize={32}
              fontWeight={500}
              lineHeight={1.25}
              color="isabelline"
              textAlign="center"
            >
              Nothing here yet
            </Typography>
            <Typography
              mt={1}
              fontSize={14}
              lineHeight={1.75}
              color="isabelline"
              maxWidth={240}
              textAlign="center"
            >
              Reach Level 5 & Start Contributing to the Used Cooking Oil
              Industry!
              <br />
              One drop at a time!
            </Typography>
            <Box height={24} />
          </>
        )}
        {hasNextPage ? (
          <Box pt={6}>
            <LoadingButton
              variant="outlined"
              size="small"
              color="icterine"
              loading={isFetchingNextPage}
              onClick={fetchNextPage}
            >
              Load more
            </LoadingButton>
          </Box>
        ) : null}
      </Stack>
      <Box px={2} mt={11}>
        <Typography
          fontSize={16}
          fontWeight={700}
          lineHeight={1.75}
          color="isabelline"
        >
          Your wallet address
        </Typography>
        <Stack direction="row" alignItems="center" mt={0.5}>
          <Typography fontSize={14} lineHeight={1} color="isabelline" mr={1}>
            {account?.substr(0, 10)}...{account?.substr(-8, 8)}
          </Typography>
          <IconButton
            size="small"
            color="isabelline"
            onClick={handleCopyWallet}
          >
            <CopyIcon />
          </IconButton>
        </Stack>
      </Box>
      <Box px={2} mt={4}>
        <Typography
          fontSize={16}
          fontWeight={700}
          lineHeight={1.75}
          color="isabelline"
        >
          Current balance
        </Typography>
        <XpGauge xp={(xp ?? 0).toFixed(0)} />
        <Typography mt={0.5} fontSize={14} lineHeight={1.25} color="beauBlue">
          ~ {(((xp ? xp : 0) * (ratio ?? 1)) / 100).toFixed(3)} B3TR
        </Typography>
        <Typography mt={0.5} fontSize={10} lineHeight={1.25} color="shadowBlue">
          ~{(1 / (ratio ? ratio / 100 : 0.01)).toLocaleString()} XP per 1 B3TR
        </Typography>
      </Box>
      <Box height={60} />
    </Box>
  );
};

ContributionItem.propTypes = {
  item: PropTypes.any,
};

import React, { useCallback } from 'react';
import { Box, ButtonBase, Stack, Typography, useTheme } from '@mui/material';
import { ArrowRightIcon } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const TokenLaunchSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { level, tgeOptIn } = useSelector((state) => state.profile);

  const handleClick = useCallback(() => {
    navigate('/about/token-launch');
  }, [navigate]);

  if (level < 7 || tgeOptIn) {
    return null;
  }

  return (
    <Box px={1} mb={1}>
      <ButtonBase
        sx={{
          borderRadius: 4,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'shadowBlue',
          display: 'block',
          width: '100%',
          boxSizing: 'border-box',
          px: 3,
          py: 4,
          textAlign: 'left',
        }}
        onClick={handleClick}
      >
        <Typography
          fontSize={24}
          fontWeight={500}
          lineHeight={1.3}
          color="beauBlue"
        >
          🚀 $OILY Token Launch
        </Typography>
        <Typography
          fontSize={40}
          fontWeight={500}
          lineHeight={1.3}
          color="beauBlue"
        >
          Opportunity!
        </Typography>
        <Typography mt={2} fontSize={28} lineHeight={1.25} color="shadowBlue">
          You&apos;re invited to join the $OILY Airdrop!
        </Typography>
        <Stack mt={3} direction="row" alignItems="center" spacing={2}>
          <Typography fontSize={32} lineHeight={1.25} color="isabelline">
            Access Now
          </Typography>
          <ArrowRightIcon color={theme.palette.isabelline} />
        </Stack>
      </ButtonBase>
    </Box>
  );
};

import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  ButtonBase,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Scaffold } from '../../components/layouts/Scaffold';
import { NavLink, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from 'lucide-react';

import imgGuide from '../../assets/guides/m2e-guide.png';

export const AboutM2EPage = () => {
  const navigate = useNavigate();

  const [tab, setTab] = useState(0);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleChangeTab = useCallback((target) => {
    return () => {
      setTab(target);
    };
  }, []);

  return (
    <Scaffold bgcolor="raisinBlack" height="100vh">
      <Scaffold.Header>
        <Stack
          px={3}
          pb={1}
          pt={3}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton size="small" color="isabelline" onClick={handleBack}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography
            color="isabelline"
            fontSize={14}
            lineHeight={1}
            flex={1}
            textAlign="center"
          >
            Requesting Mapping Info
          </Typography>
          <Box width={26} />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content mt={1} px={1} pt={2}>
        <img
          src={imgGuide}
          alt="branding"
          style={{
            width: '100%',
          }}
        />
        <Stack
          direction="row"
          mt={4}
          p={1}
          spacing={1}
          bgcolor="charcoal"
          borderRadius={2}
        >
          <ButtonBase
            sx={{
              flex: 1,
              py: '10px',
              borderRadius: 2,
              bgcolor: tab === 0 ? 'raisinBlack' : 'transparent',
            }}
            onClick={handleChangeTab(0)}
          >
            <Typography color="isabelline" fontSize={14} fontWeight={500}>
              Restaurant Guide
            </Typography>
          </ButtonBase>
          <ButtonBase
            sx={{
              flex: 1,
              py: '10px',
              borderRadius: 2,
              bgcolor: tab === 1 ? 'raisinBlack' : 'transparent',
            }}
            onClick={handleChangeTab(1)}
          >
            <Typography color="isabelline" fontSize={14} fontWeight={500}>
              Mapping WMOs
            </Typography>
          </ButtonBase>
        </Stack>
        <Box mt={4} px={3}>
          {tab === 0 ? (
            <React.Fragment>
              <Typography fontSize={32} lineHeight={1.25} color="isabelline">
                Your Guide to Restaurant Data Collection
              </Typography>
              <Typography mt={2} color="isabelline">
                Most restaurant details can be found online - but we need your
                help with the crucial UCO recycling info! Here&apos;s how to
                make it happen:
              </Typography>
              <Typography mt={2} fontSize={26} color="isabelline">
                The Perfect Intro
              </Typography>
              <Typography
                mt={1}
                mb={1}
                color="beauBlue"
                fontSize={16}
                fontStyle="italic"
                lineHeight={1.75}
              >
                &quot;Hi! I&apos;m helping map restaurants for sustainable
                cooking oil recycling through an app that rewards environmental
                impact. Would you have 2 minutes to chat about your oil
                recycling practices?&quot;
              </Typography>
              <Typography mt={1} mb={1} fontSize={26} color="isabelline">
                Key Questions
              </Typography>
              <Box component="ul" my={0}>
                <Typography component="li" fontSize={16} color="isabelline">
                  Scout restaurants and identify UCO recycling opportunities
                </Typography>
                <Typography component="li" fontSize={16} color="isabelline">
                  Track your impact saving the world
                </Typography>
                <Typography component="li" fontSize={16} color="isabelline">
                  Add specialized data as you level up
                </Typography>
                <Typography component="li" fontSize={16} color="isabelline">
                  Compete with other Ambassadors for special perks!
                </Typography>
              </Box>
              <Typography mt={1} mb={1} fontSize={26} color="isabelline">
                Pro Tips
              </Typography>
              <Box component="ul" my={0}>
                <Typography component="li" fontSize={16} color="isabelline">
                  Keep it casual and friendly
                </Typography>
                <Typography component="li" fontSize={16} color="isabelline">
                  Emphasize the sustainability angle
                </Typography>
                <Typography component="li" fontSize={16} color="isabelline">
                  Thank them for their time
                </Typography>
                <Typography component="li" fontSize={16} color="isabelline">
                  Add the info immediately to keep it fresh
                </Typography>
              </Box>
              <Typography
                mt={7}
                fontSize={40}
                fontWeight={500}
                color="isabelline"
                lineHeight={1.2}
              >
                Ready to start mapping?
              </Typography>
              <Typography
                mt={2}
                fontSize={20}
                fontWeight={500}
                color="isabelline"
                lineHeight={1.4}
              >
                Let&apos;s make every drop count!
              </Typography>
            </React.Fragment>
          ) : null}
          {tab === 1 ? (
            <React.Fragment>
              <Typography fontSize={32} lineHeight={1.25} color="isabelline">
                Mapping Waste Management Organizations (WMOs)
              </Typography>
              <Typography mt={2} fontSize={26} color="isabelline">
                What are WMOs?
              </Typography>
              <Typography mt={1} color="isabelline">
                Waste Management Organizations handle the collection,
                transportation, processing, and recycling of waste materials,
                including Used Cooking Oil.
              </Typography>
              <Typography mt={2} fontSize={26} color="isabelline">
                Getting Started
              </Typography>
              <Typography mt={1} color="isabelline">
                Most WMO details can be found online or through restaurant
                owners. Here&apos;s how to gather crucial info:
              </Typography>
              <Typography mt={2} fontSize={26} color="isabelline">
                The Perfect Intro
              </Typography>
              <Typography
                mt={1}
                mb={1}
                color="beauBlue"
                fontSize={16}
                fontStyle="italic"
                lineHeight={1.75}
              >
                &quot;Hi! I&apos;m helping map restaurants for sustainable
                cooking oil recycling through an app that rewards environmental
                impact. Would you have 2 minutes to chat about your oil
                recycling practices?&quot;
              </Typography>
              <Typography mt={1} mb={1} fontSize={26} color="isabelline">
                Key Questions
              </Typography>
              <Box component="ul" my={0}>
                <Typography component="li" fontSize={16} color="isabelline">
                  Scout restaurants and identify UCO recycling opportunities
                </Typography>
                <Typography component="li" fontSize={16} color="isabelline">
                  Track your impact saving the world
                </Typography>
                <Typography component="li" fontSize={16} color="isabelline">
                  Add specialized data as you level up
                </Typography>
                <Typography component="li" fontSize={16} color="isabelline">
                  Compete with other Ambassadors for special perks!
                </Typography>
              </Box>
              <Typography mt={1} mb={1} fontSize={26} color="isabelline">
                Pro Tips
              </Typography>
              <Box component="ul" my={0}>
                <Typography component="li" fontSize={16} color="isabelline">
                  Keep it casual and friendly
                </Typography>
                <Typography component="li" fontSize={16} color="isabelline">
                  Emphasize the sustainability angle
                </Typography>
                <Typography component="li" fontSize={16} color="isabelline">
                  Thank them for their time
                </Typography>
                <Typography component="li" fontSize={16} color="isabelline">
                  Add the info immediately to keep it fresh
                </Typography>
              </Box>
              <Typography
                mt={7}
                fontSize={40}
                fontWeight={500}
                color="isabelline"
                lineHeight={1.2}
              >
                This feature unlocks at Level 8
              </Typography>
              <Typography
                mt={2}
                fontSize={20}
                fontWeight={500}
                color="isabelline"
                lineHeight={1.4}
              >
                Keep mapping to access!
              </Typography>
            </React.Fragment>
          ) : null}
          <Box mt={6} mb={9}>
            <Button
              variant="contained"
              color="icterine"
              fullWidth
              component={NavLink}
              to="/m2e/places"
            >
              Start mapping
            </Button>
          </Box>
        </Box>
      </Scaffold.Content>
      <Scaffold.Footer></Scaffold.Footer>
    </Scaffold>
  );
};

export const MuiSlider = {
  styleOverrides: {
    root: {
      height: 8,
    },
    rail: ({ theme }) => ({
      backgroundColor: theme.palette.purpleNavy,
      opacity: 0.2,
    }),
    track: ({ theme }) => ({
      backgroundColor: theme.palette.purpleNavy,
      borderColor: theme.palette.purpleNavy,
    }),
    thumb: ({ theme }) => ({
      width: 24,
      height: 24,
      backgroundColor: theme.palette.isabelline,
      borderRadius: 100,
      borderWidth: 4,
      borderStyle: 'solid',
      borderColor: theme.palette.purpleNavy,
    }),
  },
};

/* eslint-disable no-undef */

import Web3 from 'web3';
import { DAppKit } from '@vechain/dapp-kit';
import { enqueueSnackbar } from 'notistack';
import { store } from '../redux/store';
import { clearWallet, setWallet } from '../redux/wallet.slice';

const VechainId = 100009;
const XDCId = process.env.REACT_APP_ENV === 'production' ? 50 : 51;

const getVechainSdk = () => {
  const walletConnectOptions = {
    projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || '',
    metadata: {
      name: process.env.REACT_APP_WALLETCONNECT_NAME || '',
      description: process.env.REACT_APP_WALLETCONNECT_DESCRIPTION || '',
      url: window.location.origin,
      icons: [`${window.location.origin}/logo512.png`],
    },
  };
  return new DAppKit({
    nodeUrl:
      process.env.REACT_APP_WALLETCONNECT_NODE_URL ||
      'https://testnet.vechain.org/',
    genesis: process.env.REACT_APP_WALLETCONNECT_GENESIS,
    walletConnectOptions: walletConnectOptions,
    usePersistence: true,
    useFirstDetectedSource: false,
  });
};

const getMetamaskSdk = () => {
  return new Web3(window.ethereum);
};

export const apiConnectWallet = async (target) => {
  if (target === 'vechain') {
    try {
      const sdk = getVechainSdk();
      sdk.wallet.setSource('veworld');
      const result = await sdk.wallet.connect();
      if (result.account) {
        const data = {
          account: result.account,
          chainId: VechainId,
          chainName: 'vechain',
        };
        store.dispatch(setWallet(data));
        return data;
      }
      throw new Error();
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Failed to connect to VeChain, Please try again later', {
        variant: 'error',
      });
    }
  }
  if (target === 'xdc') {
    if (window.ethereum) {
      try {
        const sdk = getMetamaskSdk();
        const chainId = await sdk.eth.getChainId();
        if (chainId.toString(10) !== XDCId.toString(10)) {
          enqueueSnackbar('Please connect to XDC Network', {
            variant: 'error',
          });
          return;
        }
        const accounts = await sdk.eth.requestAccounts();
        if (accounts.length > 0) {
          const data = {
            account: accounts[0],
            chainName: 'xdc',
            chainId: XDCId,
          };
          store.dispatch(setWallet(data));
          return data;
        } else {
          enqueueSnackbar('No account found in Metamask', {
            variant: 'error',
          });
        }
      } catch {
        enqueueSnackbar(
          'Failed to connect to XDC Network, Please try again later',
          {
            variant: 'error',
          },
        );
      }
    } else {
      console.log(e);
      enqueueSnackbar('Please install Metamask to connect to XDC Network', {
        variant: 'error',
      });
    }
  }
};

export const apiDisconnectWallet = async () => {
  if (store.getState().wallet.chainName === 'vechain') {
    const sdk = getVechainSdk();
    sdk.wallet.disconnect();
  }
  if (store.getState().wallet.chainName === 'xdc') {
    enqueueSnackbar('Please disconnect your account from MetaMask app', {
      variant: 'success',
    });
  }

  store.dispatch(clearWallet());
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Scaffold } from '../layouts/Scaffold';
import { XIcon } from 'lucide-react';
import { router } from '../../pages/router';

import imgOily from '../../assets/onboarding/oily-level-5.png';
import imgBadge from '../../assets/badges/badge-lvl5.png';
import imgBadgeNext from '../../assets/badges/badge-lvl6.png';

export const LevelUp5Dialog = ({ open, onClose }) => {
  const handleContinue = useCallback(() => {
    router.navigate('/m2e/places');
    onClose();
  }, [onClose]);

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Scaffold bgcolor="levels.5.bgcolor" height="100vh">
        <Confetti recycle={false} numberOfPieces={1000} />
        <Scaffold.Header>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={2}
            pt={1}
            pb={2}
            minHeight={56}
          >
            <Box width={26} />
            <Typography fontSize={14} color="isabelline" lineHeight={1}>
              Level Completed
            </Typography>
            <IconButton color="isabelline" size="small" onClick={onClose}>
              <XIcon />
            </IconButton>
          </Stack>
        </Scaffold.Header>
        <Scaffold.Content>
          <Stack direction="row" justifyContent="center">
            <img src={imgOily} alt="Oily" />
          </Stack>
          <Box px={3}>
            <Typography
              mt={3}
              fontSize={32}
              lineHeight={1.25}
              color="isabelline"
            >
              Bravo!
              <br />
              You&apos;ve Reached
            </Typography>
            <Typography fontSize={32} lineHeight={1.25} color="beauBlue">
              Level 5: Map Explorer
            </Typography>
            <Stack direction="row" alignItems="flex-start" spacing={2} mt={2}>
              <img
                src={imgBadge}
                alt="Badge"
                style={{
                  width: 72,
                  height: 72,
                }}
              />
              <Typography
                flex={1}
                fontSize={20}
                lineHeight={1.4}
                color="isabelline"
              >
                You&apos;ve unlocked a new realm of possibilities — your mapping
                journey begins now!
              </Typography>
            </Stack>

            <Typography
              mt={4}
              fontSize={20}
              fontWeight={500}
              lineHeight={1.4}
              color="beauBlue"
            >
              Your Achievements:
            </Typography>
            <Typography
              mt={1}
              fontSize={18}
              fontWeight={700}
              lineHeight={1.75}
              color="isabelline"
            >
              Met Pre-requisites
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="isabelline"
              component="div"
            >
              <ul>
                <li>
                  <b>XP:</b> 5000+ XP earned
                </li>
                <li>
                  <b>Activity:</b> 30+ days on the platform
                </li>
                <li>
                  <b>Commitment:</b> Locked 3000 XP
                </li>
              </ul>
            </Typography>
            <Typography
              mt={1}
              fontSize={18}
              lineHeight={1.75}
              color="isabelline"
            >
              <b>Unlocked</b> (Limited Map and Earn feature)
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="isabelline"
              component="div"
            >
              <ul>
                <li>Up to 10 mappings allowed</li>
                <li>Mapping only (no adding restaurants or WMOs)</li>
                <li>No picture uploads</li>
                <li>Mapping radius limited to within 1km of your location</li>
              </ul>
            </Typography>
            <Typography
              mt={4}
              fontSize={20}
              fontWeight={500}
              lineHeight={1.4}
              color="beauBlue"
            >
              What&apos;s Next on Your Path?
            </Typography>
            <Stack mt={1} direction="row" alignItems="flex-start" spacing={2}>
              <img
                src={imgBadgeNext}
                alt="Oily"
                style={{
                  width: 32,
                  height: 32,
                }}
              />
              <Typography fontSize={16} lineHeight={1.75} color="isabelline">
                <b>Level 6: Verified Mapper</b>
                <br />
                Elevate your mapping skills! Verify 10 restaurant locations to
                unlock full access to Map and Earn, including the ability to add
                restaurants to the map and also receive locked 3000 XP back + XP
                for mapped locations
              </Typography>
            </Stack>
          </Box>
          <Box mt={5} px={4} pb={9}>
            <Button
              variant="contained"
              color="icterine"
              fullWidth
              onClick={handleContinue}
            >
              Continue
            </Button>
          </Box>
        </Scaffold.Content>
      </Scaffold>
    </Dialog>
  );
};

LevelUp5Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

import React, { useCallback, useState } from 'react';
import {
  Box,
  ButtonBase,
  CircularProgress,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { XpGauge } from '../../../components/core/XpGauge';
import { XpIndicator } from '../../../components/core/XpIndicator';
import { LockedLessonModal } from '../../../components/modals/LockedLessonModal';
import { ArrowLeftIcon, CheckIcon, LockIcon, PlayIcon } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  apiGetChapters,
  apiGetDemoChapters,
  apiGetDemoLessons,
  apiGetLessons,
} from '../../../apis/l2e';

export const L2ELessonsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const chapterIndex = params?.chapterIndex
    ? parseInt(params.chapterIndex)
    : null;

  const isAnonymousLogin = useSelector((state) => state.auth.isAnonymousLogin);

  const { data: chapter } = useQuery({
    queryKey: ['l2e', 'chapters', chapterIndex],
    queryFn: async () => {
      const data = isAnonymousLogin
        ? await apiGetDemoChapters()
        : await apiGetChapters();
      if (data && data.length > chapterIndex) {
        return data[chapterIndex];
      } else {
        return null;
      }
    },
  });

  const { data: lessons, isLoading } = useQuery({
    queryKey: ['l2e', 'chapters', chapterIndex, 'lessons'],
    queryFn: async () => {
      if (!chapter || !chapter._id) {
        return [];
      }

      if (isAnonymousLogin) {
        return await apiGetDemoLessons(chapter._id);
      } else {
        return await apiGetLessons(chapter._id);
      }
    },
    enabled: !!(chapter && chapter._id),
  });

  const [modals, setModals] = useState({
    lockedLesson: false,
  });

  const handleBack = useCallback(() => {
    navigate('/l2e/chapters');
  }, [navigate]);

  const handleToggleModal = useCallback((name) => {
    return () => {
      setModals((prev) => ({
        ...prev,
        [name]: !prev[name],
      }));
    };
  }, []);

  const handleSelectLesson = useCallback(
    (lesson) => {
      if (!lessons) {
        return;
      }

      if (lessons[lesson].locked) {
        handleToggleModal('lockedLesson')();
      } else {
        navigate(`/l2e/chapters/${chapterIndex}/lessons/${lesson}`);
      }
    },
    [navigate, chapterIndex, lessons, handleToggleModal],
  );

  return (
    <Box pt={11}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        pt={2}
        pb={2}
        minHeight={56}
        sx={{
          position: 'fixed',
          top: 0,
          left: '50%',
          right: 0,
          maxWidth: 640,
          width: '100%',
          transform: 'translateX(-50%)',
          boxSizing: 'border-box',
          zIndex: 10,
        }}
      >
        <IconButton
          color="isabelline"
          size="small"
          sx={{ mr: 2 }}
          onClick={handleBack}
        >
          <ArrowLeftIcon />
        </IconButton>
        <XpIndicator />
      </Stack>
      <Stack
        sx={{
          px: 4,
          py: 2,
          width: '100%',
          mb: 1,
          boxSizing: 'border-box',
        }}
        direction="row"
        alignItems="center"
      >
        {chapter ? (
          <>
            <Box flex={1} mr={4}>
              <Typography fontSize={14} lineHeight={1.75} color="isabelline">
                Challenge {chapterIndex + 1}
              </Typography>
              <Typography fontSize={32} lineHeight={1.25} color="isabelline">
                {chapter.title}
              </Typography>
              <Stack mt={1} direction="row" alignItems="center" spacing={2}>
                <Typography
                  fontSize={14}
                  lineHeight={1.75}
                  color="isabelline"
                  sx={{
                    opacity: 0.7,
                  }}
                >
                  {chapter.total_lessons} Lessons
                </Typography>
                <XpGauge
                  xp={chapter.user_xp ?? 0}
                  max={chapter.total_xp ?? 0}
                  xpProps={{
                    color: 'isabelline',
                    fontSize: 14,
                    lineHeight: 1,
                    sx: {
                      opacity: 0.6,
                    },
                  }}
                  maxProps={{
                    color: 'isabelline',
                    fontSize: 14,
                    lineHeight: 1,
                    sx: {
                      opacity: 0.6,
                    },
                  }}
                  iconProps={{
                    width: 16,
                    height: 16,
                  }}
                />
              </Stack>
            </Box>
            <Box position="relative">
              <CircularProgress
                variant="determinate"
                value={chapter.progress}
                size={32}
                color="icterine"
                bgcolor="purpleNavy"
              />
              <Stack
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: 32,
                  height: 32,
                }}
                justifyContent="center"
                alignItems="center"
                color={chapter.locked ? 'purpleNavy' : 'icterine'}
              >
                {chapter.progress >= 100 ? (
                  <CheckIcon width={16} height={16} />
                ) : chapter.locked ? (
                  <LockIcon width={16} height={16} />
                ) : null}
              </Stack>
            </Box>
          </>
        ) : (
          <>
            <Box flex={1} mr={4}>
              <Skeleton
                variant="rounded"
                height={20}
                width={74}
                sx={{ mb: 0.5 }}
              />
              <Skeleton
                variant="rounded"
                height={36}
                width="100%"
                sx={{ mb: 0.5 }}
              />
              <Skeleton
                variant="rounded"
                height={36}
                width="30%"
                sx={{ mb: 0.5 }}
              />
              <Stack mt={1} direction="row" alignItems="center" spacing={2}>
                <Skeleton variant="rounded" height={24} width={62} />
                <Skeleton variant="rounded" height={24} width={72} />
              </Stack>
            </Box>
            <Box position="relative" width={32} height={32}>
              <Skeleton variant="circular" height={32} width={32} />
            </Box>
          </>
        )}
      </Stack>
      <Box mt={6}>
        {isLoading || !lessons
          ? Array(10)
              .fill(true)
              .map((item, index) => (
                <Stack
                  key={`loading-lesson-${index}`}
                  direction="row"
                  alignItems="center"
                  sx={{
                    pl: 2,
                    pr: 4,
                    py: 2,
                    width: '100%',
                    mb: 4,
                  }}
                  boxSizing="border-box"
                >
                  <Box position="relative">
                    <Skeleton variant="circular" width={72} height={72} />
                  </Box>
                  <Box ml={4} flex={1}>
                    <Skeleton variant="rounded" width={120} height={14} />
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height={16}
                      sx={{
                        mt: 1,
                      }}
                    />
                    <Skeleton
                      variant="rounded"
                      width="30%"
                      height={16}
                      sx={{
                        mt: 0.5,
                      }}
                    />
                    <Skeleton
                      variant="rounded"
                      width="20%"
                      height={16}
                      sx={{
                        mt: 1,
                      }}
                    />
                  </Box>
                </Stack>
              ))
          : lessons.map((item, index) => (
              <ButtonBase
                key={`lesson-${index}`}
                sx={{
                  pl: 2,
                  pr: 4,
                  py: 2,
                  textAlign: 'left',
                  width: '100%',
                  mb: 4,
                }}
                onClick={() => handleSelectLesson(index)}
              >
                <Box position="relative">
                  <Box
                    bgcolor="isabelline"
                    width={80}
                    height={80}
                    sx={{
                      opacity: 0.05,
                      position: 'absolute',
                      left: -4,
                      top: -4,
                      borderRadius: '100%',
                    }}
                  />
                  <CircularProgress
                    variant="determinate"
                    value={item.progress}
                    size={72}
                    color="icterine"
                    bgcolor="royalBlueDark"
                    thickness={1}
                  />
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    color={item.locked ? 'purpleNavy' : 'icterine'}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: 72,
                      height: 72,
                      ...(item.locked === false && {
                        '& svg': {
                          filter: `drop-shadow(0 0 4px ${theme.palette.icterine})`,
                        },
                      }),
                    }}
                  >
                    {item.locked ? <LockIcon /> : <PlayIcon />}
                  </Stack>
                </Box>
                <Box ml={4} flex={1}>
                  <Typography
                    fontSize={14}
                    lineHeight={1.75}
                    color={item.locked ? 'shadowBlue' : 'icterine'}
                  >
                    Lesson {index + 1} -{' '}
                    {item.locked
                      ? 'Locked'
                      : item.progress >= 100
                        ? 'Completed'
                        : item.progress > 0
                          ? 'On going'
                          : 'Get started'}
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={700}
                    color={item.locked ? 'shadowBlue' : 'white'}
                    lineHeight={1.75}
                    mb={1}
                  >
                    {item.title}
                  </Typography>
                  <XpGauge
                    xp={item.user_xp ?? 0}
                    max={item.total_xp ?? 0}
                    xpProps={{
                      color: item.locked ? 'shadowBlue' : 'isabelline',
                      fontSize: 14,
                      lineHeight: 1,
                      sx: {
                        opacity: 0.6,
                      },
                    }}
                    maxProps={{
                      color: item.locked ? 'shadowBlue' : 'isabelline',
                      fontSize: 14,
                      lineHeight: 1,
                      sx: {
                        opacity: 0.6,
                      },
                    }}
                    iconProps={{
                      width: 16,
                      height: 16,
                      opacity: item.locked ? 0.6 : 1,
                    }}
                  />
                </Box>
              </ButtonBase>
            ))}
      </Box>
      <Box height={300} />
      <LockedLessonModal
        open={modals['lockedLesson']}
        onClose={handleToggleModal('lockedLesson')}
      />
    </Box>
  );
};

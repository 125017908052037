import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Stack, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import { ReactComponent as SvgEnergy } from '../../assets/symbols/energy.svg';
import imgDrop from '../../assets/symbols/drop.png';
import imgDrop1 from '../../assets/symbols/drop1.png';
import imgDrop2 from '../../assets/symbols/drop2.png';
import imgDrop3 from '../../assets/symbols/drop3.png';
import imgDrop4 from '../../assets/symbols/drop4.png';
import imgDrop5 from '../../assets/symbols/drop5.png';
import imgDrop6 from '../../assets/symbols/drop6.png';
import imgDrop7 from '../../assets/symbols/drop7.png';
import imgDrop8 from '../../assets/symbols/drop8.png';
import imgDrop9 from '../../assets/symbols/drop9.png';
import imgDrop10 from '../../assets/symbols/drop10.png';

export const XpIndicator = ({ onClick }) => {
  const theme = useTheme();
  const {
    level,
    xp: currentXp,
    lockedXp,
  } = useSelector((state) => state.profile);
  const xp = currentXp - lockedXp;

  return (
    <ButtonBase
      sx={{
        direction: 'row',
        alignItems: 'center',
        p: '2px',
        pl: '6px',
        bgcolor: `levels.${level ?? 0}.indicator`,
        borderRadius: 4,
      }}
      onClick={onClick ? onClick : undefined}
    >
      <SvgEnergy width={16} height={16} color="white" />
      <Typography fontSize={12} lineHeight={1.4} mr={1} color="white">
        {xp ? xp.toFixed(0) : 0} XP
      </Typography>
      <Stack
        justifyContent="center"
        alignItems="center"
        width={28}
        height={28}
        borderRadius={100}
        sx={{
          background: theme.palette.levels[level ?? 0].gradient,
        }}
      >
        {(level === 0 || !level) && <img alt="Drop" src={imgDrop} />}
        {level === 1 && <img alt="Drop" src={imgDrop1} />}
        {level === 2 && <img alt="Drop" src={imgDrop2} />}
        {level === 3 && <img alt="Drop" src={imgDrop3} />}
        {level === 4 && <img alt="Drop" src={imgDrop4} />}
        {level === 5 && <img alt="Drop" src={imgDrop5} />}
        {level === 6 && <img alt="Drop" src={imgDrop6} />}
        {level === 7 && <img alt="Drop" src={imgDrop7} />}
        {level === 8 && <img alt="Drop" src={imgDrop8} />}
        {level === 9 && <img alt="Drop" src={imgDrop9} />}
        {level === 10 && <img alt="Drop" src={imgDrop10} />}
      </Stack>
    </ButtonBase>
  );
};

XpIndicator.propTypes = {
  onClick: PropTypes.func,
};

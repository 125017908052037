import React, { useCallback, useState } from 'react';
import Confetti from 'react-confetti';
import * as yup from 'yup';
import { Scaffold } from '../../components/layouts/Scaffold';
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { EnergySymbol } from '../../components/core/EnergySymbol';
import { ArrowLeftIcon, XIcon } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar } from 'notistack';
import { countries } from 'countries-list';
import { apiUpdateProfile } from '../../apis/profile';

import imgOily from '../../assets/onboarding/oily-4.png';

const schema = yup
  .object({
    username: yup
      .string()
      .required(
        'Name is a mandatory field. Please enter an unique username and make it yours.',
      ),
    age_gap: yup
      .string()
      .required(
        'Age is a mandatory field. Please select an option from the list.',
      ),
    gender: yup
      .string()
      .required(
        'Gender is a mandatory field. Please select an option from the list.',
      ),
    country: yup
      .string()
      .required(
        'Country is a mandatory field. Please select an option from the list.',
      ),
    city: yup
      .string()
      .required(
        'City is a mandatory field. Please select an option from the list.',
      ),
  })
  .required();

export const EditIdentityPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const profile = useSelector((state) => state.profile);

  const [loading, setLoading] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const { handleSubmit, control } = useForm({
    defaultValues: {
      username: location.state?.username ?? profile.username ?? '',
      age_gap: location.state?.age_gap ?? profile.age ?? '18_35',
      gender: location.state?.gender ?? profile.gender ?? 'male',
      city: location.state?.city ?? profile.city ?? '',
      country: location.state?.country ?? profile.country ?? '',
    },
    resolver: yupResolver(schema),
  });

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSave = useCallback(async (values) => {
    setLoading(true);
    const result = await apiUpdateProfile({
      ...values,
    });
    if (result !== false) {
      if (result > 0) {
        setConfetti(true);
      } else {
        enqueueSnackbar({
          message: 'Update your profile info successfully',
          variant: 'success',
        });
      }
    }
    setLoading(false);
  }, []);

  const handleToggleModal = useCallback(() => {
    setConfetti((prev) => !prev);
  }, []);

  const handleEditZealyId = useCallback(() => {
    navigate('/profile/zealy-id', {
      replace: true,
    });
  }, [navigate]);

  return (
    <Scaffold height="100vh" bgcolor="isabelline">
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={1}
          pb={2}
          minHeight={56}
        >
          <IconButton color="raisinBlack" size="small" onClick={handleBack}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography fontSize={14} color="raisinBlack" lineHeight={1}>
            Profile
          </Typography>
          <IconButton color="raisinBlack" size="small" onClick={handleBack}>
            <XIcon />
          </IconButton>
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Box px={3} mt={5}>
          <Typography
            fontSize={32}
            lineHeight={1.25}
            color="raisinBlack"
            mb={4}
          >
            Identification
          </Typography>
          <Box mb={4}>
            <Controller
              control={control}
              name="username"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  variant="standard"
                  color="light"
                  label="NAME"
                  placeholder="E.g.: John Smith"
                  required
                  fullWidth
                  {...field}
                  error={!!error?.message}
                  helperText={error?.message}
                />
              )}
            />
          </Box>
          <Box mb={4}>
            <Controller
              control={control}
              name="age_gap"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  variant="standard"
                  color="light"
                  label="AGE"
                  select
                  required
                  fullWidth
                  error={!!error?.message}
                  helperText={error?.message}
                  {...field}
                >
                  <MenuItem value="18_35">18 to 35 years old</MenuItem>
                  <MenuItem value="35_60">35 to 60 years old</MenuItem>
                  <MenuItem value="gt_60">more than 60 years old</MenuItem>
                </TextField>
              )}
            />
          </Box>
          <Box mb={4}>
            <Controller
              control={control}
              name="gender"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  variant="standard"
                  color="light"
                  label="GENDER"
                  select
                  required
                  fullWidth
                  error={!!error?.message}
                  helperText={error?.message}
                  {...field}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </TextField>
              )}
            />
          </Box>
          <Typography
            fontSize={32}
            lineHeight={1.25}
            color="raisinBlack"
            mt={6}
            mb={4}
          >
            Location
          </Typography>
          <Box mb={4}>
            <Controller
              control={control}
              name="country"
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  disablePortal
                  disableClearable
                  value={field.value}
                  options={Object.keys(countries).map((key) => ({
                    value: key,
                    label: countries[key].name,
                  }))}
                  onChange={(_, value) =>
                    field.onChange(
                      typeof value === 'string' ? value : value?.value,
                    )
                  }
                  getOptionLabel={(option) =>
                    typeof option === 'string'
                      ? countries[option]?.name
                      : option?.label
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      color="light"
                      label="COUNTRY"
                      required
                      slotProps={{
                        htmlInput: {
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        },
                      }}
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box mb={4}>
            <Controller
              control={control}
              name="city"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  variant="standard"
                  color="light"
                  placeholder="E.g.: London"
                  label="CITY"
                  required
                  fullWidth
                  error={!!error?.message}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
          </Box>
        </Box>
        <Modal open={confetti} onClose={handleToggleModal}>
          <Box>
            <Confetti
              width={window.innerWidth - 16}
              height={window.innerHeight * 0.7}
            />
            <Stack direction="row" justifyContent="center">
              <img
                src={imgOily}
                alt="Oily"
                style={{
                  width: 120,
                  height: 131,
                }}
              />
            </Stack>
            <Box px={3} mt={1}>
              <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
                Congratulations
              </Typography>
              <Typography fontSize={32} lineHeight={1.25} color="shadowBlue">
                You&apos;ve earned
              </Typography>
              <Stack mt={2} direction="row" justifyContent="flex-end">
                <EnergySymbol
                  width={32}
                  height={32}
                  color={theme.palette.raisinBlack}
                  style={{
                    alignSelf: 'center',
                  }}
                />
                <Typography fontSize={100} lineHeight={1} color="raisinBlack">
                  20
                </Typography>
                <Typography
                  fontSize={32}
                  lineHeight={1.25}
                  color="raisinBlack"
                  mt={1}
                >
                  XP
                </Typography>
              </Stack>
              <Typography
                mt={2}
                fontSize={16}
                lineHeight={1.75}
                color="raisinBlack"
              >
                Add Zealy ID now to earn more XPs!
              </Typography>
            </Box>
            <Stack direction="row" spacing={2} mt={11}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={handleBack}
              >
                Not now
              </Button>
              <Button
                variant="contained"
                color="raisinBlack"
                onClick={handleEditZealyId}
              >
                Add Zealy ID
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Scaffold.Content>
      <Scaffold.Footer>
        <Stack direction="row" spacing={2} p={3}>
          <Button
            variant="outlined"
            color="raisinBlack"
            sx={{
              flex: 1,
            }}
            onClick={handleBack}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="raisinBlack"
            sx={{
              flex: 2,
            }}
            loading={loading}
            onClick={handleSubmit(handleSave)}
          >
            Save
          </LoadingButton>
        </Stack>
      </Scaffold.Footer>
    </Scaffold>
  );
};

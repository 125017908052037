import axios from 'axios';
import { store } from '../redux/store';
import { updateAuth } from '../redux/auth.slice';
import { setProfile, updateProfile } from '../redux/profile.slice';
import { setAppConstants, toggleLevelUpDialog } from '../redux/app.slice';
import { enqueueSnackbar } from 'notistack';

export const apiGetOnboardingProfile = async () => {
  try {
    if (!store.getState().auth.isAnonymousLogin) {
      return null;
    }

    const response = await axios.get('/api/client/v2/user/info');
    const data = response.data.data;

    store.dispatch(
      updateAuth({
        id: data._id,
      }),
    );
    store.dispatch(
      setProfile({
        ucoProductionPeriod: data.uco_production_period,
        useOilToCook: data.useOilToCook,
        recycleUco: data.recycle_uco,
        knownOfDropUco: data.known_of_drop_uco,
      }),
    );

    return true;
  } catch {
    return false;
  }
};

export const apiSetOnboardingProfile = async (data) => {
  try {
    if (!store.getState().auth.isAnonymousLogin) {
      return false;
    }

    await axios.put('/api/client/v2/user/initial-profile', data);
    return true;
  } catch {
    return false;
  }
};

export const apiGetProfile = async (
  { need_balance, need_ratio } = {
    need_balance: false,
    need_ratio: false,
  },
) => {
  try {
    if (store.getState().auth.isAnonymousLogin) {
      return false;
    }

    const response = await axios.post('/api/client/v1/user/profile', {
      need_balance,
      need_ratio,
    });
    store.dispatch(
      updateAuth({
        id: response.data.data._id,
        username: response.data.data.username,
        avatar: response.data.data.profile_image,
      }),
    );
    store.dispatch(
      updateProfile({
        id: response.data.data._id,
        username: response.data.data.username,
        avatar: response.data.data.profile_image,
        address: response.data.data.wallet_address
          ? response.data.data.wallet_address
          : response.data.data.xdc_wallet_address,
        level: response.data.data.level,
        chain: response.data.data.chain,
        scammed: response.data.data.scammed,
        disabled: response.data.data.disabled,
        telegramId: response.data.data.telegram_id,
        zealyId: response.data.data.zealy_id,
        joinedTelegram: response.data.data.joined_telegram_group,
        joinTelegramLink: response.data.data.join_telegram_link,
        followedTwitter: response.data.data.followed_twitter,
        xp: response.data.data.xp,
        mintNftXp: response.data.data.mint_nft_xp,
        totalXp: response.data.data.total_xp,
        reward: response.data.data.reward_amount,
        country: response.data.data.country,
        city: response.data.data.city,
        gender: response.data.data.gender,
        referralCode: response.data.data.referral_code,
        age: response.data.data.age_gap,
        m2eLocked: response.data.data.m2e_locked,
        ucoOilToCook: response.data.data.use_oil_to_cook,
        ucoRecycling: response.data.data.recycle_uco,
        ucoRecyclingAmount: response.data.data.recycle_amount,
        ucoProductionPeriod: response.data.data.uco_production_period,
        ratio: response.data.data.ratio,
        tgeOptIn: response.data.data.tge_opt_in ? true : false,
        isProfileCompleted:
          response.data.data.username &&
          response.data.data.age_gap &&
          response.data.data.country &&
          response.data.data.city &&
          response.data.data.gender
            ? true
            : false,
      }),
    );
    store.dispatch(
      setAppConstants({
        m2eCreatePlaceXp: response.data.data.m2e_create_place_xp ?? 0,
        m2eCreateWmoXp: response.data.data.m2e_create_wmo_xp ?? 0,
      }),
    );
    return response.data.data;
  } catch {
    return false;
  }
};

export const apiUpdateProfile = async ({
  username,
  profile_image,
  country,
  city,
  zealy_id,
  age_gap,
  gender,
  uco_production_period,
  use_oil_to_cook,
  recycle_uco,
  tge_opt_in,
}) => {
  try {
    const response = await axios.put('/api/client/v1/user/profile', {
      username,
      profile_image,
      country,
      city,
      zealy_id,
      age_gap,
      gender,
      uco_production_period,
      use_oil_to_cook,
      recycle_uco,
      tge_opt_in,
    });
    await apiGetProfile();

    return response.data.award_xp ?? 0;
  } catch {
    enqueueSnackbar('Failed to update your profile, please try again later', {
      variant: 'error',
    });
    return false;
  }
};

export const apiGetAccountLevel = async () => {
  try {
    const response = await axios.get('/api/client/v1/user/level');
    store.dispatch(
      updateProfile({
        level: response.data.data.level,
        mintedNftLevel: response.data.data.minted_nft_level ?? 0,
        lockedXp: response.data.data.locked_xp ? 3000 : 0,
      }),
    );
    if (response.data.data.earned_xp) {
      store.dispatch(
        setAppConstants({
          levelUpXp: response.data.data.earned_xp,
        }),
      );
    }
    if (response.data.data.level_up) {
      store.dispatch(
        toggleLevelUpDialog({
          level: response.data.data.level,
        }),
      );
    }
    return response.data.data;
  } catch {
    return {
      level: 0,
      level_up: false,
      earned_xp: 0,
      locked_xp: 0,
      l2e_earned_xp: 0,
      m2e_earned_xp: 0,
      completed_l2e_chapters: 0,
      verified_added_m2e_locations: 0,
      verified_mapped_m2e_locations: 0,
      minted_nft_level: 0,
      count_login: 0,
    };
  }
};

export const apiLevelUp = async () => {
  try {
    const current = store.getState().profile.level;
    const next = current + 1;

    await axios.post('/api/client/v1/user/level');
    await apiGetAccountLevel();
    store.dispatch(
      setAppConstants({
        levelUpXp: 0,
      }),
    );
    store.dispatch(
      toggleLevelUpDialog({
        level: next,
      }),
    );
    return true;
  } catch (e) {
    enqueueSnackbar(
      'Failed to level up, please check your level up requirements and try again',
      {
        variant: 'error',
      },
    );
    return false;
  }
};

export const apiLockXp = async () => {
  try {
    await axios.put('/api/client/v1/user/lock-xp');
    await apiGetAccountLevel();
    await apiGetProfile();
    return true;
  } catch {
    enqueueSnackbar({
      message: 'Failed to lock your XP, please try again later',
      variant: 'error',
    });
    return false;
  }
};

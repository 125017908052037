import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Scaffold } from '../layouts/Scaffold';
import { ArrowLeftIcon } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { toggleFeatureDialog } from '../../redux/app.slice';
import { apiMintNft } from '../../apis/redeem';

import imgOily from '../../assets/onboarding/oily-10.png';

export const MintNftDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const { username, xp, mintNftXp } = useSelector((state) => state.profile);

  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleToggleModal = useCallback(() => {
    setConfirm((prev) => !prev);
  }, []);

  const handleMintNft = useCallback(async () => {
    if (xp > mintNftXp) {
      setLoading(true);
      const result = await apiMintNft();
      setLoading(false);
      if (result) {
        onClose();
        dispatch(
          toggleFeatureDialog({
            name: 'completeNft',
          }),
        );
      } else {
        enqueueSnackbar({
          message: 'Failed to mint NFT, please try again later',
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar({
        message: "You don't have enough XP for minting NFT now",
        variant: 'error',
      });
    }
  }, [onClose, dispatch, xp, mintNftXp]);

  useEffect(() => {
    if (open) {
      setConfirm(false);
      setLoading(false);
    }
  }, [open]);

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Scaffold bgcolor="royalBlueDark" height="100vh">
        <Scaffold.Header>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={2}
            pt={1}
            pb={2}
            minHeight={56}
          >
            <IconButton color="isabelline" size="small" onClick={onClose}>
              <ArrowLeftIcon />
            </IconButton>
            <Typography fontSize={14} color="isabelline" lineHeight={1}>
              Mint NFT
            </Typography>
            <Box width={26} />
          </Stack>
        </Scaffold.Header>
        <Scaffold.Content>
          <Stack py={2} direction="row" justifyContent="center">
            <img
              src={imgOily}
              alt="oily"
              style={{
                width: 157,
                height: 162,
              }}
            />
          </Stack>
          <Box mt={2} px={3}>
            <Typography fontSize={32} lineHeight={1.25} color="isabelline">
              Hey {username},
            </Typography>
            <Typography fontSize={32} lineHeight={1.25} color="beauBlue">
              Would you like to use {mintNftXp} XPs to Level up and Mint your
              NFT?
            </Typography>
          </Box>
          <Box mt={6} px={3}>
            <Button
              variant="contained"
              color="icterine"
              fullWidth
              onClick={handleToggleModal}
            >
              Mint NFT Now
            </Button>
          </Box>
          <Box mt={2} px={3}>
            <Button
              variant="outlined"
              color="icterine"
              fullWidth
              onClick={onClose}
            >
              Keep my XP
            </Button>
          </Box>
        </Scaffold.Content>
      </Scaffold>
      <Modal open={confirm} onClose={handleToggleModal}>
        <Box>
          <Stack direction="row" justifyContent="center">
            <img
              src={imgOily}
              alt="oily"
              style={{
                width: 120,
                height: 124,
              }}
            />
          </Stack>
          <Box mt={1} px={3}>
            <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
              {username ? username : 'Hi'}
            </Typography>
            <Typography fontSize={32} lineHeight={1.25} color="shadowBlue">
              Are you sure you want to proceed?
            </Typography>
            <Typography
              mt={2}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              This action will use {mintNftXp} XPs to mint your NFT. Once
              confirmed, the XP will be deducted, and the NFT will be added to
              your collection. Do you want to continue?
            </Typography>
          </Box>
          <Box mt={11}>
            <LoadingButton
              variant="contained"
              color="raisinBlack"
              loading={loading}
              onClick={handleMintNft}
            >
              Yes, Mint My NFT
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </Dialog>
  );
};

MintNftDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

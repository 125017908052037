import React, { useCallback, useEffect } from 'react';
import Confetti from 'react-confetti';
import { Scaffold } from '../../../components/layouts/Scaffold';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { ArrowLeftIcon } from 'lucide-react';
import { XpIndicator } from '../../../components/core/XpIndicator';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import imgBg from '../../../assets/backgrounds/m2e-bg.png';
import imgOily from '../../../assets/onboarding/oily-4.png';
import imgBubble from '../../../assets/onboarding/bubble-7.png';

export const M2EPlaceContributedPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const data = useSelector((state) => (id ? state.places.places[id] : null));

  const handleClose = useCallback(() => {
    navigate('/m2e/places');
  }, [navigate]);

  useEffect(() => {
    if (!id) {
      navigate('/m2e/places');
      return;
    }
    if (!data) {
      navigate('/m2e/places');
      return;
    }
  }, [data, id, navigate]);

  return (
    <Scaffold
      height="100vh"
      bgcolor="raisinBlack"
      sx={{
        backgroundImage: `url(${imgBg})`,
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={1}
          pb={2}
          minHeight={56}
        >
          <IconButton
            color="isabelline"
            size="small"
            sx={{ mr: 2 }}
            onClick={handleClose}
          >
            <ArrowLeftIcon />
          </IconButton>
          <XpIndicator />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Box pt={22}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <img
              src={imgOily}
              alt="Oily"
              style={{
                width: 151,
                height: 165,
              }}
            />
            <img
              src={imgBubble}
              alt="Speech Bubble"
              style={{
                width: 189,
                height: 162,
                position: 'absolute',
                bottom: 150,
                left: 'calc(50% - 50px)',
              }}
            />
          </Box>
        </Box>
        <Box mt={5} px={3}>
          <Typography
            fontSize={24}
            fontWeight={500}
            lineHeight={1.3}
            color="isabelline"
          >
            Restaurant name:
          </Typography>
          <Typography fontSize={32} lineHeight={1.25} color="beauBlue">
            {data?.name}
          </Typography>
          <Typography mt={3} fontSize={16} lineHeight={1.75} color="isabelline">
            Our team is reviewing your restaurant claim.
            <br />
            Check the &quot;My Contributions&quot; tab to track approval status!
          </Typography>
        </Box>
        <Confetti recycle={false} numberOfPieces={1000} />
      </Scaffold.Content>
      <Scaffold.Footer>
        <Box p={3}>
          <Button variant="contained" color="icterine" fullWidth>
            Go to My Contributions
          </Button>
        </Box>
      </Scaffold.Footer>
    </Scaffold>
  );
};

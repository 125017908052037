import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, ButtonBase, Stack, Typography, useTheme } from '@mui/material';
import { EnergySymbol } from '../../components/core/EnergySymbol';
import { ArrowRightIcon, CircleCheckIcon, XCircleIcon } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiGetMyRestaurants } from '../../apis/m2e';

export const RestaurantItem = ({ item }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { m2eCreatePlaceXp } = useSelector((state) => state.app.constants);

  const handleClick = useCallback(() => {
    navigate(`/m2e/places?lat=${item.latitude}&lng=${item.longitude}`);
  }, [navigate, item]);

  return (
    <ButtonBase
      sx={{
        borderRadius: 4,
        bgcolor:
          item.status === 'pending'
            ? 'yellow.400'
            : item.status === 'approved'
              ? 'green.400'
              : 'error.400',
        display: 'block',
        minWidth: 'calc(100vw - 16px)',
        boxSizing: 'border-box',
        px: 3,
        py: 4,
        textAlign: 'left',
      }}
      onClick={handleClick}
    >
      <Stack
        display="inline-flex"
        direction="row"
        alignItems="center"
        px={1}
        py={0.5}
        mb={2}
        fontSize={14}
        lineHeight={1.75}
        color={
          item.status === 'pending'
            ? 'raisinBlack'
            : item.status === 'approved'
              ? 'green.700'
              : 'gray.700'
        }
        bgcolor={item.status === 'pending' ? 'white' : 'gray.300'}
        spacing={0.5}
        sx={{
          borderRadius: 2,
          borderColor:
            item.status === 'pending'
              ? 'raisinBlack'
              : item.status === 'approved'
                ? 'green.500'
                : 'gray.700',
          borderStyle: 'solid',
          borderWidth: 1,
        }}
      >
        {item.status === 'disapproved' ? (
          <XCircleIcon width={16} height={16} />
        ) : (
          <CircleCheckIcon width={16} height={16} />
        )}
        <span>
          {item.status === 'pending'
            ? 'Under review'
            : item.status === 'approved'
              ? 'Restaurant approved'
              : 'Restaurant disapproved'}
        </span>
      </Stack>
      {item.status === 'approved' ? (
        <>
          <Typography
            fontSize={32}
            fontWeight={500}
            lineHeight={1.4}
            color="raisinBlack"
          >
            Congratulations
          </Typography>
          <Typography
            fontSize={32}
            fontWeight={500}
            lineHeight={1.4}
            color="shadowBlue"
          >
            You&apos;ve earned
          </Typography>
          <Stack direction="row" justifyContent="flex-end" mb={1}>
            <EnergySymbol
              width={32}
              height={32}
              color={theme.palette.raisinBlack}
              style={{
                alignSelf: 'center',
              }}
            />
            <Typography fontSize={100} lineHeight={1} color="raisinBlack">
              {m2eCreatePlaceXp}
            </Typography>
            <Typography
              fontSize={32}
              lineHeight={1.25}
              color="raisinBlack"
              mt={1}
            >
              XP
            </Typography>
          </Stack>
        </>
      ) : null}
      <Typography
        fontSize={20}
        fontWeight={500}
        lineHeight={1.4}
        color="pennBlue"
      >
        {item.status === 'approved' ? 'Restaurant added to map' : ''}
      </Typography>
      <Typography
        fontSize={40}
        fontWeight={500}
        lineHeight={1.2}
        color="pennBlue"
      >
        {item.name}
      </Typography>
      <Typography mt={2} fontSize={16} lineHeight={1.75} color="pennBlue">
        {item.status === 'pending'
          ? 'Your restaurant is under review, which may take up to 72 hours.'
          : item.status === 'approved'
            ? 'Your restaurant has been added to the map and you now have 48 hours to map it and earn more XP.'
            : 'Your restaurant has been added to the map and is under review, which may take up to 72 hours.'}
      </Typography>
      <Stack mt={3} direction="row" alignItems="center" spacing={2}>
        <Typography fontSize={32} lineHeight={1.25} color="royalBlueDark">
          View on map
        </Typography>
        <ArrowRightIcon color={theme.palette.royalBlueDark} />
      </Stack>
    </ButtonBase>
  );
};

export const RestaurantsSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { m2eCreatePlaceXp } = useSelector((state) => state.app.constants);
  const { level } = useSelector((state) => state.profile);

  const { data, isLoading } = useQuery({
    queryKey: ['m2e', 'my-restaurants'],
    queryFn: async () => {
      return await apiGetMyRestaurants();
    },
  });

  const handleAddRestaurant = useCallback(() => {
    navigate('/m2e/add-restaurant');
  }, [navigate]);

  if (level < 6 || isLoading) {
    return null;
  }

  if (data?.length > 0) {
    return (
      <Box mb={1}>
        <Box
          sx={{
            overflowX: 'auto',
          }}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            overflow="visible"
            flexWrap="nowrap"
            spacing={1}
            px={1}
          >
            {data?.map((item, index) => (
              <RestaurantItem key={`place-${index}`} item={item} />
            ))}
          </Stack>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box mx={1} mb={1}>
        <ButtonBase
          sx={{
            borderRadius: 4,
            bgcolor: 'isabelline',
            display: 'block',
            width: '100%',
            boxSizing: 'border-box',
            px: 3,
            py: 4,
            textAlign: 'left',
          }}
          onClick={handleAddRestaurant}
        >
          <Typography fontSize={32} lineHeight={1.25} color="shadowBlue">
            Earn now
          </Typography>
          <Stack direction="row" justifyContent="flex-end">
            <EnergySymbol
              width={32}
              height={32}
              color={theme.palette.raisinBlack}
              style={{
                alignSelf: 'center',
              }}
            />
            <Typography fontSize={100} lineHeight={1} color="raisinBlack">
              {m2eCreatePlaceXp}
            </Typography>
            <Typography
              fontSize={32}
              lineHeight={1.25}
              color="raisinBlack"
              mt={1}
            >
              XP
            </Typography>
          </Stack>
          <Stack mt={3} direction="row" alignItems="center" spacing={2}>
            <Typography fontSize={32} lineHeight={1.25} color="royalBlueDark">
              Add a Restaurant
            </Typography>
            <ArrowRightIcon color={theme.palette.royalBlueDark} />
          </Stack>
          <Typography fontSize={16} lineHeight={1.75} color="pennBlue">
            Help us map the unmapped restaurants
          </Typography>
        </ButtonBase>
      </Box>
    );
  }
};

RestaurantItem.propTypes = {
  item: PropTypes.any,
};

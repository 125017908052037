import React, { useCallback } from 'react';
import { Box, ButtonBase, Stack, Typography } from '@mui/material';
import { ArrowRightIcon } from 'lucide-react';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFeatureDialog } from '../../redux/app.slice';

export const NftSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { level, mintedNftLevel } = useSelector((state) => state.profile);

  const handleClick = useCallback(() => {
    dispatch(
      toggleFeatureDialog({
        name: 'mintNft',
      }),
    );
  }, [dispatch]);

  if (
    level >= 2 &&
    level <= 9 &&
    (mintedNftLevel === 0 || mintedNftLevel !== level)
  ) {
    return (
      <Box px={1} mb={1}>
        <ButtonBase
          sx={{
            borderRadius: 4,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'yellow.500',
            display: 'block',
            width: '100%',
            boxSizing: 'border-box',
            px: 3,
            py: 4,
            textAlign: 'left',
          }}
          onClick={handleClick}
        >
          <Typography
            fontSize={24}
            fontWeight={500}
            lineHeight={1.3}
            color="yellow.400"
          >
            Mint Your Ambassador NFT
          </Typography>
          <Typography
            fontSize={40}
            fontWeight={500}
            lineHeight={1.3}
            color="yellow.400"
          >
            Mint Using XPs
          </Typography>
          <Typography mt={2} fontSize={28} lineHeight={1.25} color="yellow.500">
            Secure Access
            <br />
            to Level {level + 1}
          </Typography>
          <Stack mt={3} direction="row" alignItems="center" spacing={2}>
            <Typography fontSize={32} lineHeight={1.25} color="isabelline">
              Start now
            </Typography>
            <ArrowRightIcon color={theme.palette.isabelline} />
          </Stack>
        </ButtonBase>
      </Box>
    );
  } else {
    return null;
  }
};

import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import imgBg from '../../assets/backgrounds/onboarding-bg.png';

export const OnboardingLayout = () => {
  return (
    <Box
      width="100%"
      height="100vh"
      bgcolor="purpleNavy"
      sx={{
        overflowX: 'hidden',
        overflowY: 'auto',
        backgroundImage: `url(${imgBg})`,
        backgroundPosition: 'center',
        backgroundSize: '100% 100%',
      }}
    >
      <Outlet />
    </Box>
  );
};

/* eslint-disable no-undef */

import axios from 'axios';
import { store } from '../redux/store';
import { setAppConstants } from '../redux/app.slice';
import { apiGetProfile } from './profile';

export const apiGetL2ESummary = async () => {
  try {
    const response = await axios.get('/api/client/v1/l2e/info');
    store.dispatch(
      setAppConstants({
        l2eTotalXp: response.data.total_xp ?? 0,
        l2eTotalChapters: response.data.total_chapters ?? 0,
      }),
    );

    return {
      totalXp: response.data.total_xp ?? 0,
      totalChapters: response.data.total_chapters ?? 0,
    };
  } catch {
    return {
      totalXp: 0,
      totalChapters: 0,
    };
  }
};

export const apiGetL2EAdvancedSummary = async () => {
  try {
    const response = await axios.get('/api/client/v1/l2e/chapters');
    const chapters = response.data.data;

    let sum = 0;
    for (const chapter of chapters) {
      sum += chapter.user_xp;
    }
    return {
      earnedXp: sum,
      started: sum > 0,
    };
  } catch {
    return {
      earnedXp: 0,
      started: false,
    };
  }
};

export const apiGetChapters = async () => {
  try {
    const response = await axios.get('/api/client/v1/l2e/chapters');
    return response.data.data.sort((a, b) => (a.order > b.order ? 1 : -1));
  } catch (e) {
    return [];
  }
};

export const apiGetLessons = async (chapterId) => {
  try {
    const response = await axios.get(`/api/client/v1/l2e/lessons/${chapterId}`);
    return response.data.data.sort((a, b) => (a.order > b.order ? 1 : -1));
  } catch (e) {
    return [];
  }
};

export const apiGetContents = async (lessonId) => {
  try {
    const response = await axios.get(`/api/client/v1/l2e/pages/${lessonId}`);
    return response.data.data;
  } catch (e) {
    return [];
  }
};

export const apiGetQuestions = async (lessonId) => {
  try {
    const response = await axios.get(
      `/api/client/v1/l2e/questions/${lessonId}`,
    );
    return response.data.data.sort((a, b) => (a._id > b._id ? 1 : -1));
  } catch (e) {
    return [];
  }
};

export const apiAnswerQuestion = async (data) => {
  return new Promise((resolve) => {
    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(
          process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
          { action: 'answer' },
        );
        const response = await axios.post(`/api/client/v1/l2e/answer`, {
          ...data,
          recaptcha_token: token,
        });

        await apiGetProfile({
          need_balance: false,
          need_ratio: false,
        });
        return resolve(response.data.data?.correct ? true : false);
      } catch (e) {
        if (e?.response?.data?.error === 'RECAPCHA_ERROR') {
          return resolve('recaptcha');
        }
        return resolve(false);
      }
    });
  });
};

export const apiGetDemoChapters = async () => {
  try {
    const response = await axios.get('/api/client/v2/l2e/initial-chapters');
    return response.data.data;
  } catch {
    return [];
  }
};

export const apiGetDemoLessons = async (chapterId) => {
  try {
    const response = await axios.get(
      '/api/client/v2/l2e/initial-lessons/' + chapterId,
    );
    return response.data.data;
  } catch {
    return [];
  }
};

export const apiGetDemoContents = async (lessonId) => {
  try {
    const response = await axios.get(
      '/api/client/v2/l2e/initial-pages/' + lessonId,
    );
    return response.data.data;
  } catch {
    return [];
  }
};

export const apiGetDemoQuestions = async (lessonId) => {
  try {
    const response = await axios.get(
      '/api/client/v2/l2e/initial-questions/' + lessonId,
    );
    return response.data.data;
  } catch {
    return [];
  }
};

export const apiAnswerDemoQuestion = async (data) => {
  try {
    const response = await axios.post(
      '/api/client/v2/l2e/initial-answer',
      data,
    );
    if (response.data?.data?.correct) {
      return response.data.data.earned_xp;
    } else {
      return 0;
    }
  } catch {
    return 0;
  }
};

export const MuiButton = {
  styleOverrides: {
    root: {
      textTransform: 'none',
    },
    contained: {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
    },
    sizeSmall: {
      padding: '4px 12px',
      borderRadius: 20,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '24px',
    },
    sizeMedium: {
      padding: '12px 24px',
      borderRadius: 28,
      fontSize: 18,
      fontWeight: 400,
    },
  },
  variants: [
    {
      props: {
        variant: 'contained',
        color: 'icterine',
      },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.icterine,
        color: theme.palette.raisinBlack,

        '&.Mui-disabled': {
          backgroundColor: `${theme.palette.icterine}80`,
        },
        '& .MuiLoadingButton-loadingIndicator': {
          color: `${theme.palette.raisinBlack}`,
        },
      }),
    },
    {
      props: {
        variant: 'contained',
        color: 'raisinBlack',
      },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.raisinBlack,
        color: theme.palette.isabelline,

        '&.Mui-disabled': {
          backgroundColor: `${theme.palette.raisinBlack}80`,
        },
        '& .MuiLoadingButton-loadingIndicator': {
          color: `${theme.palette.isabelline}`,
        },
      }),
    },
    {
      props: {
        variant: 'contained',
        color: 'charcoal',
      },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.charcoal,
        color: theme.palette.isabelline,

        '&.Mui-disabled': {
          backgroundColor: `${theme.palette.charcoal}80`,
        },
        '& .MuiLoadingButton-loadingIndicator': {
          color: `${theme.palette.isabelline}`,
        },
      }),
    },
    {
      props: {
        variant: 'contained',
        color: 'isabelline',
      },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.isabelline,
        color: theme.palette.raisinBlack,

        '&.Mui-disabled': {
          backgroundColor: `${theme.palette.isabelline}80`,
        },
        '& .MuiLoadingButton-loadingIndicator': {
          color: `${theme.palette.raisinBlack}`,
        },
      }),
    },
    {
      props: {
        variant: 'contained',
        color: 'beauBlue',
      },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.beauBlue,
        color: theme.palette.raisinBlack,

        '&.Mui-disabled': {
          backgroundColor: `${theme.palette.beauBlue}80`,
        },
        '& .MuiLoadingButton-loadingIndicator': {
          color: `${theme.palette.raisinBlack}`,
        },
      }),
    },
    {
      props: {
        variant: 'contained',
        color: 'danger',
      },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.greyishRed,
        color: theme.palette.raisinBlack,

        '&.Mui-disabled': {
          backgroundColor: `${theme.palette.greyishRed}80`,
        },
        '& .MuiLoadingButton-loadingIndicator': {
          color: `${theme.palette.raisinBlack}`,
        },
      }),
    },
    {
      props: {
        variant: 'outlined',
        color: 'icterine',
      },
      style: ({ theme }) => ({
        border: `1px solid ${theme.palette.icterine}`,
        color: theme.palette.icterine,

        '&.Mui-disabled': {
          border: `1px solid ${theme.palette.icterine}80`,
        },
        '& .MuiLoadingButton-loadingIndicator': {
          color: `${theme.palette.icterine}80`,
        },
      }),
    },
    {
      props: {
        variant: 'outlined',
        color: 'raisinBlack',
      },
      style: ({ theme }) => ({
        border: `1px solid ${theme.palette.raisinBlack}`,
        color: theme.palette.raisinBlack,

        '&.Mui-disabled': {
          border: `1px solid ${theme.palette.raisinBlack}80`,
        },
        '& .MuiLoadingButton-loadingIndicator': {
          color: `${theme.palette.raisinBlack}80`,
        },
      }),
    },
    {
      props: {
        variant: 'outlined',
        color: 'isabelline',
      },
      style: ({ theme }) => ({
        border: `1px solid ${theme.palette.isabelline}`,
        color: theme.palette.isabelline,

        '&.Mui-disabled': {
          border: `1px solid ${theme.palette.isabelline}80`,
        },
        '& .MuiLoadingButton-loadingIndicator': {
          color: `${theme.palette.isabelline}80`,
        },
      }),
    },
  ],
};

export const MuiButtonBase = {
  styleOverrides: {
    root: {
      fontFamily: 'inherit',
    },
  },
};

import React, { useCallback } from 'react';
import { Box, IconButton, Link, Stack, Typography } from '@mui/material';
import { ArrowLeftIcon, GlobeIcon } from 'lucide-react';
import { Scaffold } from '../../components/layouts/Scaffold';
import { useNavigate } from 'react-router-dom';

import imgGuide from '../../assets/guides/uco-guide.png';
import { ReactComponent as LinkedinIcon } from '../../assets/socials/linkedin.svg';
import { ReactComponent as TwitterIcon } from '../../assets/socials/twitter.svg';

export const AboutUCOPage = () => {
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Scaffold bgcolor="raisinBlack" height="100vh">
      <Scaffold.Header>
        <Stack
          px={3}
          pb={1}
          pt={3}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton size="small" color="isabelline" onClick={handleBack}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography
            color="isabelline"
            fontSize={14}
            lineHeight={1}
            flex={1}
            textAlign="center"
          >
            About UCO Network
          </Typography>
          <Box width={26} />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content mt={1} px={1} pt={2}>
        <img
          src={imgGuide}
          alt="branding"
          style={{
            width: '100%',
          }}
        />
        <Box mt={3} px={3}>
          <Typography fontSize={32} lineHeight={1.25} color="isabelline">
            What is UCO Network?
          </Typography>
          <Typography mt={2} color="isabelline" fontSize={16} lineHeight={1.75}>
            UCO Network is a blockchain-based ecosystem dedicated to improving
            the collection and recycling of used cooking oil (UCO) for biofuel
            production, ensuring regulatory compliance and operational
            efficiency while boosting value and participation in the circular
            economy. Our solution unites individuals, businesses,
            municipalities, and recycling programs into one global collection
            process. Parties can sell, buy and transport UCO with confidence.
            Sales are verified, shipments are tracked and all participants earn
            recycling credits and incentives for their efforts—thereby aligning
            economic and environmental motivations. Find more about the UCO
            Network&apos;s ecosystem & team on our whitepaper:
          </Typography>
          <Typography mt={2} fontSize={26} color="isabelline">
            Ecosystem Overview
          </Typography>
          <Typography mt={1} fontSize={16} color="isabelline" lineHeight={1.75}>
            <b>Collection App:</b> A complete management platform and a suite of
            specialized tools designed to streamline and enhance logistical and
            governance processes involved in the collection, transportation, and
            recycling of used cooking oil in a compliant, efficient, and
            eco-friendly manner.
            <br />
            <br />
            <b>Oily, Community dApp:</b> a Learn, Map and Recycle-to-Earn
            multichain dApp! Oily rewards users for learning about UCO and for
            helping to map the UCO industry, specially restaurants not yet
            recycling their UCO.
          </Typography>
          <Typography mt={2} fontSize={26} color="isabelline">
            Coming Soon
          </Typography>
          <Typography mt={1} fontSize={16} color="isabelline" lineHeight={1.75}>
            <b>IoT Sensors:</b> Using a DePin infrastructure and LoRaWAN for
            communication, enabling data transmission over long distances with
            minimal energy use and maximum data tamper-proof resilience.
            <br />
            <br />
            <b>Trading Hub:</b> Every UCO listing has a unique digital
            certificate, ensuring the authenticity of the UCO&apos;s origin,
            collection method, and quality. This provides buyers with confidence
            in the reliability of their purchases.
            <br />
            <br />
            <b>Carbon Tokenization Tools:</b> Using the power of cutting-edge
            technology and insightful data analytics, the UCO Network introduces
            a suite of Carbon Measurement Tools designed to measure, report,
            verify and revolutionize access to voluntary carbon and recycling
            markets.
          </Typography>
          <Typography fontSize={22} color="isabelline" mt={6}>
            Official Links
          </Typography>
          <Stack direction="column" mt={2} mb={8} spacing={2}>
            <Stack
              direction="row"
              alignItems="center"
              color="beauBlue"
              component={Link}
              href="https://www.google.com"
              sx={{
                textDecoration: 'none',
              }}
            >
              <GlobeIcon width={16} height={16} />
              <Typography fontSize={15} lineHeight={1} ml={1}>
                uco.network
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              color="beauBlue"
              component={Link}
              href="https://www.google.com"
              sx={{
                textDecoration: 'none',
              }}
            >
              <GlobeIcon width={16} height={16} />
              <Typography fontSize={15} lineHeight={1} ml={1}>
                Link Hub
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              color="beauBlue"
              component={Link}
              href="https://www.google.com"
              sx={{
                textDecoration: 'none',
              }}
            >
              <GlobeIcon width={16} height={16} />
              <Typography fontSize={15} lineHeight={1} ml={1}>
                Whitepaper
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              color="beauBlue"
              component={Link}
              href="https://www.google.com"
              sx={{
                textDecoration: 'none',
              }}
            >
              <TwitterIcon width={16} height={16} />
              <Typography fontSize={15} lineHeight={1} ml={1}>
                Twitter
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              color="beauBlue"
              component={Link}
              href="https://www.google.com"
              sx={{
                textDecoration: 'none',
              }}
            >
              <LinkedinIcon width={16} height={16} />
              <Typography fontSize={15} lineHeight={1} ml={1}>
                Linkedin
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Scaffold.Content>
      <Scaffold.Footer></Scaffold.Footer>
    </Scaffold>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { Scaffold } from '../../../components/layouts/Scaffold';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { ArrowLeftIcon, InfoIcon, XIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { apiAddWmo } from '../../../apis/m2e';

const scheme = yup.object({
  type: yup.string().required('Please select a type'),
  collector_name: yup.string().required('Please fill collector name'),
  collector_email: yup
    .string()
    .email('Please fill valid email address')
    .required('Please fill collector email address'),
  collector_phone_number: yup
    .string()
    .required('Please fill collector phone number'),
  count_vehicles: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue === '' || Number.isNaN(Number(originalValue))
        ? 0
        : value;
    })
    .min(1, 'Please fill count of vehicles')
    .required('Please fill count of vehicles'),
});

export const M2EAddWmoPage = () => {
  const navigate = useNavigate();

  const { level } = useSelector((state) => state.profile);

  const [modals, setModals] = useState({
    success: false,
    limit: false,
    info: false,
  });
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      type: 'company',
      collector_name: '',
      collector_email: '',
      collector_phone_number: '',
      count_vehicles: 0,
    },
    resolver: yupResolver(scheme),
  });

  const handleClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const toggleModal = useCallback((name) => {
    setModals((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  }, []);

  const handleSave = useCallback(
    async (values) => {
      setLoading(true);
      const result = await apiAddWmo(values);
      setLoading(false);

      if (result === true) {
        toggleModal('success');
      } else if (result === 'DAILY_LIMIT_REACHED') {
        toggleModal('limit');
      } else {
        // todo
      }
    },
    [toggleModal],
  );

  const handleCheckContributions = useCallback(() => {
    navigate('/progress?tab=contributions');
  }, [navigate]);

  useEffect(() => {
    if (level < 7) {
      navigate('/challenges');
    }
  }, [level, navigate]);

  return (
    <Scaffold height="100vh" bgcolor="raisinBlack">
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={1}
          pb={2}
          minHeight={56}
        >
          <IconButton color="isabelline" size="small" onClick={handleClose}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography fontSize={14} lineHeight={1} color="isabelline">
            Add a WMO
          </Typography>
          <IconButton color="isabelline" size="small" onClick={handleClose}>
            <XIcon />
          </IconButton>
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Box px={3} pt={3}>
          <Stack direction="row" alignItems="flex-start" spacing={2}>
            <Typography fontSize={32} lineHeight={1.25} color="isabelline">
              Waste Management Organization (WMO)
            </Typography>
            <Box pt={1}>
              <IconButton
                size="small"
                color="isabelline"
                onClick={() => toggleModal('info')}
              >
                <InfoIcon />
              </IconButton>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2} mt={4}>
            <Typography
              fontSize={18}
              lineHeight={1.75}
              color="isabelline"
              flex={1}
            >
              Is it a company?
            </Typography>
            <Box pt={0.5}>
              <Controller
                control={control}
                name="type"
                render={({ field: { value, onChange } }) => (
                  <Switch
                    checked={value === 'company'}
                    onChange={(ev, checked) => {
                      if (checked) {
                        onChange('company');
                      }
                    }}
                  />
                )}
              />
            </Box>
          </Stack>
          <Stack direction="row" spacing={2} mt={2}>
            <Typography
              fontSize={18}
              lineHeight={1.75}
              color="isabelline"
              flex={1}
            >
              Is it an individual?
            </Typography>
            <Box pt={0.5}>
              <Controller
                control={control}
                name="type"
                render={({ field: { value, onChange } }) => (
                  <Switch
                    checked={value === 'individual'}
                    onChange={(ev, checked) => {
                      if (checked) {
                        onChange('individual');
                      }
                    }}
                  />
                )}
              />
            </Box>
          </Stack>
          <Box mt={4}>
            <Controller
              control={control}
              name="collector_name"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  variant="standard"
                  color="dark"
                  label="COLLECTOR'S NAME"
                  placeholder="E.g.: John Smith"
                  required
                  fullWidth
                  {...field}
                  error={!!error?.message}
                  helperText={error?.message}
                />
              )}
            />
          </Box>
          <Box mt={4}>
            <Controller
              control={control}
              name="collector_email"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  variant="standard"
                  color="dark"
                  label="COLLECTOR'S EMAIL ADDRESS"
                  placeholder="E.g.: email@domain.com"
                  required
                  fullWidth
                  {...field}
                  error={!!error?.message}
                  helperText={error?.message}
                />
              )}
            />
          </Box>
          <Box mt={4}>
            <Controller
              control={control}
              name="collector_phone_number"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  variant="standard"
                  color="dark"
                  label="COLLECTOR'S PHONE NUMBER"
                  placeholder="E.g.: +351 555 258 856"
                  required
                  fullWidth
                  {...field}
                  error={!!error?.message}
                  helperText={error?.message}
                />
              )}
            />
          </Box>
          <Box mt={4}>
            <Controller
              control={control}
              name="count_vehicles"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  variant="standard"
                  color="dark"
                  label="HOW MANY VEHICLES IN THE FLEET?"
                  placeholder="E.g.: 5"
                  required
                  fullWidth
                  {...field}
                  error={!!error?.message}
                  helperText={error?.message}
                />
              )}
            />
          </Box>
        </Box>
        <Modal open={modals.info} onClose={() => toggleModal('info')}>
          <Box>
            <Typography fontSize={32} color="raisinBlack" lineHeight={1.25}>
              What is a Waste Management Organization?
            </Typography>
            <Typography
              mt={3}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              Waste Management Organizations (WMO), are individuals or entities
              responsible for the systematic collection, transportation,
              processing, recycling, or disposal of waste materials.
            </Typography>
            <Stack mt={11} direction="row" spacing={1}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={() => toggleModal('info')}
              >
                Close
              </Button>
            </Stack>
          </Box>
        </Modal>
        <Modal open={modals.success} onClose={() => toggleModal('success')}>
          <Box>
            <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
              WMO added to the map!
            </Typography>
            <Typography
              mt={3}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              The data will remain inactive on the map until our team manually
              checks/validates the data sent, which can take up to
              <b> 72 hours</b>.
              <br />
              <br />
              After validating and approving the data sent, you will have
              <b> 24 hours</b> to map the restaurant. After the deadline
              expires, the restaurant will be released to the public for
              mapping.
            </Typography>
            <Box mt={11}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={handleCheckContributions}
              >
                Ok, I understand
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal open={modals.limit} onClose={() => toggleModal('limit')}>
          <Box>
            <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
              Daily WMO Limit Reached!
            </Typography>
            <Typography
              mt={3}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              You have reached the daily limit of <b>10 WMO</b> additions.
              Please try again tomorrow to add more WMOs.
              <br />
              <br />
              Thank you for your understanding!
            </Typography>
            <Box mt={11}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={handleClose}
              >
                Ok, I understand
              </Button>
            </Box>
          </Box>
        </Modal>
      </Scaffold.Content>
      <Scaffold.Footer>
        <Stack direction="row" spacing={2} p={3}>
          <Button
            variant="outlined"
            color="isabelline"
            sx={{
              flex: 1,
            }}
            onClick={handleClose}
          >
            Discard
          </Button>
          <LoadingButton
            variant="contained"
            color="isabelline"
            loading={loading}
            sx={{
              flex: 2,
            }}
            onClick={handleSubmit(handleSave)}
          >
            Save
          </LoadingButton>
        </Stack>
      </Scaffold.Footer>
    </Scaffold>
  );
};

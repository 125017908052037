import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ButtonBase,
  CircularProgress,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { XpGauge } from '../../../components/core/XpGauge';
import { XpIndicator } from '../../../components/core/XpIndicator';
import { LockedChapterModal } from '../../../components/modals/LockedChapterModal';
import {
  LockedAccountLvl1InfoModal,
  LockedAccountLvl2InfoModal,
  LockedAccountLvl3InfoModal,
  LockedAccountLvl4InfoModal,
} from '../../../components/modals/AccountModals';
import { ArrowLeftIcon, CheckIcon, InfoIcon, LockIcon } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  apiGetChapters,
  apiGetDemoChapters,
  apiGetL2EAdvancedSummary,
} from '../../../apis/l2e';

const UnlockInfoSection = ({ description, onClick }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      px={4}
      mb={2}
      spacing={2}
    >
      <Typography fontSize={14} lineHeight={1.75} color="isabelline">
        <b>Unlock:</b> {description}
      </Typography>
      <IconButton size="small" color="isabelline" onClick={onClick}>
        <InfoIcon />
      </IconButton>
    </Stack>
  );
};

export const L2EChaptersPage = () => {
  const navigate = useNavigate();

  const { isAnonymousLogin } = useSelector((state) => state.auth);
  const { l2eTotalXp, l2eTotalChapters } = useSelector(
    (state) => state.app.constants,
  );

  const { data, isLoading } = useQuery({
    queryKey: ['l2e', 'chapters'],
    queryFn: async () => {
      if (isAnonymousLogin) {
        return await apiGetDemoChapters();
      } else {
        return await apiGetChapters();
      }
    },
  });

  const { data: l2eAdvancedSummary } = useQuery({
    queryKey: ['l2e', 'advanced-summary'],
    queryFn: async () => {
      return await apiGetL2EAdvancedSummary();
    },
  });

  const [modals, setModals] = useState({
    lockedChapter: false,
    lockedLevel1: false,
    lockedLevel2: false,
    lockedLevel3: false,
    lockedLevel4: false,
  });

  const handleBack = useCallback(() => {
    navigate('/challenges');
  }, [navigate]);

  const handleToggleModal = useCallback((name) => {
    return () => {
      setModals((prev) => ({
        ...prev,
        [name]: !prev[name],
      }));
    };
  }, []);

  const handleSelectChapter = useCallback(
    (chapter) => {
      if (!data || !data[chapter]) {
        return;
      }

      if (data[chapter].locked) {
        console.log('locked');
        handleToggleModal('lockedChapter')();
      } else {
        navigate(`/l2e/chapters/${chapter}`, {
          replace: true,
        });
      }
    },
    [navigate, data, handleToggleModal],
  );

  return (
    <Box pt={10}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        pt={2}
        pb={2}
        minHeight={56}
        sx={{
          position: 'fixed',
          top: 0,
          left: '50%',
          right: 0,
          maxWidth: 640,
          width: '100%',
          transform: 'translateX(-50%)',
          boxSizing: 'border-box',
          zIndex: 10,
        }}
      >
        <IconButton
          color="isabelline"
          size="small"
          sx={{ mr: 2 }}
          onClick={handleBack}
        >
          <ArrowLeftIcon />
        </IconButton>
        <XpIndicator />
      </Stack>
      <Box px={4} pt={3}>
        <Typography
          fontSize={48}
          fontWeight={500}
          lineHeight={1.2}
          color="icterine"
        >
          Learn
        </Typography>
        <Typography
          fontSize={48}
          fontWeight={500}
          lineHeight={1.2}
          color="isabelline"
        >
          and Earn
        </Typography>
      </Box>
      <Stack mt={4} px={4} direction="row" spacing={2} alignItems="center">
        <Typography fontSize={16} lineHeight={1.75} color="isabelline">
          {l2eTotalChapters} Challenges
        </Typography>
        <XpGauge
          xp={l2eAdvancedSummary?.earnedXp ?? 0}
          max={l2eTotalXp}
          xpProps={{
            fontSize: 16,
            color: 'isabelline',
          }}
          maxProps={{
            fontSize: 16,
            color: 'isabelline',
          }}
          iconProps={{
            width: 16,
            height: 16,
          }}
        />
      </Stack>
      {!l2eAdvancedSummary?.started ? (
        <Box px={4} mt={4}>
          <Typography
            fontSize={14}
            color="isabelline"
            sx={{
              opacity: 0.6,
            }}
          >
            Not started yet
          </Typography>
        </Box>
      ) : null}
      <Box mt={7}>
        {isLoading
          ? Array(10)
              .fill(true)
              .map((item, index) => (
                <Stack
                  key={`loading-chapter-${index}`}
                  sx={{
                    px: 4,
                    py: 2,
                    width: '100%',
                    mb: 1,
                    boxSizing: 'border-box',
                  }}
                  direction="row"
                  alignItems="center"
                >
                  <Box flex={1} mr={4}>
                    <Skeleton
                      variant="rounded"
                      height={20}
                      width={74}
                      sx={{ mb: 0.5 }}
                    />
                    <Skeleton
                      variant="rounded"
                      height={36}
                      width="100%"
                      sx={{ mb: 0.5 }}
                    />
                    <Skeleton
                      variant="rounded"
                      height={36}
                      width="30%"
                      sx={{ mb: 0.5 }}
                    />
                    <Stack
                      mt={1}
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Skeleton variant="rounded" height={24} width={62} />
                      <Skeleton variant="rounded" height={24} width={72} />
                    </Stack>
                  </Box>
                  <Box position="relative" width={32} height={32}>
                    <Skeleton variant="circular" height={32} width={32} />
                  </Box>
                </Stack>
              ))
          : data.map((chapter, index) => (
              <React.Fragment key={`chapter-${index}`}>
                <ButtonBase
                  sx={{
                    px: 4,
                    py: 2,
                    width: '100%',
                    textAlign: 'left',
                    mb: 1,
                  }}
                  onClick={() => handleSelectChapter(index)}
                >
                  <Box flex={1} mr={4}>
                    <Typography
                      fontSize={14}
                      lineHeight={1.75}
                      color={chapter.locked ? 'shadowBlue' : 'isabelline'}
                    >
                      Challenge {index + 1}
                      {chapter.progress >= 100 && (
                        <Typography
                          component="span"
                          fontSize={14}
                          lineHeight={1.75}
                          color="icterine"
                        >
                          {' '}
                          - Completed
                        </Typography>
                      )}
                    </Typography>
                    <Typography
                      fontSize={32}
                      lineHeight={1.25}
                      color={chapter.locked ? 'shadowBlue' : 'isabelline'}
                    >
                      {chapter.title}
                    </Typography>
                    <Stack
                      mt={1}
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Typography
                        fontSize={14}
                        lineHeight={1.75}
                        color={chapter.locked ? 'shadowBlue' : 'isabelline'}
                        sx={{
                          opacity: 0.7,
                        }}
                      >
                        {chapter.total_lessons} Lessons
                      </Typography>
                      <XpGauge
                        xp={chapter.user_xp ?? 0}
                        max={chapter.total_xp ?? 0}
                        xpProps={{
                          color: chapter.locked ? 'shadowBlue' : 'isabelline',
                          fontSize: 14,
                          lineHeight: 1,
                          sx: {
                            opacity: 0.6,
                          },
                        }}
                        maxProps={{
                          color: chapter.locked ? 'shadowBlue' : 'isabelline',
                          fontSize: 14,
                          lineHeight: 1,
                          sx: {
                            opacity: 0.6,
                          },
                        }}
                        iconProps={{
                          width: 16,
                          height: 16,
                          opacity: chapter.locked ? 0.6 : 1,
                        }}
                      />
                    </Stack>
                  </Box>
                  <Box position="relative">
                    <CircularProgress
                      variant="determinate"
                      value={chapter.progress}
                      size={32}
                      color="icterine"
                      bgcolor="purpleNavy"
                      thickness={2}
                    />
                    <Stack
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: 32,
                        height: 32,
                      }}
                      justifyContent="center"
                      alignItems="center"
                      color={chapter.locked ? 'purpleNavy' : 'icterine'}
                    >
                      {chapter.progress >= 100 ? (
                        <CheckIcon width={16} height={16} />
                      ) : chapter.locked ? (
                        <LockIcon width={16} height={16} />
                      ) : null}
                    </Stack>
                  </Box>
                </ButtonBase>
                {index === 2 ? (
                  <UnlockInfoSection
                    description="Oily B3TR Lottery!"
                    onClick={handleToggleModal('lockedLevel1')}
                  />
                ) : null}
                {index === 6 ? (
                  <UnlockInfoSection
                    description="Mint Oily Ambassador NFT"
                    onClick={handleToggleModal('lockedLevel2')}
                  />
                ) : null}
                {index === 11 ? (
                  <UnlockInfoSection
                    description="Redeem Feature"
                    onClick={handleToggleModal('lockedLevel3')}
                  />
                ) : null}
                {index === 15 ? (
                  <UnlockInfoSection
                    description="Ability to apply for Map and Earn"
                    onClick={handleToggleModal('lockedLevel4')}
                  />
                ) : null}
              </React.Fragment>
            ))}
      </Box>
      <Box height={300} />
      <LockedChapterModal
        open={modals.lockedChapter}
        onClose={handleToggleModal('lockedChapter')}
      />
      <LockedAccountLvl1InfoModal
        open={modals.lockedLevel1}
        onClose={handleToggleModal('lockedLevel1')}
      />
      <LockedAccountLvl2InfoModal
        open={modals.lockedLevel2}
        onClose={handleToggleModal('lockedLevel2')}
      />
      <LockedAccountLvl3InfoModal
        open={modals.lockedLevel3}
        onClose={handleToggleModal('lockedLevel3')}
      />
      <LockedAccountLvl4InfoModal
        open={modals.lockedLevel4}
        onClose={handleToggleModal('lockedLevel4')}
      />
    </Box>
  );
};

UnlockInfoSection.propTypes = {
  description: PropTypes.string,
  onClick: PropTypes.func,
};

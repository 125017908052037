import React, { useCallback, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { Scaffold } from '../../../components/layouts/Scaffold';
import {
  Box,
  Button,
  Skeleton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { XpIndicator } from '../../../components/core/XpIndicator';
import { SharePreviewModal } from '../../../components/modals/ShareModal';
import { EnergySymbol } from '../../../components/core/EnergySymbol';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import {
  apiGetChapters,
  apiGetDemoChapters,
  apiGetDemoLessons,
  apiGetDemoQuestions,
  apiGetLessons,
  apiGetQuestions,
} from '../../../apis/l2e';
import { apiGetAccountLevel } from '../../../apis/profile';

import imgOily from '../../../assets/onboarding/oily-4.png';

const OilyImage = styled('img')({
  width: 140,
  height: 153,
  zIndex: 3,
});

export const L2ELessonRewardPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const chapterIndex = params?.chapterIndex
    ? parseInt(params.chapterIndex)
    : null;
  const lessonIndex = params?.lessonIndex ? parseInt(params.lessonIndex) : null;

  const isAnonymousLogin = useSelector((state) => state.auth.isAnonymousLogin);

  const { data: chapter } = useQuery({
    queryKey: ['l2e', 'chapters', chapterIndex],
    queryFn: async () => {
      const data = isAnonymousLogin
        ? await apiGetDemoChapters()
        : await apiGetChapters();
      if (data && data.length > chapterIndex) {
        return data[chapterIndex];
      } else {
        return null;
      }
    },
  });

  const { data: lesson } = useQuery({
    queryKey: ['l2e', 'chapters', chapterIndex, 'lessons', lessonIndex],
    queryFn: async () => {
      const data = isAnonymousLogin
        ? await apiGetDemoLessons(chapter._id)
        : await apiGetLessons(chapter._id);
      if (data && data.length > lessonIndex) {
        return data[lessonIndex];
      } else {
        return null;
      }
    },
    enabled: !!(chapter && chapter._id),
  });

  const { data: rewards } = useQuery({
    queryKey: [
      'l2e',
      'chapters',
      chapterIndex,
      'lessons',
      lessonIndex,
      'rewards',
    ],
    queryFn: async () => {
      const data = isAnonymousLogin
        ? await apiGetDemoQuestions(lesson._id)
        : await apiGetQuestions(lesson._id);

      let rewards = {
        xp: 0,
      };
      for (const item of data) {
        rewards.xp += item.user_xp;
      }
      return rewards;
    },
    enabled: !!(lesson && lesson._id),
  });

  const [modals, setModals] = useState({
    share: false,
  });

  const handleToggleModal = useCallback((name) => {
    setModals((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  }, []);

  const handleClose = useCallback(() => {
    navigate(`/l2e/chapters/${chapterIndex}`, {
      replace: true,
    });
  }, [navigate, chapterIndex]);

  const handleShare = useCallback(() => {
    handleToggleModal('share');
  }, [handleToggleModal]);

  useEffect(() => {
    apiGetAccountLevel();
  }, []);

  return (
    <Scaffold bgcolor="raisinBlack" height="100vh">
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={1}
          pb={1}
          minHeight={56}
        >
          <Box />
          <XpIndicator />
        </Stack>
        <Stack
          bgcolor="#1d1c26"
          justifyContent="center"
          alignItems="center"
          px={1}
          py={2}
          height={200}
          boxSizing="border-box"
          position="relative"
        >
          <OilyImage alt="Oily" src={imgOily} />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Confetti recycle={false} numberOfPieces={1000} />
        <Box pt={3} px={3}>
          <Typography fontSize={32} lineHeight={1.25} color="isabelline">
            Congratulations
          </Typography>
          <Typography fontSize={32} lineHeight={1.25} color="beauBlue">
            You&apos;ve earned
          </Typography>
          {rewards?.xp ? (
            <Stack direction="row" justifyContent="flex-end">
              <EnergySymbol
                width={32}
                height={32}
                style={{
                  alignSelf: 'center',
                }}
              />
              <Typography fontSize={100} lineHeight={1} color="isabelline">
                {rewards?.xp?.toFixed(0)}
              </Typography>
              <Typography
                fontSize={32}
                lineHeight={1.25}
                color="isabelline"
                mt={1}
              >
                XP
              </Typography>
            </Stack>
          ) : (
            <Skeleton variant="rounded" height={100} sx={{ mt: 1 }} />
          )}
          {rewards?.xp ? (
            <>
              <Typography
                mt={2}
                fontSize={16}
                lineHeight={1.5}
                color="isabelline"
              >
                You’ve earned {rewards?.xp?.toFixed(0)} XP as a reward for your
                time to improve your knowledge.
                <br />
                Share with the community and continue with your journey learning
                and contributing for UCO Community.
              </Typography>
            </>
          ) : (
            <>
              <Skeleton variant="rounded" height={24} sx={{ mt: 2 }} />
              <Skeleton variant="rounded" height={24} sx={{ mt: 0.5 }} />
              <Skeleton variant="rounded" height={24} sx={{ mt: 0.5 }} />
              <Skeleton variant="rounded" height={24} sx={{ mt: 2 }} />
              <Skeleton variant="rounded" height={24} sx={{ mt: 0.5 }} />
              <Skeleton variant="rounded" height={24} sx={{ mt: 0.5 }} />
            </>
          )}
        </Box>
        <SharePreviewModal
          amount={rewards?.xp ?? 0}
          open={modals.share}
          onClose={() => handleToggleModal('share')}
        />
      </Scaffold.Content>
      <Scaffold.Footer>
        <Stack direction="row" p={3} spacing={2}>
          <Button
            variant="contained"
            color="charcoal"
            sx={{
              flex: 1,
            }}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="icterine"
            sx={{
              flex: 2,
            }}
            onClick={handleShare}
          >
            Share
          </Button>
        </Stack>
      </Scaffold.Footer>
    </Scaffold>
  );
};

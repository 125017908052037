import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import imgOily from '../../assets/onboarding/oily-8.png';

export const AddWmoDisabledModal = ({ open, onClose }) => {
  const navigate = useNavigate();

  const { username, level } = useSelector((state) => state.profile);

  const handleAction = useCallback(() => {
    onClose();
    navigate('/l2e/chapters');
  }, [navigate, onClose]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <img src={imgOily} alt="Oily" />
        </Stack>
        <Typography fontSize={32} lineHeight={1.25} color="raisinBlack" mt={1}>
          {username ? username : 'Hi'},
        </Typography>
        <Typography fontSize={32} lineHeight={1.25} color="shadowBlue">
          This feature is locked!
        </Typography>
        <Typography mt={2} fontSize={16} lineHeight={1.75} color="charcoal">
          Adding WMOs is not available at Level {level}. To unlock this feature,
          you need to advance to Level 7. Continue learning and earning XP to
          reach higher levels and gain access to additional capabilities!
        </Typography>
        <Box mt={10}>
          <Button
            variant="contained"
            color="raisinBlack"
            onClick={handleAction}
          >
            Ok, take me there
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

AddWmoDisabledModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

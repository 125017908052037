import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  account: undefined,
  chainName: undefined,
  chainId: undefined,
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: initialState,
  reducers: {
    setWallet(state, action) {
      return action.payload;
    },
    updateWallet(state, action) {
      return { ...state, ...action.payload };
    },
    clearWallet() {
      return initialState;
    },
  },
});

export const { setWallet, updateWallet, clearWallet } = walletSlice.actions;

import React, { useCallback } from 'react';
import { Button, Stack, Box, IconButton } from '@mui/material';
import { Scaffold } from '../../components/layouts/Scaffold';
import { XpIndicator } from '../../components/core/XpIndicator';
import { ArrowLeftIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import imgOily from '../../assets/onboarding/oily-4.png';
import imgBubble from '../../assets/onboarding/bubble-5.png';

export const ReadyPage = () => {
  const navigate = useNavigate();

  const handleContinue = useCallback(() => {
    navigate('/l2e/chapters', {
      replace: true,
    });
  }, [navigate]);

  return (
    <Scaffold>
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={1}
          minHeight={56}
          pt={1}
        >
          <IconButton color="isabelline" sx={{ mr: 2 }}>
            <ArrowLeftIcon />
          </IconButton>
          <XpIndicator />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Stack
          height="100%"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box position="relative">
            <img
              src={imgOily}
              alt="Oily"
              style={{
                width: 280,
                height: 306,
              }}
            />
            <img
              src={imgBubble}
              alt="Oily"
              style={{
                position: 'absolute',
                left: -20,
                top: -130,
                width: 324,
                height: 149,
              }}
            />
          </Box>
        </Stack>
      </Scaffold.Content>
      <Scaffold.Footer>
        <Stack direction="row" justifyContent="center" px={3} pb={3}>
          <Button variant="contained" color="icterine" onClick={handleContinue}>
            Continue
          </Button>
        </Stack>
      </Scaffold.Footer>
    </Scaffold>
  );
};

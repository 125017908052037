import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { Scaffold } from '../../components/layouts/Scaffold';
import {
  Box,
  Button,
  ButtonBase,
  IconButton,
  Modal,
  Skeleton,
  Slider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { XpGauge } from '../../components/core/XpGauge';
import { XpIndicator } from '../../components/core/XpIndicator';
import {
  ArrowLeftIcon,
  ClipboardCheckIcon,
  CopyIcon,
  InfoIcon,
} from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useInfiniteQuery } from '@tanstack/react-query';
import { apiClaimReward, apiGetTransactions } from '../../apis/redeem';
import { apiGetProfile } from '../../apis/profile';
import { updateAppStatus } from '../../redux/app.slice';

export const TransactionItem = ({ item }) => {
  const theme = useTheme();

  return (
    <ButtonBase
      sx={{
        textAlign: 'left',
        px: 2,
        py: 1,
        borderRadius: 4,
        bgcolor: `${theme.palette.beauBlue}30`,
      }}
      component="a"
      href={item.transaction_link}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Box flex={1}>
        <Typography fontSize={14} lineHeight={1.7} color="isabelline">
          {item.xp.toFixed(0)}XP {'->'}{' '}
          {Number(item.rewarded_amount).toFixed(2)} B3TR
        </Typography>
        <Typography fontSize={10} lineHeight={1.6} color="beauBlue">
          {dayjs(item.date).format('DD MMMM YYYY')}
        </Typography>
      </Box>
      <Typography fontSize={14} lineHeight={1.7} color="beauBlue">
        {item.status}
      </Typography>
    </ButtonBase>
  );
};

export const RedeemPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    xp: currentXp,
    ratio,
    level,
    lockedXp,
  } = useSelector((state) => state.profile);
  const { account } = useSelector((state) => state.wallet);
  const { isGlobalLoading } = useSelector((state) => state.app);

  const xp = currentXp - lockedXp;

  const {
    data: transactions,
    status,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['transactions'],
    queryFn: async ({ pageParam }) => {
      return await apiGetTransactions({
        skip: pageParam,
        limit: 100,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < 100) {
        return undefined;
      }
      return allPages.length * 100;
    },
  });

  const [copied, setCopied] = useState(false);
  const [percentage, setPercentage] = useState(100);
  const [open, setOpen] = useState(false);
  const [guide, setGuide] = useState(false);

  const handleBack = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  const handleCopyAddress = useCallback(() => {
    if (account) {
      copy(account);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [account]);

  const handleRedeem = useCallback(async () => {
    setOpen(false);
    if (!xp || xp <= 0 || isGlobalLoading || !ratio) {
      return;
    }

    dispatch(
      updateAppStatus({
        isGlobalLoading: true,
      }),
    );
    const result = await apiClaimReward({
      percentage: percentage,
    });
    if (result && result > 0) {
      enqueueSnackbar({
        message: `You have converted ${((xp * percentage) / 100).toFixed(0)} XP into ${Number(result).toFixed(2)} B3TR`,
        variant: 'success',
      });
      await refetch();
    }
    dispatch(
      updateAppStatus({
        isGlobalLoading: false,
      }),
    );
  }, [xp, ratio, percentage, isGlobalLoading, refetch, dispatch]);

  useEffect(() => {
    apiGetProfile({
      need_balance: true,
      need_ratio: true,
    });
  }, []);

  useEffect(() => {
    if (level < 3) {
      // navigate('/home');
    }
  }, [level, navigate]);

  return (
    <Scaffold height="100vh" bgcolor="raisinBlack">
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={1}
          pb={2}
          minHeight={56}
        >
          <IconButton
            color="isabelline"
            size="small"
            sx={{ mr: 2 }}
            onClick={handleBack}
          >
            <ArrowLeftIcon />
          </IconButton>
          <XpIndicator onClick={() => setOpen(true)} />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Box px={3} mt={2}>
          <Typography
            fontSize={16}
            fontWeight={700}
            color="isabelline"
            lineHeight={1.75}
          >
            Current balance
          </Typography>
          <Box>
            <XpGauge
              xp={(((xp ?? 0) * percentage) / 100)?.toFixed(0) ?? '0'}
              max={xp?.toFixed(0) ?? '0'}
              iconProps={{
                width: 24,
                height: 24,
              }}
              xpProps={{
                fontSize: 32,
                lineHeight: 1.25,
                color: 'white',
              }}
              maxProps={{
                fontSize: 32,
                lineHeight: 1.25,
                color: 'shadowBlue',
              }}
            />
          </Box>
          <Typography fontSize={14} lineHeight={1.7} color="beauBlue">
            ~{' '}
            {(
              ((((xp ? xp : 0) * percentage) / 100) * (ratio ?? 1)) /
              100
            ).toFixed(3)}{' '}
            B3TR
          </Typography>
          <Typography fontSize={10} lineHeight={1.6} color="shadowBlue">
            ~{(1 / (ratio ? ratio / 100 : 1)).toLocaleString()} XP per 1 B3TR
          </Typography>
          {!xp ? (
            <ButtonBase
              sx={{
                width: '100%',
                px: 2,
                py: 1.5,
                borderRadius: 4,
                bgcolor: `${theme.palette.beauBlue}30`,
                flexDirection: 'column',
                boxSizing: 'border-box',
                mt: 3,
              }}
              component={NavLink}
              to="/challenges"
            >
              <Typography fontSize={22} lineHeight={1.5} color="white">
                You didn&apos;t earn any reward
              </Typography>
              <Typography fontSize={14} lineHeight={1.7} color="beauBlue">
                Head on to Challenges
              </Typography>
            </ButtonBase>
          ) : null}
          <Box mt={4} onClick={() => setOpen(true)}>
            <Button variant="contained" color="icterine" fullWidth>
              Redeem
            </Button>
          </Box>
          <Typography
            mt={4}
            fontSize={16}
            fontWeight={700}
            color="isabelline"
            lineHeight={1.75}
          >
            Your wallet address
          </Typography>
          <Stack mt={0.5} direction="row" alignItems="center">
            <Typography
              fontSize={14}
              lineHeight={1.7}
              color="isabelline"
              mr={1}
            >
              {account?.substr(0, 10)}...{account?.substr(-8, 8)}
            </Typography>
            <IconButton
              size="small"
              color="isabelline"
              onClick={handleCopyAddress}
            >
              {copied ? <ClipboardCheckIcon /> : <CopyIcon />}
            </IconButton>
          </Stack>
        </Box>
        <Stack mt={4} direction="column" spacing={1} px={3}>
          {transactions &&
            transactions.pages &&
            (transactions.pages.length === 0 ||
              transactions.pages[0].length === 0) && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography fontSize={14} color="isabelline">
                  No transactions yet
                </Typography>
                <IconButton
                  size="small"
                  color="isabelline"
                  onClick={() => setGuide(true)}
                >
                  <InfoIcon />
                </IconButton>
              </Stack>
            )}
          {status === 'pending' ? (
            Array(10)
              .fill(true)
              .map((item, index) => (
                <Skeleton
                  variant="rounded"
                  height={56}
                  key={`loading-${index}`}
                />
              ))
          ) : (
            <>
              {transactions?.pages?.map((page, index) => (
                <React.Fragment key={`page-${index}`}>
                  {page.map((item, ii) => (
                    <TransactionItem item={item} key={`trx-${index}-${ii}`} />
                  ))}
                </React.Fragment>
              ))}
              <Box className="mb-8 mt-8 flex flex-row justify-center">
                {hasNextPage ? (
                  <LoadingButton
                    variant="contained"
                    color="icterine"
                    loading={isFetchingNextPage}
                    onClick={fetchNextPage}
                  >
                    Load more
                  </LoadingButton>
                ) : null}
              </Box>
              {isFetchingNextPage &&
                Array(10)
                  .fill(true)
                  .map((item, index) => (
                    <Skeleton
                      variant="rounded"
                      height={56}
                      key={`loading-more-${index}`}
                    />
                  ))}
            </>
          )}
        </Stack>
        <Modal open={open} onClose={() => setOpen(false)}>
          <Box>
            <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
              You&apos;re about to convert&nbsp;
              <b>{Number((xp * percentage) / 100).toFixed(0)}</b> XP into&nbsp;
              <b>
                {Number((((xp * percentage) / 100) * ratio) / 100).toFixed(2)}
              </b>
              &nbsp; B3TR
            </Typography>
            <Typography
              mt={3}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              Set a value below to redeem your XP&apos;s for $B3TR:
            </Typography>
            <Box mt={6}>
              <Slider
                valueLabelDisplay="auto"
                value={percentage}
                onChange={(ev, value) => setPercentage(value)}
              />
            </Box>
            <Stack mt={11} direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="raisinBlack"
                onClick={handleRedeem}
              >
                Continue
              </Button>
            </Stack>
          </Box>
        </Modal>
        <Modal open={guide} onClose={() => setGuide(false)}>
          <Box>
            <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
              Thank you for Holding!
            </Typography>
            <Typography
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
              mt={3}
              component="div"
            >
              Better conversion rates & more utility are coming to Oily.
            </Typography>
            <Box mt={11}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={() => setGuide(false)}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      </Scaffold.Content>
    </Scaffold>
  );
};

TransactionItem.propTypes = {
  item: PropTypes.any,
};

import React, { useCallback, useEffect } from 'react';
import { Scaffold } from '../../../components/layouts/Scaffold';
import {
  Box,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { XpIndicator } from '../../../components/core/XpIndicator';
import { ArrowLeftIcon, ImageIcon } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import { useGeolocated } from 'react-geolocated';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { apiGetPlaceDetails } from '../../../apis/m2e';

import imgOily from '../../../assets/onboarding/oily-8.png';

export const M2EClaimedPlacePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const { data: details, isLoading } = useQuery({
    queryKey: ['m2e', 'places', data?._id],
    queryFn: async () => {
      if (data?._id) {
        return await apiGetPlaceDetails(data._id);
      }
      return null;
    },
  });

  const { coords, isGeolocationEnabled, isGeolocationAvailable } =
    useGeolocated();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleGetDirections = useCallback(() => {
    if (!data?.latitude && !data?.longitude) {
      return;
    }
    if (isGeolocationEnabled && isGeolocationAvailable) {
      const origin = `${coords?.latitude},${coords?.longitude}`;
      const destination = `${data.latitude},${data.longitude}`;
      const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;
      window.open(url, '_blank');
    } else {
      enqueueSnackbar({
        message: 'Please enable geolocation service',
        variant: 'error',
      });
      return;
    }
  }, [
    data?.latitude,
    data?.longitude,
    isGeolocationAvailable,
    isGeolocationEnabled,
    coords,
  ]);

  useEffect(() => {
    if (!data) {
      navigate('/m2e/places');
    }
  }, [navigate, data]);

  return (
    <Scaffold height="100vh" bgcolor="raisinBlack">
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={2}
          pb={2}
          minHeight={56}
          sx={{
            position: 'fixed',
            top: 0,
            left: '50%',
            right: 0,
            maxWidth: 640,
            width: '100%',
            transform: 'translateX(-50%)',
            boxSizing: 'border-box',
            zIndex: 10,
          }}
        >
          <IconButton
            color="isabelline"
            size="small"
            sx={{ mr: 2 }}
            onClick={handleBack}
          >
            <ArrowLeftIcon />
          </IconButton>
          <XpIndicator />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Box height={64} />
        <Stack direction="row" justifyContent="center">
          <Stack
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              width: 160,
              height: 160,
              overflow: 'hidden',
              bgcolor: 'isabelline',
              color: 'spanishGray',
              borderRadius: 6,
              transform: 'rotate(5deg)',
            }}
          >
            {data?.photo_url || details?.photo ? (
              <img
                src={details?.photo ? details.photo : data.photo_url}
                alt="restaurant"
                style={{
                  width: '100%',
                  height: '100%',
                  transform: 'rotate(-5deg) scale(110%)',
                }}
              />
            ) : (
              <ImageIcon />
            )}
          </Stack>
        </Stack>
        <Box mt={7} px={3}>
          <Typography
            fontSize={26}
            lineHeight={1.5}
            color="white"
            textAlign="center"
          >
            {data?.name}
          </Typography>
          <Typography
            mt={1}
            fontSize={14}
            lineHeight={1.7}
            color="shadowBlue"
            textAlign="center"
          >
            Restaurant
          </Typography>
          <Typography
            mt={2}
            fontSize={22}
            lineHeight={1.5}
            color="isabelline"
            textAlign="center"
          >
            Address
          </Typography>
          <Typography
            fontSize={14}
            px="16%"
            lineHeight={1.5}
            color="isabelline"
            textAlign="center"
          >
            {data?.address ?? 'Unknown'}
          </Typography>
          <Stack mt={1} direction="row" justifyContent="center">
            <Link
              fontSize={14}
              color="shadowBlue"
              onClick={handleGetDirections}
            >
              Get directions
            </Link>
          </Stack>
        </Box>
        <Box
          mt={2}
          mx={3}
          p={2.5}
          borderRadius={6}
          bgcolor="rgba(120, 76, 79, 0.25)"
        >
          <Stack direction="row" justifyContent="center">
            <img
              src={imgOily}
              alt="oily"
              style={{
                width: 78,
                height: 85,
              }}
            />
          </Stack>
          <Typography
            fontSize={20}
            lineHeight={1.5}
            color="isabelline"
            textAlign="center"
          >
            Someone claimed the place before
          </Typography>
          <Typography
            fontSize={14}
            lineHeight={1.7}
            color="greyishBlue"
            textAlign="center"
          >
            Don&apos;t give up; plenty of places remain to be claimed.
          </Typography>
          <Stack mt={2} direction="row" justifyContent="center">
            {isLoading ? (
              <Skeleton variant="rounded" height={20} width={200} />
            ) : (
              <Typography
                fontSize={14}
                lineHeight={1.7}
                color="isabelline"
                textAlign="center"
              >
                Mapped by @{details?.claimedBy ?? 'unknown'}
              </Typography>
            )}
          </Stack>
        </Box>
      </Scaffold.Content>
    </Scaffold>
  );
};

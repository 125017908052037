/* eslint-disable no-undef */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import { Scaffold } from '../../../components/layouts/Scaffold';
import {
  Button,
  IconButton,
  Stack,
  Typography,
  Box,
  CircularProgress,
  Modal,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { ArrowLeftIcon, XIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useGeolocated } from 'react-geolocated';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { apiAddRestaurant } from '../../../apis/m2e';

const scheme = yup.object({
  name: yup.string().required('Please fill restaurant name'),
  address: yup.string().required('Please fill restaurant address'),
  longitude: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue === '' ||
        Number.isNaN(Number(originalValue)) ||
        !originalValue
        ? null
        : value;
    })
    .required('Please fill longitude value'),
  latitude: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue === '' ||
        Number.isNaN(Number(originalValue)) ||
        !originalValue
        ? null
        : value;
    })
    .required('Please fill latitude value'),
});

const PlaceMarker = ({ onClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transform: 'translateY(-100%)',
        boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          width: 14,
          height: 14,
          borderRadius: 6,
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'white',
          bgcolor: 'error.500',
        }}
      />
      <Box
        sx={{
          width: 2,
          height: 8,
          borderRadius: '100%',
          mt: '-2px',
          bgcolor: 'white',
        }}
      />
    </Box>
  );
};

export const M2EAddRestaurantPage = () => {
  const navigate = useNavigate();

  const { coords } = useGeolocated();
  const { level } = useSelector((state) => state.profile);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
  });
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(16);
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [place, setPlace] = useState(null);
  const [modals, setModals] = useState({
    form: false,
    success: false,
    limit: false,
  });
  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      name: '',
      address: '',
      longitude: 0,
      latitude: 0,
    },
    resolver: yupResolver(scheme),
  });

  const toggleModal = useCallback((name) => {
    setModals((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  }, []);

  const onClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onMapMount = useCallback((map) => {
    setMap(map);
  }, []);

  const onMapUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const onZoomChanged = useCallback(() => {
    if (map) {
      setZoom(map.getZoom() ?? 16);
      setCenter({
        lat: map.getCenter()?.lat() ?? 0,
        lng: map.getCenter()?.lng() ?? 0,
      });
    }
  }, [map]);

  const onCenterChanged = useCallback(() => {
    if (map) {
      setCenter({
        lat: map.getCenter()?.lat() ?? 0,
        lng: map.getCenter()?.lng() ?? 0,
      });
    }
  }, [map]);

  const deg2rad = useCallback((deg) => {
    return deg * (Math.PI / 180);
  }, []);

  const getDistance = useCallback(
    (from) => {
      if (coords && coords.latitude !== 0 && coords.longitude !== 0) {
        const R = 6371;
        const dLat = deg2rad(coords?.latitude - from.lat);
        const dLng = deg2rad(coords?.longitude - from.lng);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(deg2rad(from.lat)) *
            Math.cos(deg2rad(coords?.latitude)) *
            Math.sin(dLng / 2) *
            Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
        return distance;
      }
    },
    [deg2rad, coords],
  );

  const onPressMap = useCallback(
    (e) => {
      if (e.latLng?.lat() && e.latLng?.lng()) {
        setPlace(e.latLng);

        const distance = getDistance({
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        });
        if (distance && distance <= (level < 7 ? 1 : 5)) {
          // todo
        } else {
          enqueueSnackbar(
            `You can only add restaurant within ${level < 7 ? 1 : 5}km radius`,
            {
              variant: 'error',
            },
          );
        }
      }
    },
    [getDistance, level],
  );

  const onSave = useCallback(
    async (values) => {
      setLoading(true);
      const result = await apiAddRestaurant(values);
      setLoading(false);

      if (result === true) {
        toggleModal('success');
      } else if (result === 'DAILY_LIMIT_REACHED') {
        toggleModal('limit');
      } else {
        // todo
      }
    },
    [toggleModal],
  );

  const onSuccess = useCallback(() => {
    navigate(`/m2e/places?lat=${place?.lat()}&lng=${place?.lng()}`, {
      replace: true,
    });
  }, [navigate, place]);

  const onReachedLimit = useCallback(() => {
    navigate('/m2e/places', {
      replace: true,
    });
  }, [navigate]);

  useEffect(() => {
    if (center.lat !== 0 || center.lng !== 0) {
      return;
    }
    if (
      coords &&
      (coords.latitude !== center.lat || coords.longitude !== center.lng)
    ) {
      setCenter({
        lat: coords.latitude,
        lng: coords.longitude,
      });
    }
  }, [coords, center]);

  useEffect(() => {
    setValue('latitude', center.lat);
    setValue('longitude', center.lng);
  }, [center, setValue]);

  useEffect(() => {
    if (level < 6) {
      navigate('/challenges');
    }
  }, [level, navigate]);

  return (
    <Scaffold height="100vh" bgcolor="isabelline">
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={1}
          pb={2}
          minHeight={56}
        >
          <IconButton color="raisinBlack" size="small" onClick={onClose}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography fontSize={14} lineHeight={1} color="raisinBlack">
            Add a Restaurant to Map
          </Typography>
          <IconButton color="raisinBlack" size="small" onClick={onClose}>
            <XIcon />
          </IconButton>
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        {isLoaded ? (
          <GoogleMap
            center={center}
            zoom={zoom}
            options={{
              zoomControl: false,
              mapTypeControl: false,
              streetViewControl: false,
              fullscreenControl: false,
            }}
            onLoad={onMapMount}
            onUnmount={onMapUnmount}
            onDragEnd={onCenterChanged}
            onZoomChanged={onZoomChanged}
            onClick={onPressMap}
            mapContainerStyle={{
              width: '100%',
              height: '100%',
            }}
          >
            {place ? (
              <OverlayView position={place} mapPaneName="overlayMouseTarget">
                <PlaceMarker onClick={() => toggleModal('form')} />
              </OverlayView>
            ) : null}
          </GoogleMap>
        ) : (
          <Stack
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="raisinBlack" />
          </Stack>
        )}
        <Modal
          open={modals.form || loading}
          onClose={() => toggleModal('form')}
        >
          <Box>
            <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
              Restaurant Details
            </Typography>
            <Box mt={4}>
              <Controller
                control={control}
                name="name"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    variant="standard"
                    color="light"
                    label="PLACE NAME"
                    placeholder="E.g.: Bounty Cool Burger"
                    required
                    fullWidth
                    {...field}
                    error={!!error?.message}
                    helperText={error?.message}
                  />
                )}
              />
            </Box>
            <Box mt={4}>
              <Controller
                control={control}
                name="address"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    variant="standard"
                    color="light"
                    label="ADDRESS"
                    placeholder="E.g.: Vestergade 21"
                    required
                    fullWidth
                    {...field}
                    error={!!error?.message}
                    helperText={error?.message}
                  />
                )}
              />
            </Box>
            <Stack mt={11} direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="raisinBlack"
                sx={{
                  flex: 1,
                }}
                onClick={() => toggleModal('form')}
              >
                Close
              </Button>
              <LoadingButton
                variant="contained"
                color="raisinBlack"
                loading={loading}
                sx={{
                  flex: 2,
                }}
                onClick={handleSubmit(onSave)}
              >
                Save
              </LoadingButton>
            </Stack>
          </Box>
        </Modal>
        <Modal open={modals.success} onClose={() => toggleModal('success')}>
          <Box>
            <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
              Restaurant added to the map!
            </Typography>
            <Typography
              mt={3}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              The restaurant will remain inactive on the map until our team
              manually checks/validates the data sent, which can take up to
              <b> 72 hours</b>.
              <br />
              <br />
              After validating and approving the data sent, you will have
              <b> 24 hours</b> to map the restaurant. After the deadline
              expires, the restaurant will be released to the public for
              mapping.
            </Typography>
            <Box mt={11}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={onSuccess}
              >
                Ok, I understand
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal open={modals.limit} onClose={() => toggleModal('limit')}>
          <Box>
            <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
              Daily Restaurant Limit Reached!
            </Typography>
            <Typography
              mt={3}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              You have reached the daily limit of <b>10 restaurant</b> additions
              to the map. Please try again tomorrow to add more restaurants.
              <br />
              <br />
              Thank you for your understanding!
            </Typography>
            <Box mt={11}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={onReachedLimit}
              >
                Ok, I understand
              </Button>
            </Box>
          </Box>
        </Modal>
      </Scaffold.Content>
      <Scaffold.Footer>
        <Stack direction="row" spacing={2} p={3}>
          <Button
            variant="outlined"
            color="raisinBlack"
            sx={{
              flex: 1,
            }}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="raisinBlack"
            sx={{
              flex: 2,
            }}
            disabled
          >
            Save
          </Button>
        </Stack>
      </Scaffold.Footer>
    </Scaffold>
  );
};

PlaceMarker.propTypes = {
  onClick: PropTypes.func,
};

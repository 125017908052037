import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isM2EInfoModalDisabled: false,
  lastM2EInfoModalShownAt: null,
};

export const variablesSlice = createSlice({
  name: 'variables',
  initialState: initialState,
  reducers: {
    setVariables(state, action) {
      return action.payload;
    },
    updateVariables(state, action) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setVariables, updateVariables } = variablesSlice.actions;

import React from 'react';
import PropTypes from 'prop-types';
import { ChevronDownIcon } from 'lucide-react';

const ArrowDropDownIcon = ({ className }) => {
  return <ChevronDownIcon className={className} />;
};

export const MuiSelect = {
  defaultProps: {
    IconComponent: ArrowDropDownIcon,
  },
};

export const MuiAutocomplete = {
  defaultProps: {
    popupIcon: ArrowDropDownIcon,
  },
};

ArrowDropDownIcon.propTypes = {
  className: PropTypes.string,
};

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  IconButton,
  Stack,
  Typography,
  Box,
  Button,
  Skeleton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Scaffold } from '../layouts/Scaffold';
import { ArrowLeftIcon, CheckIcon, XIcon } from 'lucide-react';
import { EnergySymbol } from '../core/EnergySymbol';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { apiLockXp, apiGetAccountLevel, apiLevelUp } from '../../apis/profile';
import { toggleFeatureDialog } from '../../redux/app.slice';

const OverviewSection = ({
  title,
  description,
  completed,
  loading,
  showEnergySymbol,
}) => {
  return (
    <Stack
      p={2}
      borderRadius={4}
      bgcolor="purpleNavy"
      direction="row"
      alignItems="center"
    >
      <Box flex={1} mr={2}>
        {loading ? (
          <Skeleton
            variant="rounded"
            height={14}
            width="60%"
            sx={{
              my: '5px',
            }}
          />
        ) : (
          <Stack direction="row" alignItems="center">
            {showEnergySymbol ? <EnergySymbol width={16} height={16} /> : null}
            <Typography
              fontSize={14}
              fontWeight={700}
              lineHeight={1.75}
              color="greyishBlue"
            >
              {title}
            </Typography>
          </Stack>
        )}
        {loading ? (
          <Skeleton
            variant="rounded"
            height={14}
            width="50%"
            sx={{
              my: '5px',
            }}
          />
        ) : (
          <Typography fontSize={14} lineHeight={1.75} color="isabelline">
            {description}
          </Typography>
        )}
      </Box>
      {loading ? (
        <Skeleton variant="circular" width={20} height={20} />
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          width={20}
          height={20}
          sx={{
            borderRadius: '100%',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: completed ? 'green.700' : 'error.500',
            color: completed ? 'green.700' : 'error.500',
          }}
        >
          {completed ? (
            <CheckIcon width={16} height={16} strokeWidth={3} />
          ) : (
            <XIcon width={16} height={16} strokeWidth={3} />
          )}
        </Stack>
      )}
    </Stack>
  );
};

const PrepareLevelUpDialog = ({ open, requirements, actions, onClose }) => {
  const dispatch = useDispatch();
  const level = useSelector((state) => state.profile.level);
  const username = useSelector((state) => state.profile.username);
  const mintNftXp = useSelector((state) => state.profile.mintNftXp);

  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLevelUp = useCallback(async () => {
    if (level === 0) {
      setLoading(true);
      await apiLevelUp();
      setLoading(false);
    } else {
      dispatch(
        toggleFeatureDialog({
          name: 'mintNft',
        }),
      );
    }
  }, [dispatch, level]);

  useEffect(() => {
    for (const item of requirements) {
      if (!item.completed || item.loading) {
        setCompleted(false);
        return;
      }
    }
    setCompleted(true);
  }, [requirements]);

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Scaffold bgcolor="royalBlueDark" height="100vh">
        <Scaffold.Header>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={2}
            pt={1}
            pb={2}
            minHeight={56}
          >
            <IconButton color="isabelline" size="small" onClick={onClose}>
              <ArrowLeftIcon />
            </IconButton>
            <Typography fontSize={14} color="isabelline" lineHeight={1}>
              Level {level + 1} Confirmation
            </Typography>
            <Box width={26} />
          </Stack>
        </Scaffold.Header>
        <Scaffold.Content>
          <Box mt={2} px={3}>
            <Typography fontSize={32} lineHeight={1.25} color="isabelline">
              Hey {username},
            </Typography>
            <Typography fontSize={32} lineHeight={1.25} color="isabelline">
              You are ready to advance to Level {level + 1}
            </Typography>
          </Box>
          <Box mt={6} px={3}>
            <Typography
              fontSize={20}
              fontWeight={500}
              lineHeight={1.4}
              color="beauBlue"
            >
              Progress Overview:
            </Typography>
          </Box>
          <Stack mt={4} direction="column" spacing={1} px={3}>
            {requirements.map((item, index) => (
              <OverviewSection key={`section-${index}`} {...item} />
            ))}
          </Stack>
          <Box
            mt={4}
            mx={3}
            p={3}
            sx={{
              borderRadius: 4,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'purpleNavy',
            }}
          >
            <Typography
              fontSize={20}
              fontWeight={500}
              lineHeight={1.4}
              px={3}
              color="beauBlue"
              textAlign="center"
            >
              {actions?.title && actions?.enabled
                ? actions?.title
                : completed
                  ? 'You are ready to Level Up!'
                  : 'You need to complete all the requirements to progress'}
            </Typography>
            <Typography
              mt={2}
              fontSize={16}
              lineHeight={1.75}
              color="isabelline"
              textAlign="center"
            >
              <b>Note: </b>
              {actions?.description && actions?.enabled
                ? actions?.description
                : `Level up will cost ${mintNftXp} XPs`}
            </Typography>
            <Stack
              mt={4}
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              {completed ? (
                <>
                  <LoadingButton
                    variant="contained"
                    color="icterine"
                    fullWidth
                    loading={loading}
                    onClick={handleLevelUp}
                  >
                    Yes, Level Up
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    color="icterine"
                    fullWidth
                    onClick={onClose}
                  >
                    No, I am not interested
                  </Button>
                </>
              ) : actions?.buttons && actions?.enabled ? (
                actions?.buttons.map(({ text, ...props }, index) => (
                  <LoadingButton key={`action-button-${index}`} {...props}>
                    {text}
                  </LoadingButton>
                ))
              ) : (
                <Button
                  variant="contained"
                  color="icterine"
                  fullWidth
                  onClick={onClose}
                >
                  I understand
                </Button>
              )}
            </Stack>
          </Box>
          <Box height={100} />
        </Scaffold.Content>
      </Scaffold>
    </Dialog>
  );
};

export const PrepareLevelUp1Dialog = ({ open, onClose }) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['profile', 'level'],
    queryFn: async () => {
      return await apiGetAccountLevel();
    },
  });

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  return (
    <PrepareLevelUpDialog
      open={open}
      requirements={[
        {
          title: '3 Learn & Earn Chapters',
          description:
            data?.completed_l2e_chapters >= 3
              ? '100% completion'
              : `${data?.completed_l2e_chapters} of 3 completed`,
          completed: data?.completed_l2e_chapters >= 3,
          loading: isFetching,
        },
      ]}
      onClose={onClose}
    />
  );
};

export const PrepareLevelUp2Dialog = ({ open, onClose }) => {
  const { xp, mintNftXp } = useSelector((state) => state.profile);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['profile', 'level'],
    queryFn: async () => {
      return await apiGetAccountLevel();
    },
  });

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  return (
    <PrepareLevelUpDialog
      open={open}
      requirements={[
        {
          title: '7 Learn & Earn Chapters',
          description:
            data?.completed_l2e_chapters >= 7
              ? '100% completion'
              : `${data?.completed_l2e_chapters} of 7 completed`,
          completed: data?.completed_l2e_chapters >= 7,
          loading: isFetching,
        },
        {
          title: '3 days of logins',
          description:
            data?.count_login >= 3
              ? 'Completed'
              : `${data?.count_login} of 3 days completed`,
          completed: data?.count_login >= 3,
          loading: isFetching,
        },
        {
          title: `${mintNftXp} XPs for Mint`,
          description: xp >= mintNftXp ? 'Verified' : 'Pending',
          showEnergySymbol: true,
          completed: xp >= mintNftXp,
          loading: isFetching,
        },
      ]}
      onClose={onClose}
    />
  );
};

export const PrepareLevelUp3Dialog = ({ open, onClose }) => {
  const { xp, mintNftXp } = useSelector((state) => state.profile);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['profile', 'level'],
    queryFn: async () => {
      return await apiGetAccountLevel();
    },
  });

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  return (
    <PrepareLevelUpDialog
      open={open}
      requirements={[
        {
          title: '12 Learn & Earn Chapters',
          description:
            data?.completed_l2e_chapters >= 12
              ? '100% completion'
              : `${data?.completed_l2e_chapters} of 12 completed`,
          completed: data?.completed_l2e_chapters >= 12,
          loading: isFetching,
        },
        {
          title: `${mintNftXp} XPs for Mint`,
          description: xp >= mintNftXp ? 'Verified' : 'Pending',
          showEnergySymbol: true,
          completed: xp >= mintNftXp,
          loading: isFetching,
        },
      ]}
      onClose={onClose}
    />
  );
};

export const PrepareLevelUp4Dialog = ({ open, onClose }) => {
  const { xp, mintNftXp } = useSelector((state) => state.profile);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['profile', 'level'],
    queryFn: async () => {
      return await apiGetAccountLevel();
    },
  });

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  return (
    <PrepareLevelUpDialog
      open={open}
      requirements={[
        {
          title: '16 Learn & Earn Chapters',
          description:
            data?.completed_l2e_chapters >= 16
              ? '100% completion'
              : `${data?.completed_l2e_chapters} of 16 completed`,
          completed: data?.completed_l2e_chapters >= 16,
          loading: isFetching,
        },
        {
          title: '14 days on the platform',
          description:
            data?.count_login >= 14
              ? 'Completed'
              : `${data?.count_login} of 14 days`,
          completed: data?.count_login >= 14,
          loading: isFetching,
        },
        {
          title: `${mintNftXp} XPs for Mint`,
          description: xp >= mintNftXp ? 'Verified' : 'Pending',
          showEnergySymbol: true,
          completed: xp >= mintNftXp,
          loading: isFetching,
        },
      ]}
      onClose={onClose}
    />
  );
};

export const PrepareLevelUp5Dialog = ({ open, onClose }) => {
  const { xp, mintNftXp } = useSelector((state) => state.profile);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['profile', 'level'],
    queryFn: async () => {
      return await apiGetAccountLevel();
    },
  });

  const [locking, setLocking] = useState(false);

  const handleLockXp = useCallback(async () => {
    setLocking(true);
    const result = await apiLockXp();
    if (result) {
      await refetch();
    }
    setLocking(false);
  }, [refetch]);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  return (
    <PrepareLevelUpDialog
      open={open}
      requirements={[
        {
          title: '5000 XPs',
          description: 'In your wallet',
          showEnergySymbol: true,
          completed: xp >= 5000,
          loading: isFetching,
        },
        {
          title: 'Locked XP',
          description: 'Lock 3000 XP',
          completed: data?.locked_xp ? true : false,
          loading: isFetching,
        },
        {
          title: `${mintNftXp} XPs for Mint`,
          description: xp >= mintNftXp ? 'Verified' : 'Pending',
          showEnergySymbol: true,
          completed: xp >= mintNftXp,
          loading: isFetching,
        },
      ]}
      actions={{
        title: 'Lock 3000 XPs & Move to Level 5',
        description:
          'You will not lose locked XPs. They will be unlocked after completing Level 5.',
        buttons: [
          {
            text: 'Yes, Lock XPs',
            variant: 'contained',
            color: 'icterine',
            fullWidth: true,
            loading: locking,
            onClick: handleLockXp,
          },
          {
            text: 'No, I am not interested',
            variant: 'outlined',
            color: 'icterine',
            fullWidth: true,
            onClick: onClose,
          },
        ],
        enabled: data?.locked_xp ? false : true,
      }}
      onClose={onClose}
    />
  );
};

export const PrepareLevelUp6Dialog = ({ open, onClose }) => {
  const { xp, mintNftXp } = useSelector((state) => state.profile);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['profile', 'level'],
    queryFn: async () => {
      return await apiGetAccountLevel();
    },
  });

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  return (
    <PrepareLevelUpDialog
      open={open}
      requirements={[
        {
          title: 'Successfully mapped 10 restaurants',
          description:
            data?.verified_mapped_m2e_locations >= 10
              ? '100% completion'
              : `${data?.verified_mapped_m2e_locations} of 10 completed`,
          completed: data?.verified_mapped_m2e_locations >= 10,
          loading: isFetching,
        },
        {
          title: `${mintNftXp} XPs for Mint`,
          description: xp >= mintNftXp ? 'Verified' : 'Pending',
          showEnergySymbol: true,
          completed: xp >= mintNftXp,
          loading: isFetching,
        },
      ]}
      onClose={onClose}
    />
  );
};

export const PrepareLevelUp7Dialog = ({ open, onClose }) => {
  const { xp, mintNftXp } = useSelector((state) => state.profile);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['profile', 'level'],
    queryFn: async () => {
      return await apiGetAccountLevel();
    },
  });

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  return (
    <PrepareLevelUpDialog
      open={open}
      requirements={[
        {
          title: 'Successfully mapped 20+ restaurants',
          description:
            data?.verified_mapped_m2e_locations >= 20
              ? '100% completion'
              : `${data?.verified_mapped_m2e_locations} of 20 completed`,
          completed: data?.verified_mapped_m2e_locations >= 20,
          loading: isFetching,
        },
        {
          title: 'Successfully added 30+ restaurants',
          description:
            data?.verified_added_m2e_locations >= 30
              ? '100% completion'
              : `${data?.verified_added_m2e_locations} of 30 completed`,
          completed: data?.verified_added_m2e_locations >= 30,
          loading: isFetching,
        },
        {
          title: `${mintNftXp} XPs for Mint`,
          description: xp >= mintNftXp ? 'Verified' : 'Pending',
          showEnergySymbol: true,
          completed: xp >= mintNftXp,
          loading: isFetching,
        },
      ]}
      onClose={onClose}
    />
  );
};

export const PrepareLevelUp8Dialog = ({ open, onClose }) => {
  const { xp, mintNftXp } = useSelector((state) => state.profile);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['profile', 'level'],
    queryFn: async () => {
      return await apiGetAccountLevel();
    },
  });

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  return (
    <PrepareLevelUpDialog
      open={open}
      requirements={[
        {
          title: 'Successfully mapped 50+ restaurants',
          description:
            data?.verified_mapped_m2e_locations >= 50
              ? '100% completion'
              : `${data?.verified_mapped_m2e_locations} of 50 completed`,
          completed: data?.verified_mapped_m2e_locations >= 50,
          loading: isFetching,
        },
        {
          title: `${mintNftXp} XPs for Mint`,
          description: xp >= mintNftXp ? 'Verified' : 'Pending',
          showEnergySymbol: true,
          completed: xp >= mintNftXp,
          loading: isFetching,
        },
      ]}
      onClose={onClose}
    />
  );
};

export const PrepareLevelUp9Dialog = ({ open, onClose }) => {
  const { xp, mintNftXp } = useSelector((state) => state.profile);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['profile', 'level'],
    queryFn: async () => {
      return await apiGetAccountLevel();
    },
  });

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  return (
    <PrepareLevelUpDialog
      open={open}
      requirements={[
        {
          title: 'Successfully mapped 100+ restaurants',
          description:
            data?.verified_mapped_m2e_locations >= 100
              ? '100% completion'
              : `${data?.verified_mapped_m2e_locations} of 100 completed`,
          completed: data?.verified_mapped_m2e_locations >= 100,
          loading: isFetching,
        },
        {
          title: `${mintNftXp} XPs for Mint`,
          description: xp >= mintNftXp ? 'Verified' : 'Pending',
          showEnergySymbol: true,
          completed: xp >= mintNftXp,
          loading: isFetching,
        },
      ]}
      onClose={onClose}
    />
  );
};

export const PrepareLevelUp10Dialog = ({ open, onClose }) => {
  const { xp, mintNftXp } = useSelector((state) => state.profile);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['profile', 'level'],
    queryFn: async () => {
      return await apiGetAccountLevel();
    },
  });

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  return (
    <PrepareLevelUpDialog
      open={open}
      requirements={[
        {
          title: 'Successfully mapped 150+ restaurants',
          description:
            data?.verified_mapped_m2e_locations >= 150
              ? '100% completion'
              : `${data?.verified_mapped_m2e_locations} of 150 completed`,
          completed: data?.verified_mapped_m2e_locations >= 150,
          loading: isFetching,
        },
        {
          title: `${mintNftXp} XPs for Mint`,
          description: xp >= mintNftXp ? 'Verified' : 'Pending',
          showEnergySymbol: true,
          completed: xp >= mintNftXp,
          loading: isFetching,
        },
      ]}
      onClose={onClose}
    />
  );
};

PrepareLevelUpDialog.propTypes = {
  open: PropTypes.bool,
  requirements: PropTypes.any,
  actions: PropTypes.any,
  onClose: PropTypes.func,
};

PrepareLevelUp1Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
PrepareLevelUp2Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
PrepareLevelUp3Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
PrepareLevelUp4Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
PrepareLevelUp5Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
PrepareLevelUp6Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
PrepareLevelUp7Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
PrepareLevelUp8Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
PrepareLevelUp9Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
PrepareLevelUp10Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

OverviewSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  completed: PropTypes.bool,
  loading: PropTypes.bool,
  showEnergySymbol: PropTypes.bool,
};

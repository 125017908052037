import React, { useCallback } from 'react';
import { Box, ButtonBase, Stack, Typography, useTheme } from '@mui/material';
import { ArrowRightIcon } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFeatureDialog } from '../../redux/app.slice';

export const LevelUpSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { level } = useSelector((state) => state.profile);

  const handleClick = useCallback(() => {
    dispatch(
      toggleFeatureDialog({
        name: `prepareLevelUp${level + 1}`,
      }),
    );
  }, [dispatch, level]);

  if (level >= 10) {
    return;
  }

  return (
    <Box px={1} mb={1}>
      <ButtonBase
        sx={{
          borderRadius: 4,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'purpleNavy',
          display: 'block',
          width: '100%',
          boxSizing: 'border-box',
          px: 3,
          py: 4,
          textAlign: 'left',
        }}
        onClick={handleClick}
      >
        <Typography
          fontSize={24}
          fontWeight={500}
          lineHeight={1.3}
          color="beauBlue"
        >
          You&apos;re on a roll
        </Typography>
        <Typography
          fontSize={48}
          fontWeight={500}
          lineHeight={1.15}
          color="isabelline"
        >
          Level up!
        </Typography>
        <Typography mt={1} fontSize={16} lineHeight={1.75} color="beauBlue">
          <b>Note:</b> You need to complete all the requirements to progress to
          the next level.
        </Typography>
        <Stack mt={3} direction="row" alignItems="center" spacing={2}>
          <Typography fontSize={32} lineHeight={1.25} color="icterine">
            Check Now
          </Typography>
          <ArrowRightIcon color={theme.palette.icterine} />
        </Stack>
      </ButtonBase>
    </Box>
  );
};

import React, { useCallback } from 'react';
import { Button, Stack, Box, Typography, IconButton } from '@mui/material';
import { Scaffold } from '../../components/layouts/Scaffold';
import { XpIndicator } from '../../components/core/XpIndicator';
import { ArrowLeftIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import imgOily from '../../assets/onboarding/oily-3.png';
import imgBubble from '../../assets/onboarding/bubble-4.png';

export const SettingsPage = () => {
  const navigate = useNavigate();

  const handleContinue = useCallback(() => {
    navigate('/onboarding/ready', {
      replace: true,
    });
  }, [navigate]);

  return (
    <Scaffold>
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={1}
          minHeight={56}
          pt={1}
        >
          <IconButton color="isabelline" sx={{ mr: 2 }}>
            <ArrowLeftIcon />
          </IconButton>
          <XpIndicator />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Stack
          height="100%"
          direction="column"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box
            width={296}
            mb={9}
            p={3}
            position="relative"
            bgcolor="isabelline"
            borderRadius={2.5}
          >
            <Typography fontSize={18} lineHeight={1.7} color="raisinBlack">
              The website ”oily.uco.network” would like to send you
              notifications.
            </Typography>
            <Stack mt={3} direction="row" justifyContent="center" spacing={2}>
              <Button
                variant="outlined"
                size="small"
                color="raisinBlack"
                onClick={handleContinue}
              >
                Block
              </Button>
              <Button
                variant="contained"
                size="small"
                color="raisinBlack"
                onClick={handleContinue}
              >
                Allow
              </Button>
            </Stack>
            <img
              src={imgOily}
              alt="Oily"
              style={{
                position: 'absolute',
                left: 20,
                top: -110,
                width: 140,
                height: 110,
              }}
            />
            <img
              src={imgBubble}
              alt="Oily"
              style={{
                position: 'absolute',
                left: 100,
                top: -230,
                width: 163,
                height: 149,
              }}
            />
          </Box>
        </Stack>
      </Scaffold.Content>
      <Scaffold.Footer>
        <Stack direction="row" justifyContent="center" px={3} pb={3}>
          <Button variant="contained" color="icterine" onClick={handleContinue}>
            Continue
          </Button>
        </Stack>
      </Scaffold.Footer>
    </Scaffold>
  );
};

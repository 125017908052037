import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import ordinal from 'ordinal';
import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Scaffold } from '../../components/layouts/Scaffold';
import { XpIndicator } from '../../components/core/XpIndicator';
import { ArrowLeftIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useInfiniteQuery } from '@tanstack/react-query';
import { apiGetLeaderboardData } from '../../apis/leaderboard';

const HighlightUser = ({ user, rank }) => {
  return (
    <Stack direction="column" alignItems="center">
      {user ? (
        <Box position="relative">
          <img
            src={user?.userimage ?? 'https://placehold.co/256x256/png'}
            alt="person"
            style={{
              width: rank === 1 ? 64 : 40,
              height: rank === 1 ? 64 : 40,
              borderRadius: '100%',
            }}
          />
          <Typography
            fontSize={10}
            fontWeight={700}
            lineHeight={1.6}
            color={
              rank === 1
                ? 'raisinBlack'
                : rank === 2
                  ? 'raisinBlack'
                  : 'pennBlue'
            }
            bgcolor={
              rank === 1 ? 'icterine' : rank === 2 ? 'isabelline' : 'beauBlue'
            }
            py="2px"
            width={32}
            textAlign="center"
            borderRadius={4}
            position="absolute"
            top="0"
            left="50%"
            sx={{
              transform: 'translate(10%, -50%)',
            }}
          >
            {rank === 1 ? '1st' : rank === 2 ? '2nd' : '3rd'}
          </Typography>
        </Box>
      ) : (
        <Skeleton
          variant="circular"
          width={rank === 1 ? 64 : 40}
          height={rank === 1 ? 64 : 40}
        />
      )}
      {user ? (
        <Typography
          mt={0.5}
          fontSize={14}
          fontWeight={700}
          color="isabelline"
          lineHeight={1.75}
        >
          {user?.username ?? 'Anonymous'}
        </Typography>
      ) : (
        <Skeleton
          variant="rounded"
          width={100}
          height={14}
          sx={{
            mt: 0.85,
            mb: 0.85,
          }}
        />
      )}
      {user ? (
        <Typography
          fontSize={14}
          fontWeight={700}
          color="beauBlue"
          lineHeight={1.75}
        >
          Level {rank}
        </Typography>
      ) : (
        <Skeleton
          variant="rounded"
          width={100}
          height={14}
          sx={{
            mt: 0.1,
          }}
        />
      )}
    </Stack>
  );
};

const ListUser = ({ user, rank, highlight }) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="center"
      p={1}
      pr={2}
      bgcolor={highlight ? 'isabelline' : `${theme.palette.beauBlue}30`}
      borderRadius={4}
      spacing={1}
    >
      <img
        src={user?.userimage ?? '/logo512.png'}
        alt="person"
        style={{
          width: 40,
          height: 40,
          borderRadius: '100%',
        }}
      />
      <Box flex={1}>
        <Typography
          fontSize={14}
          fontWeight={700}
          lineHeight={1.25}
          color={highlight ? 'shadowBlue' : 'beauBlue'}
        >
          {ordinal(rank)}
        </Typography>
        <Typography
          fontSize={14}
          fontWeight={700}
          lineHeight={1.25}
          color={highlight ? 'pennBlue' : 'isabelline'}
        >
          {user?.username ?? 'Anonymous'}
        </Typography>
      </Box>
      <Typography
        fontSize={14}
        fontWeight={700}
        lineHeight={1.25}
        color={highlight ? 'pennBlue' : 'isabelline'}
      >
        Level {user?.level ?? 1}
      </Typography>
    </Stack>
  );
};

const LoadingUsers = () => {
  return (
    <>
      <Skeleton variant="rounded" width="100%" height={44} />
      <Skeleton variant="rounded" width="100%" height={44} />
      <Skeleton variant="rounded" width="100%" height={44} />
      <Skeleton variant="rounded" width="100%" height={44} />
      <Skeleton variant="rounded" width="100%" height={44} />
      <Skeleton variant="rounded" width="100%" height={44} />
      <Skeleton variant="rounded" width="100%" height={44} />
      <Skeleton variant="rounded" width="100%" height={44} />
      <Skeleton variant="rounded" width="100%" height={44} />
      <Skeleton variant="rounded" width="100%" height={44} />
    </>
  );
};

export const LeaderboardPage = () => {
  const navigate = useNavigate();

  const { id: authId } = useSelector((state) => state.auth);

  const selectedMonth = useMemo(() => dayjs(), []);

  const [firstRanking, setFirstRanking] = useState(null);
  const [secondRanking, setSecondRanking] = useState(null);
  const [thirdRanking, setThirdRanking] = useState(null);
  const [userRanking, setUserRanking] = useState(null);

  const { data, status, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ['leaderboard', selectedMonth.year(), selectedMonth.month()],
      queryFn: async ({ pageParam }) => {
        const result = await apiGetLeaderboardData({
          month: selectedMonth.month() + 1,
          year: selectedMonth.year(),
          limit: 100,
          skip: pageParam,
        });

        if (result.first3.length > 0) {
          setFirstRanking(result.first3[0]);
        }
        if (result.first3.length > 1) {
          setSecondRanking(result.first3[1]);
        }
        if (result.first3.length > 2) {
          setThirdRanking(result.first3[2]);
        }
        if (result.me) {
          setUserRanking(result.me);
        }
        return result.data;
      },
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < 100) {
          return undefined;
        }
        return allPages.length * 100;
      },
    });

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Scaffold height="100vh" bgcolor="raisinBlack">
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={1}
          pb={2}
          minHeight={56}
        >
          <IconButton
            color="isabelline"
            size="small"
            sx={{ mr: 2 }}
            onClick={handleBack}
          >
            <ArrowLeftIcon />
          </IconButton>
          <XpIndicator />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Box px={3}>
          <Typography fontSize={32} lineHeight={1.25} color="isabelline">
            Top 10 Mapping
          </Typography>
          <Typography fontSize={32} lineHeight={1.25} color="beauBlue">
            Leaderboard
          </Typography>
        </Box>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-end"
          mt={6}
        >
          <HighlightUser user={secondRanking} rank={2} />
          <HighlightUser user={firstRanking} rank={1} />
          <HighlightUser user={thirdRanking} rank={3} />
        </Stack>
        <Stack direction="column" mt={4} px={3} spacing={1}>
          {status === 'pending' ? <LoadingUsers /> : null}
          {data?.pages.map((page, index) => (
            <React.Fragment key={`page-${index}`}>
              {page.map((item) =>
                item.rank >= 4 && item.user_id !== authId ? (
                  <ListUser
                    key={`user-${item.user_id}`}
                    user={item}
                    rank={item.rank}
                  />
                ) : null,
              )}
            </React.Fragment>
          ))}
          {hasNextPage ? (
            <Stack pt={8} direction="row" justifyContent="center">
              <LoadingButton
                variant="contained"
                color="charcoal"
                size="small"
                loading={isFetchingNextPage}
                onClick={fetchNextPage}
              >
                Load more
              </LoadingButton>
            </Stack>
          ) : null}
          <Box height={150} />
        </Stack>
      </Scaffold.Content>
      <Scaffold.Footer>
        <Box p={3}>
          <Typography fontSize={16} lineHeight={1.75} color="isabelline" mb={3}>
            Keep levelling up and you can be the next top contributor!
          </Typography>
          {userRanking ? (
            <ListUser user={userRanking} rank={userRanking.rank} highlight />
          ) : (
            <Skeleton variant="rounded" width="100%" height={44} />
          )}
        </Box>
      </Scaffold.Footer>
    </Scaffold>
  );
};

HighlightUser.propTypes = {
  user: PropTypes.any,
  rank: PropTypes.number,
};

ListUser.propTypes = {
  user: PropTypes.any,
  rank: PropTypes.number,
  highlight: PropTypes.bool,
};

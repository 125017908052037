import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { SelectChoice } from './SelectChoice';

export const SelectChoiceGroup = ({
  options,
  value,
  onChange,
  ...boxProps
}) => {
  return (
    <Box {...boxProps}>
      {options.map((option, index) => (
        <SelectChoice
          key={`option-${index}`}
          checked={option.value === value}
          error={option.error}
          label={option.label}
          onChange={(value) => {
            if (value) {
              onChange(options[index].value);
            }
          }}
        />
      ))}
    </Box>
  );
};

SelectChoiceGroup.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

import axios from 'axios';
import { apiGetProfile } from './profile';
import { enqueueSnackbar } from 'notistack';
import { store } from '../redux/store';
import { setAppConstants } from '../redux/app.slice';

export const apiGetPlaces = async ({ latitude, longitude, radius }) => {
  try {
    const response = await axios.post('/api/client/v1/m2e/places', {
      latitude: latitude,
      longitude: longitude,
      radius: radius,
    });

    store.dispatch(
      setAppConstants({
        m2eMapPlaceXp: response.data.total_xp,
      }),
    );

    const data = response.data.data.map((item) => ({
      ...item,
      total_xp: response.data.total_xp,
      initial_xp: response.data.initial_xp,
    }));
    return data;
  } catch {
    return [];
  }
};

export const apiGetMyRestaurants = async () => {
  try {
    const response = await axios.get('/api/client/v1/m2e/place');
    return response.data.data;
  } catch {
    return [];
  }
};

export const apiGetMyWMOs = async () => {
  try {
    const response = await axios.get('/api/client/v1/m2e/wmo');
    return response.data.data;
  } catch {
    return [];
  }
};

export const apiGetMyLocations = async ({ skip, limit }) => {
  try {
    const response = await axios.post('/api/client/v1/m2e/location', {
      skip,
      limit,
    });
    return response.data.data;
  } catch {
    return [];
  }
};

export const apiGetPlaceDetails = async (id) => {
  try {
    const response = await axios.get(`/api/client/v1/m2e/place/${id}`);
    return {
      id: id,
      claimed: !!response.data?.data?.claimed_by,
      claimedBy: response.data?.data?.claimed_by,
      photo: response.data?.data?.photo_url,
    };
  } catch {
    return {
      id: id,
      claimed: false,
    };
  }
};

export const apiClaimPlace = async ({
  place_id,
  contact_name,
  contact_email,
  contact_phone_number,
  charge_name,
  charge_email,
  photo_url,
  waste_info,
  is_collected_by_wmo,
  is_company,
  is_compensating,
  collector_name,
  collector_email,
  collector_phone_number,
  spoken_language,
}) => {
  try {
    await axios.post('/api/client/v1/m2e/claim', {
      place_id,
      contact_name,
      contact_email,
      contact_phone_number,
      charge_name,
      charge_email,
      photo_url,
      waste_info,
      is_collected_by_wmo,
      is_company,
      is_compensating,
      collector_name,
      collector_email,
      collector_phone_number,
      spoken_language,
    });
    await apiGetProfile({
      need_balance: true,
      need_ratio: true,
    });
    return true;
  } catch {
    return false;
  }
};

export const apiAddWmo = async ({
  type,
  collector_name,
  collector_email,
  collector_phone_number,
  count_vehicles,
}) => {
  try {
    await axios.post('/api/client/v1/m2e/wmo', {
      type,
      collector_name,
      collector_email,
      collector_phone_number,
      count_vehicles,
    });
    return true;
  } catch (e) {
    if (e?.response?.data?.error === 'DAILY_LIMIT_REACHED') {
      return 'DAILY_LIMIT_REACHED';
    } else {
      enqueueSnackbar({
        message: 'Please check your data and try again',
        variant: 'error',
      });
      return false;
    }
  }
};

export const apiAddRestaurant = async ({
  name,
  address,
  latitude,
  longitude,
}) => {
  try {
    await axios.post('/api/client/v1/m2e/place', {
      name,
      address,
      latitude,
      longitude,
      type: 'restaurant',
    });
    return true;
  } catch (e) {
    if (e?.response?.data?.error === 'DAILY_LIMIT_REACHED') {
      return 'DAILY_LIMIT_REACHED';
    } else {
      enqueueSnackbar({
        message: 'Please check your data and try again',
        variant: 'error',
      });
      return false;
    }
  }
};

export const apiGetM2ESummary = async () => {
  try {
    const response = await axios.get('/api/client/v1/m2e/status');
    return {
      count_added_locations: response.data.data.count_added_locations,
      count_verified_added_locations:
        response.data.data.count_verified_added_locations,
      count_mapped_locations: response.data.data.count_mapped_locations,
      count_verified_mapped_locations:
        response.data.data.count_verified_mapped_locations,
    };
  } catch {
    return {
      count_added_locations: 0,
      count_verified_added_locations: 0,
      count_mapped_locations: 0,
      count_verified_mapped_locations: 0,
    };
  }
};

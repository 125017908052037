import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Scaffold } from '../layouts/Scaffold';
import { XIcon } from 'lucide-react';
import { EnergySymbol } from '../core/EnergySymbol';

import imgOily from '../../assets/onboarding/oily-level-6.png';
import imgBadge from '../../assets/badges/badge-lvl6.png';
import imgBadgeNext from '../../assets/badges/badge-lvl7.png';

export const LevelUp6Dialog = ({ open, onClose }) => {
  const handleContinue = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Scaffold bgcolor="levels.6.bgcolor" height="100vh">
        <Confetti recycle={false} numberOfPieces={1000} />
        <Scaffold.Header>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={2}
            pt={1}
            pb={2}
            minHeight={56}
          >
            <Box width={26} />
            <Typography fontSize={14} color="isabelline" lineHeight={1}>
              Level Completed
            </Typography>
            <IconButton color="isabelline" size="small" onClick={onClose}>
              <XIcon />
            </IconButton>
          </Stack>
        </Scaffold.Header>
        <Scaffold.Content>
          <Stack direction="row" justifyContent="center">
            <img src={imgOily} alt="Oily" />
          </Stack>
          <Box px={3}>
            <Typography
              mt={3}
              fontSize={32}
              lineHeight={1.25}
              color="isabelline"
            >
              Outstanding!
              <br />
              You&apos;ve Reached
            </Typography>
            <Typography fontSize={32} lineHeight={1.25} color="beauBlue">
              Level 6: Verified Mapper
            </Typography>
            <Stack direction="row" alignItems="flex-start" spacing={2} mt={2}>
              <img
                src={imgBadge}
                alt="Badge"
                style={{
                  width: 72,
                  height: 72,
                }}
              />
              <Typography
                flex={1}
                fontSize={20}
                lineHeight={1.4}
                color="isabelline"
              >
                You&apos;re becoming an Oily mapping expert—ready to expand your
                impact even further?
              </Typography>
            </Stack>

            <Typography
              mt={4}
              fontSize={32}
              lineHeight={1.25}
              color="isabelline"
            >
              You&apos;ve got back your
            </Typography>
            <Stack direction="row" justifyContent="flex-end">
              <EnergySymbol
                width={32}
                height={32}
                style={{
                  alignSelf: 'center',
                }}
              />
              <Typography fontSize={100} lineHeight={1} color="isabelline">
                3000
              </Typography>
              <Typography
                fontSize={32}
                lineHeight={1.25}
                color="isabelline"
                mt={1}
              >
                XP
              </Typography>
            </Stack>
            <Typography
              mt={4}
              fontSize={20}
              fontWeight={500}
              lineHeight={1.4}
              color="beauBlue"
            >
              Your Achievements:
            </Typography>
            <Typography
              mt={1}
              fontSize={18}
              fontWeight={700}
              lineHeight={1.75}
              color="isabelline"
            >
              Completed
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="isabelline"
              component="div"
            >
              <ul>
                <li>Successfully mapped and verified 10 restaurants</li>
              </ul>
            </Typography>
            <Typography
              mt={1}
              fontSize={18}
              fontWeight={700}
              lineHeight={1.75}
              color="isabelline"
            >
              Rewards
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="isabelline"
              component="div"
            >
              <ul>
                <li>
                  Your locked <b>3000 XP</b> has been returned
                </li>
                <li>Earned additional XP for mapped locations</li>
              </ul>
            </Typography>
            <Typography
              mt={1}
              fontSize={18}
              fontWeight={700}
              lineHeight={1.75}
              color="isabelline"
            >
              Unlocked
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="isabelline"
              component="div"
            >
              <ul>
                <li>Full access to the Map and Earn feature</li>
                <li>Ability to upload pictures for mapping</li>
                <li>Permission to ADD new restaurants to the map</li>
                <li>XP multiplier: 1.1</li>
              </ul>
            </Typography>
            <Typography
              mt={4}
              fontSize={20}
              fontWeight={500}
              lineHeight={1.4}
              color="beauBlue"
            >
              What&apos;s Next on Your Path?
            </Typography>
            <Stack mt={1} direction="row" alignItems="flex-start" spacing={2}>
              <img
                src={imgBadgeNext}
                alt="Oily"
                style={{
                  width: 32,
                  height: 32,
                }}
              />
              <Typography fontSize={16} lineHeight={1.75} color="isabelline">
                <b>Level 7: UCO Champion</b>
                <br />
                Take it to the next level! Map 20+ verified restaurants and add
                30+ verified restaurants to unlock full Access to Map and Earn +
                possibility to map restaurant even if 5kms away.
              </Typography>
            </Stack>
          </Box>
          <Box mt={5} px={4} pb={9}>
            <Button
              variant="contained"
              color="icterine"
              fullWidth
              onClick={handleContinue}
            >
              Continue
            </Button>
          </Box>
        </Scaffold.Content>
      </Scaffold>
    </Dialog>
  );
};

LevelUp6Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

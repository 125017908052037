/* eslint-disable no-undef */

import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { store } from '../redux/store';
import { updateProfile } from '../redux/profile.slice';
import { apiRunFingerprintjs } from './fingerprintjs';

export const apiClaimReward = ({ percentage }) => {
  return new Promise((resolve) => {
    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(
          process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
          { action: 'redeem' },
        );
        const response = await axios.post('/api/client/v1/user/claim-reward', {
          recaptcha_token: token,
          percentage: percentage,
        });
        store.dispatch(
          updateProfile({
            xp: ((store.getState().auth.xp ?? 0) * (100 - percentage)) / 100,
            reward:
              ((store.getState().auth.reward ?? 0) * (100 - percentage)) / 100,
          }),
        );

        await apiRunFingerprintjs();
        resolve(response.data.data.price);
      } catch (e) {
        if (e.response?.data?.error === 'NOTHING_FOR_REWARD') {
          enqueueSnackbar({
            message: 'You have no XP to claim',
            variant: 'error',
          });
        } else if (e.response?.data?.error === 'TOO_MANY_REQUESTS') {
          enqueueSnackbar({
            message:
              'You are trying to claim your reward too many times. Please try again later',
            variant: 'error',
          });
        } else if (e.response?.data?.error === 'SCAMMER_USER') {
          enqueueSnackbar({
            message:
              'Your account has been flagged for suspicious activity and blocked from redeeming',
            variant: 'error',
          });
        } else if (e.response?.data?.error === 'WAITING_FOR_ADMIN_SET_LIMIT') {
          enqueueSnackbar({
            message: "We're replenishing the rewards pool. Stand by.",
            variant: 'error',
          });
        } else if (e.response?.data?.error === 'EXCEED_WEEKLY_LIMIT') {
          enqueueSnackbar({
            message: "We're replenishing the rewards pool. Stand by.",
            variant: 'error',
          });
        } else {
          enqueueSnackbar({
            message: 'An error occurred. Please try again later',
            variant: 'error',
          });
        }
        resolve(0);
      }
    });
  });
};

export const apiGetTransactions = async ({ skip, limit }) => {
  try {
    const response = await axios.post('/api/client/v1/user/transactions', {
      skip,
      limit,
    });
    return response.data.data;
  } catch {
    return [];
  }
};

export const apiMintNft = async () => {
  try {
    await axios.post('/api/client/v1/user/mint-nft');
    return true;
  } catch {
    return false;
  }
};

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ButtonBase,
  IconButton,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { Scaffold } from '../../components/layouts/Scaffold';
import { XpIndicator } from '../../components/core/XpIndicator';
import { XpGauge } from '../../components/core/XpGauge';
import { LockedAccountLvl5InfoModal } from '../../components/modals/AccountModals';
import { ArrowLeftIcon, ArrowRightIcon, LockIcon } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { apiGetL2EAdvancedSummary } from '../../apis/l2e';

import imgOilyL2E from '../../assets/onboarding/oily-l2e.png';
import imgOilyM2E from '../../assets/onboarding/oily-m2e.png';
import imgOilyR2EDisabled from '../../assets/onboarding/oily-r2e-disabled.png';

const ChallengeButton = styled(ButtonBase)(() => ({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  borderRadius: 16,
  backgroundColor: 'rgba(71, 89, 133, 0.20)',
  '& .MuiTouchRipple-root': {
    color: 'rgba(255, 255, 255, 0.3)',
  },
  marginBottom: 16,
}));

const ChallengeItem = ({
  imgSrc,
  title,
  subtitle,
  xp,
  maxXp,
  disabled,
  onClick,
}) => {
  const theme = useTheme();

  const [key, setKey] = useState(imgSrc + new Date().getTime());

  useEffect(() => {
    setKey(imgSrc + new Date().getTime());
  }, [imgSrc]);

  return (
    <ChallengeButton key={key} onClick={onClick}>
      <Stack direction="row" width="100%" p={1} pl={2}>
        <img
          src={imgSrc}
          alt="Oily"
          style={{
            width: 110,
            height: 113,
          }}
        />
        <Stack
          flex={1}
          ml={2}
          height={113}
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography pt={1}>
            <Typography
              component="span"
              color={disabled ? 'shadowBlue' : 'icterine'}
              fontSize={20}
              fontWeight={700}
              lineHeight={1.4}
            >
              {title}
            </Typography>
            <Typography
              component="span"
              color={disabled ? 'shadowBlue' : 'isabelline'}
              fontSize={20}
              fontWeight={700}
              lineHeight={1.4}
            >
              &nbsp;and Earn
            </Typography>
          </Typography>
          <Typography
            fontSize={16}
            lineHeight={1.75}
            color={disabled ? 'shadowBlue' : 'isabelline'}
          >
            {subtitle}
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            mb={1}
          >
            <XpGauge
              xp={xp}
              xpProps={{
                fontSize: 16,
                fontWeight: 500,
                color: disabled ? 'shadowBlue' : 'isabelline',
              }}
              max={maxXp}
              maxProps={{
                fontSize: 16,
                fontWeight: 500,
                color: disabled ? 'shadowBlue' : 'isabelline',
              }}
              iconProps={{
                width: 16,
                height: 16,
                color: disabled
                  ? theme.palette.shadowBlue
                  : theme.palette.icterine,
              }}
            />
            {disabled ? (
              <LockIcon color={theme.palette.shadowBlue} />
            ) : (
              <ArrowRightIcon color={theme.palette.isabelline} />
            )}
          </Stack>
        </Stack>
      </Stack>
    </ChallengeButton>
  );
};

export const ChallengesPage = () => {
  const navigate = useNavigate();

  const { l2eTotalXp, l2eTotalChapters } = useSelector(
    (state) => state.app.constants,
  );

  const { data: l2eAdvancedSummary } = useQuery({
    queryKey: ['l2e', 'advanced-summary'],
    queryFn: async () => {
      return await apiGetL2EAdvancedSummary();
    },
  });

  const [modals, setModals] = useState({
    lockedAccountLvl5: false,
  });

  const handleBack = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  const handleToggleModal = useCallback((name) => {
    setModals((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  }, []);

  const handleLearn = useCallback(() => {
    navigate('/l2e/chapters');
  }, [navigate]);

  const handleMap = useCallback(() => {
    navigate('/m2e/places');
  }, [navigate]);

  const handleRecycle = useCallback(() => {
    enqueueSnackbar({
      message: 'This feature is coming soon',
      variant: 'info',
    });
  }, []);

  return (
    <Scaffold bgcolor="royalBlueDark" height="100vh">
      <Scaffold.Content>
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={2}
            pt={2}
            pb={2}
            minHeight={56}
            sx={{
              position: 'fixed',
              top: 0,
              left: '50%',
              right: 0,
              maxWidth: 640,
              width: '100%',
              transform: 'translateX(-50%)',
              boxSizing: 'border-box',
              zIndex: 10,
            }}
          >
            <IconButton
              color="isabelline"
              size="small"
              sx={{ mr: 2 }}
              onClick={handleBack}
            >
              <ArrowLeftIcon />
            </IconButton>
            <XpIndicator />
          </Stack>
          <Box pt={10} px={4}>
            <Typography
              fontSize={48}
              fontWeight={500}
              lineHeight={1.16}
              color="icterine"
            >
              My
            </Typography>
            <Typography
              fontSize={48}
              fontWeight={500}
              lineHeight={1.16}
              color="isabelline"
            >
              Challenges
            </Typography>
          </Box>
          <Box px={3} mt={4}>
            <ChallengeItem
              imgSrc={imgOilyL2E}
              title="Learn"
              subtitle={`${l2eTotalChapters} Challenges`}
              xp={l2eAdvancedSummary?.earnedXp?.toFixed(0) ?? 0}
              maxXp={l2eTotalXp}
              onClick={handleLearn}
            />
            <ChallengeItem
              imgSrc={imgOilyM2E}
              title="Map"
              subtitle="Map unrecycled UCO"
              xp="Earn more"
              onClick={handleMap}
            />
            <ChallengeItem
              imgSrc={imgOilyR2EDisabled}
              title="Recycle"
              subtitle="Coming soon..."
              xp={0}
              maxXp={0}
              disabled
              onClick={handleRecycle}
            />
            <Box height={100} />
          </Box>
        </Box>
        <LockedAccountLvl5InfoModal
          open={modals.lockedAccountLvl5}
          onClose={() => handleToggleModal('lockedAccountLvl5')}
        />
      </Scaffold.Content>
    </Scaffold>
  );
};

ChallengeItem.propTypes = {
  imgSrc: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  xp: PropTypes.any,
  maxXp: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';

export const Scaffold = ({ children, ...props }) => {
  return (
    <Stack
      width="100%"
      height="100%"
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      {...props}
    >
      {children}
    </Stack>
  );
};
Scaffold.propTypes = {
  children: PropTypes.node,
};

const Header = ({ children, ...props }) => {
  return <Box {...props}>{children}</Box>;
};
Header.displayName = 'Scaffold.Header';
Header.propTypes = {
  children: PropTypes.node,
};
Scaffold.Header = Header;

const Content = ({ children, ...props }) => {
  return (
    <Box
      flex={1}
      sx={{
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
Content.displayName = 'Scaffold.Content';
Content.propTypes = {
  children: PropTypes.node,
};
Scaffold.Content = Content;

const Footer = ({ children, ...props }) => {
  return <Box {...props}>{children}</Box>;
};
Footer.displayName = 'Scaffold.Footer';
Footer.propTypes = {
  children: PropTypes.node,
};
Scaffold.Footer = Footer;

import React from 'react';
import {
  Box,
  ButtonBase,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ArrowRightIcon } from 'lucide-react';
import { useSelector } from 'react-redux';

export const ProgressSection = () => {
  const theme = useTheme();
  const level = useSelector((state) => state.profile.level);

  return (
    <Box px={1} mb={1}>
      <ButtonBase
        sx={{
          borderRadius: 4,
          bgcolor: 'beauBlue',
          px: 3,
          py: 4,
          width: '100%',
          boxSizing: 'border-box',
          display: 'block',
          textAlign: 'left',
        }}
        component={NavLink}
        to="/progress"
      >
        <Typography
          fontSize={20}
          fontWeight={500}
          lineHeight={1.4}
          color="raisinBlack"
        >
          Become an Oily Ambassador
        </Typography>
        <Box my={2}>
          <LinearProgress
            variant="determinate"
            value={((level ?? 0) / 10) * 100}
            sx={{
              height: 8,
              '& .MuiLinearProgress-bar': {
                background: theme.palette.green[600],
              },
            }}
          />
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography fontSize={32} lineHeight={1.25} color="royalBlueDark">
            View my progress
          </Typography>
          <IconButton color="royalBlueDark">
            <ArrowRightIcon />
          </IconButton>
        </Stack>
      </ButtonBase>
    </Box>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { Scaffold } from '../../../components/layouts/Scaffold';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Modal,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ArrowLeftIcon, InfoIcon, XIcon } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar } from 'notistack';
import { updatePlace } from '../../../redux/places.slice';
import * as yup from 'yup';

const schema = yup
  .object({
    waste_info: yup.string().required('Please fill waste info'),
    is_collected_by_wmo: yup.boolean().required(),
    is_company: yup.boolean().required(),
    is_compensating: yup.boolean().required(),
    collector_name: yup
      .string()
      .when(['is_collected_by_wmo'], (values, schema) => {
        return values[0]
          ? schema.required('Please fill collector name')
          : schema.optional();
      }),
    collector_email: yup
      .string()
      .email('Please fill valid email address')
      .optional(),
    collector_phone_number: yup.string().optional(),
  })
  .required();

export const M2EEditUcoDetailsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.placeId;

  const data = useSelector((state) => (id ? state.places.places[id] : null));

  const [modals, setModals] = useState({
    discard: false,
    info: false,
  });

  const { reset, control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      is_collected_by_wmo: false,
      is_company: false,
      is_compensating: false,
      collector_email: '',
      collector_name: '',
      waste_info: '',
    },
    resolver: yupResolver(schema),
  });

  const isCollectedByWmo = watch('is_collected_by_wmo');

  const handleToggleModal = useCallback((name) => {
    setModals((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  }, []);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleDiscard = useCallback(() => {
    handleToggleModal('discard');
  }, [handleToggleModal]);

  const handleSave = useCallback(
    (values) => {
      if (!id) {
        return;
      }

      if (
        values.is_collected_by_wmo &&
        !values.collector_email &&
        !values.collector_phone_number
      ) {
        enqueueSnackbar({
          message: "Please fill collector's email address or phone number",
          variant: 'error',
        });
      }

      dispatch(
        updatePlace({
          id: id,
          info: {
            is_collected_by_wmo: values.is_collected_by_wmo,
            is_company: values.is_company,
            is_compensating: values.is_compensating,
            collector_name: values.collector_name,
            collector_email: values.collector_email,
            collector_phone_number: values.collector_phone_number,
            waste_info: values.waste_info,
          },
        }),
      );
      navigate(-1);
    },
    [dispatch, navigate, id],
  );

  useEffect(() => {
    if (!id) {
      navigate('/m2e/places');
      return;
    }
    if (data) {
      reset({
        is_collected_by_wmo: data.info?.is_collected_by_wmo ?? false,
        is_company: data.info?.is_company ?? false,
        is_compensating: data.info?.is_compensating ?? false,
        waste_info: data.info?.waste_info ?? '',
        collector_name: data.info?.collector_name ?? '',
        collector_email: data.info?.collector_email ?? '',
        collector_phone_number: data.info?.collector_phone_number ?? '',
      });
    } else {
      navigate('/m2e/places');
    }
  }, [data, id, navigate, reset]);

  return (
    <Scaffold height="100vh" bgcolor="raisinBlack">
      <Scaffold.Header>
        <Stack
          direction="row"
          alignItems="center"
          px={2}
          pt={1}
          pb={2}
          minHeight={56}
        >
          <IconButton color="isabelline" size="small" onClick={handleDiscard}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography
            fontSize={14}
            color="isabelline"
            flex={1}
            mx={2}
            textAlign="center"
          >
            UCO Details
          </Typography>
          <IconButton color="isabelline" size="small" onClick={handleDiscard}>
            <XIcon />
          </IconButton>
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Box px={3} pt={6}>
          <Typography fontSize={32} lineHeight={1.25} color="isabelline" mb={4}>
            Waste production
          </Typography>
          <Controller
            control={control}
            name="waste_info"
            render={({ field, fieldState: { error } }) => (
              <TextField
                variant="standard"
                color="dark"
                label="QUANTITY OF UCO PRODUCED PER MONTH"
                placeholder="E.g.: 100 liters"
                required
                fullWidth
                {...field}
                error={!!error?.message}
              />
            )}
          />
        </Box>
        <Box px={3} pt={6}>
          <Stack direction="row" alignItems="flex-start" mb={4}>
            <Typography
              fontSize={32}
              lineHeight={1.25}
              color="isabelline"
              flex={1}
              mr={2}
            >
              Waste Management Organization (WMO)
            </Typography>
            <Box pt={1}>
              <IconButton
                color="isabelline"
                size="small"
                onClick={() => handleToggleModal('info')}
              >
                <InfoIcon />
              </IconButton>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2} mb={4}>
            <Typography
              fontSize={18}
              lineHeight={1.75}
              color="isabelline"
              flex={1}
            >
              Is the UCO being collected by someone?
            </Typography>
            <Box pt={0.5}>
              <Controller
                control={control}
                name="is_collected_by_wmo"
                render={({ field: { value, onChange } }) => (
                  <Switch
                    checked={value}
                    onChange={(newValue, checked) => {
                      if (!checked) {
                        setValue('collector_name', '');
                        setValue('collector_email', '');
                        setValue('collector_phone_number', '');
                      }
                      onChange(checked);
                    }}
                  />
                )}
              />
            </Box>
          </Stack>
        </Box>
        <Collapse in={isCollectedByWmo}>
          <Box px={3}>
            <Stack direction="row" spacing={2} mb={4}>
              <Typography
                fontSize={18}
                lineHeight={1.75}
                color="isabelline"
                flex={1}
              >
                Is it a company?
              </Typography>
              <Box pt={0.5}>
                <Controller
                  control={control}
                  name="is_company"
                  render={({ field: { value, onChange } }) => (
                    <Switch
                      checked={value}
                      onChange={(ev, checked) => onChange(checked)}
                    />
                  )}
                />
              </Box>
            </Stack>
            <Box mb={4}>
              <Controller
                control={control}
                name="collector_name"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    variant="standard"
                    color="dark"
                    label="COLLECTOR'S NAME"
                    placeholder="E.g.: John Smith"
                    required
                    fullWidth
                    {...field}
                    error={!!error?.message}
                  />
                )}
              />
            </Box>
            <Box mb={4}>
              <Controller
                control={control}
                name="collector_email"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    variant="standard"
                    color="dark"
                    label="COLLECTOR'S EMAIL ADDRESS"
                    placeholder="E.g.: email@domain.com"
                    required
                    fullWidth
                    {...field}
                    error={!!error?.message}
                  />
                )}
              />
            </Box>
            <Box mb={4}>
              <Controller
                control={control}
                name="collector_phone_number"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    variant="standard"
                    color="dark"
                    label="COLLECTOR'S PHONE NUMBER"
                    placeholder="E.g.: +351 555 258 856"
                    required
                    fullWidth
                    {...field}
                    error={!!error?.message}
                  />
                )}
              />
            </Box>
            <Typography
              mt={6}
              mb={4}
              fontSize={32}
              lineHeight={1.25}
              color="isabelline"
            >
              Compensations
            </Typography>
            <Stack direction="row" spacing={2} mb={4}>
              <Typography
                fontSize={18}
                lineHeight={1.75}
                color="isabelline"
                flex={1}
              >
                Is the restaurant being compensated for recycling its UCO
              </Typography>
              <Box pt={0.5}>
                <Controller
                  control={control}
                  name="is_compensating"
                  render={({ field: { value, onChange } }) => (
                    <Switch
                      checked={value}
                      onChange={(ev, checked) => onChange(checked)}
                    />
                  )}
                />
              </Box>
            </Stack>
          </Box>
        </Collapse>
        <Modal
          open={modals.discard}
          onClose={() => handleToggleModal('discard')}
        >
          <Box>
            <Typography fontSize={32} color="raisinBlack" lineHeight={1.25}>
              Inserted data will be discarded permanently.
            </Typography>
            <Stack mt={11} direction="row" spacing={1}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={() => handleToggleModal('discard')}
              >
                Close
              </Button>
              <Button variant="contained" color="danger" onClick={handleBack}>
                Discard changes
              </Button>
            </Stack>
          </Box>
        </Modal>
        <Modal open={modals.info} onClose={() => handleToggleModal('info')}>
          <Box>
            <Typography fontSize={32} color="raisinBlack" lineHeight={1.25}>
              What is a Waste Management Organization?
            </Typography>
            <Typography
              mt={3}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              Waste Management Organizations (WMO), are individuals or entities
              responsible for the systematic collection, transportation,
              processing, recycling, or disposal of waste materials.
            </Typography>
            <Stack mt={11} direction="row" spacing={1}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={() => handleToggleModal('info')}
              >
                Close
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Scaffold.Content>
      <Scaffold.Footer p={3}>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            color="isabelline"
            sx={{
              flex: 1,
            }}
            onClick={handleDiscard}
          >
            Discard
          </Button>
          <Button
            variant="contained"
            color="isabelline"
            sx={{
              flex: 2,
            }}
            onClick={handleSubmit(handleSave)}
          >
            Save
          </Button>
        </Stack>
      </Scaffold.Footer>
    </Scaffold>
  );
};

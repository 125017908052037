import React from 'react';
import { Box, Typography } from '@mui/material';
import { XpGauge } from '../../components/core/XpGauge';
import { useSelector } from 'react-redux';

export const BalanceSection = () => {
  const {
    xp: currentXp,
    lockedXp,
    ratio,
  } = useSelector((state) => state.profile);
  const xp = currentXp - lockedXp;

  return (
    <Box px={3} my={4}>
      <Typography
        fontSize={16}
        fontWeight={700}
        color="isabelline"
        lineHeight={1.75}
      >
        Current balance
      </Typography>
      <Box>
        <XpGauge
          xp={xp?.toFixed(0) ?? '0'}
          iconProps={{
            width: 24,
            height: 24,
          }}
          xpProps={{
            fontSize: 48,
            lineHeight: 1.1,
            color: 'white',
          }}
        />
      </Box>
      <Typography fontSize={14} lineHeight={1.7} color="beauBlue">
        ~ {(((xp ? xp : 0) * (ratio ?? 1)) / 100).toFixed(3)} B3TR
      </Typography>
      <Typography fontSize={10} lineHeight={1.6} color="shadowBlue">
        ~{(1 / (ratio ? ratio / 100 : 0.01)).toLocaleString()} XP per 1 B3TR
      </Typography>
    </Box>
  );
};

import React, { useCallback, useState } from 'react';
import Confetti from 'react-confetti';
import * as yup from 'yup';
import { Scaffold } from '../../components/layouts/Scaffold';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { EnergySymbol } from '../../components/core/EnergySymbol';
import { ArrowLeftIcon, XIcon } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar } from 'notistack';
import { apiUpdateProfile } from '../../apis/profile';

import imgOily from '../../assets/onboarding/oily-4.png';

const schema = yup
  .object({
    zealy_id: yup
      .string()
      .required(
        'Zealy ID is a mandatory field. Please enter your unique Zealy ID.',
      )
      .matches(
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
        'Zealy ID must be a valid UUID',
      ),
  })
  .required();

export const EditZealyIdPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const profile = useSelector((state) => state.profile);

  const [loading, setLoading] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const { handleSubmit, control } = useForm({
    defaultValues: {
      zealy_id: location.state?.zealy_id ?? profile.zealy_id ?? '',
    },
    resolver: yupResolver(schema),
  });

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSave = useCallback(async (values) => {
    setLoading(true);
    const result = await apiUpdateProfile({
      ...values,
    });
    if (result !== false) {
      if (result > 0) {
        setConfetti(true);
      } else {
        enqueueSnackbar({
          message: 'Update your profile info successfully',
          variant: 'success',
        });
      }
    }
    setLoading(false);
  }, []);

  const handleToggleModal = useCallback(() => {
    setConfetti((prev) => !prev);
  }, []);

  const handleEditTelegramId = useCallback(() => {
    if (profile.joinTelegramLink) {
      window.open(profile.joinTelegramLink, '_blank');
    }
    navigate(-1);
  }, [navigate, profile]);

  return (
    <Scaffold height="100vh" bgcolor="isabelline">
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={1}
          pb={2}
          minHeight={56}
        >
          <IconButton color="raisinBlack" size="small" onClick={handleBack}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography fontSize={14} color="raisinBlack" lineHeight={1}>
            Zealy ID
          </Typography>
          <IconButton color="raisinBlack" size="small" onClick={handleBack}>
            <XIcon />
          </IconButton>
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Box px={3} mt={5}>
          <Typography
            fontSize={32}
            lineHeight={1.25}
            color="raisinBlack"
            mb={4}
          >
            Identification
          </Typography>
          <Box mb={4}>
            <Controller
              control={control}
              name="zealy_id"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  variant="standard"
                  color="light"
                  label="ID"
                  placeholder="E.g.: 8e28623f-0769-50f-ac38-b6a959de4a85"
                  required
                  fullWidth
                  {...field}
                  error={!!error?.message}
                  helperText={error?.message}
                />
              )}
            />
          </Box>
        </Box>
        <Modal open={confetti} onClose={handleToggleModal}>
          <Box>
            <Confetti
              width={window.innerWidth - 16}
              height={window.innerHeight * 0.7}
            />
            <Stack direction="row" justifyContent="center">
              <img
                src={imgOily}
                alt="Oily"
                style={{
                  width: 120,
                  height: 131,
                }}
              />
            </Stack>
            <Box px={3} mt={1}>
              <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
                Congratulations
              </Typography>
              <Typography fontSize={32} lineHeight={1.25} color="shadowBlue">
                You&apos;ve earned
              </Typography>
              <Stack mt={2} direction="row" justifyContent="flex-end">
                <EnergySymbol
                  width={32}
                  height={32}
                  color={theme.palette.raisinBlack}
                  style={{
                    alignSelf: 'center',
                  }}
                />
                <Typography fontSize={100} lineHeight={1} color="raisinBlack">
                  20
                </Typography>
                <Typography
                  fontSize={32}
                  lineHeight={1.25}
                  color="raisinBlack"
                  mt={1}
                >
                  XP
                </Typography>
              </Stack>
              <Typography
                mt={2}
                fontSize={16}
                lineHeight={1.75}
                color="raisinBlack"
              >
                Join Telegram now to earn more XPs!
              </Typography>
            </Box>
            <Stack direction="row" spacing={2} mt={11}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={handleBack}
              >
                Not now
              </Button>
              <Button
                variant="contained"
                color="raisinBlack"
                onClick={handleEditTelegramId}
              >
                Join Telegram
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Scaffold.Content>
      <Scaffold.Footer>
        <Stack direction="row" spacing={2} p={3}>
          <Button
            variant="outlined"
            color="raisinBlack"
            sx={{
              flex: 1,
            }}
            onClick={handleBack}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="raisinBlack"
            sx={{
              flex: 2,
            }}
            loading={loading}
            onClick={handleSubmit(handleSave)}
          >
            Save
          </LoadingButton>
        </Stack>
      </Scaffold.Footer>
    </Scaffold>
  );
};

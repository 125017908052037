import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { AppBottomNavigationBar } from './AppLayout';

import imgBg from '../../assets/backgrounds/l2e-bg.png';

export const L2ELayout = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        position: 'relative',
        overflowX: 'hidden',
        overflowY: 'auto',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: `url(${imgBg})`,
          backgroundPosition: '0 0',
          backgroundSize: '100%',
          backgroundRepeat: 'no-repeat',
          opacity: 0.5,
          zIndex: -1,
        },
      }}
    >
      <Outlet />
      <AppBottomNavigationBar />
    </Box>
  );
};

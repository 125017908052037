import React from 'react';
import {
  Box,
  ButtonBase,
  Link,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { apiGetArticles } from '../../apis/articles';

export const ArticleSection = () => {
  const { data: articles, isLoading } = useQuery({
    queryKey: ['articles'],
    queryFn: async () => {
      return await apiGetArticles({
        limit: 10,
        skip: 0,
      });
    },
  });

  return (
    <Box bgcolor="raisinBlack" pt={5}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        px={3}
      >
        <Typography
          fontSize={24}
          fontWeight={500}
          lineHeight={1.3}
          color="isabelline"
        >
          Latest news
        </Typography>
        <Link
          fontSize={14}
          lineHeight={1.75}
          color="greyishBlue"
          component={NavLink}
          to="/articles"
        >
          View all
        </Link>
      </Stack>
      <Stack direction="column" spacing={1} mt={2} px={isLoading ? 3 : 0}>
        {isLoading
          ? Array(5)
              .fill(true)
              .map((item, index) => (
                <Skeleton
                  key={`loading-${index}`}
                  variant="rounded"
                  width="100%"
                  height={90}
                />
              ))
          : articles?.map((item, index) => (
              <ButtonBase
                key={`article-${index}`}
                sx={{
                  textAlign: 'left',
                  px: 3,
                  py: 1,
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
                component={NavLink}
                to={item.url}
                target="_blank"
              >
                <Box flex={1} mr={3}>
                  <Typography
                    fontSize={16}
                    lineHeight={1.5}
                    color="isabelline"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    fontSize={14}
                    lineHeight={1.25}
                    color="spanishGray"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                    }}
                  >
                    {item.description}
                  </Typography>
                </Box>
                <img
                  src={item.image_url ? item.image_url : '/logo512.png'}
                  alt="article"
                  style={{
                    aspectRatio: 1,
                    width: 80,
                    height: 80,
                    objectFit: 'cover',
                    borderRadius: 8,
                  }}
                />
              </ButtonBase>
            ))}
      </Stack>
      <Box height={200} />
    </Box>
  );
};

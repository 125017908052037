import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Scaffold } from '../../../components/layouts/Scaffold';
import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  Modal,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { XpIndicator } from '../../../components/core/XpIndicator';
import {
  ArrowLeftIcon,
  CheckIcon,
  ImageIcon,
  UploadCloudIcon,
} from 'lucide-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useGeolocated } from 'react-geolocated';
import { enqueueSnackbar } from 'notistack';
import { updateVariables } from '../../../redux/variables.slice';
import { insertPlace, updatePlace } from '../../../redux/places.slice';
import { apiUploadFile } from '../../../apis/files';
import { apiClaimPlace } from '../../../apis/m2e';
import dayjs from 'dayjs';

const haversineDistance = (coords1, coords2) => {
  const toRad = (value) => (value * Math.PI) / 180;

  const R = 6371;
  const dLat = toRad(coords2.latitude - coords1.latitude);
  const dLng = toRad(coords2.longitude - coords1.longitude);
  const lat1 = toRad(coords1.latitude);
  const lat2 = toRad(coords2.latitude);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance;
};

const SectionButton = ({
  title,
  subtitle,
  help,
  completed,
  showError,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <ButtonBase
      sx={{
        px: 2,
        py: 1.5,
        bgcolor:
          showError && !completed
            ? `${theme.palette.error[400]}30`
            : `${theme.palette.beauBlue}30`,
        borderRadius: 4,
        textAlign: 'left',
        alignItems: 'center',
      }}
      onClick={onClick}
    >
      <Box flex={1} mr={2}>
        <Typography fontSize={22} lineHeight={1.5} color="white">
          {title}
        </Typography>
        {subtitle ? (
          <Typography fontSize={14} lineHeight={1.7} color="beauBlue">
            {subtitle}
          </Typography>
        ) : null}
      </Box>
      {help ? (
        <Typography fontSize={10} color="shadowBlue" fontWeight={700} mr={2}>
          {help}
        </Typography>
      ) : null}
      <Stack
        justifyContent="center"
        alignItems="center"
        alignSelf="flex-start"
        sx={{
          borderRadius: 99,
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: completed
            ? 'icterine'
            : showError
              ? 'error.400'
              : 'purpleNavy',
          boxSizing: 'border-box',
          width: 32,
          height: 32,
          bgcolor: completed ? 'icterine' : 'transparent',
          color: 'raisinBlack',
        }}
      >
        {completed ? (
          <CheckIcon width={16} height={16} strokeWidth={4} />
        ) : null}
      </Stack>
    </ButtonBase>
  );
};

export const M2EPlaceDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { coords, isGeolocationEnabled, isGeolocationAvailable } =
    useGeolocated();

  const params = useParams();
  const id = params.placeId;

  const data = useSelector((state) => (id ? state.places.places[id] : null));
  const level = useSelector((state) => state.profile.level);

  const [uploading, setUploading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [status, setStatus] = useState({
    general: false,
    info: false,
  });
  const [error, setError] = useState(false);
  const [modals, setModals] = useState({
    close: false,
    info: false,
    validation: false,
    claimed: false,
    check: false,
    image: false,
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDropAccepted: async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setUploading(true);
        const urls = await apiUploadFile({
          key: 'places',
          files: [file],
        });
        setUploading(false);

        if (urls) {
          dispatch(
            updatePlace({
              id: data?.id,
              image: urls[0],
            }),
          );
        } else {
          enqueueSnackbar({
            message: 'Failed to upload image',
            variant: 'error',
          });
        }
      }
    },
    noClick: true,
    noKeyboard: true,
  });

  const { isM2EInfoModalDisabled, lastM2EInfoModalShownAt } = useSelector(
    (state) => state.variables,
  );

  const handleToggleModal = useCallback((name) => {
    setModals((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  }, []);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleUcoDetails = useCallback(() => {
    navigate(`/m2e/places/${id}/edit/uco`);
  }, [navigate, id]);

  const handleRestaurantDetails = useCallback(() => {
    navigate(`/m2e/places/${id}/edit/restaurant`);
  }, [navigate, id]);

  const handleGetDirections = useCallback(() => {
    if (!data?.latitude && !data?.longitude) {
      return;
    }
    if (isGeolocationEnabled && isGeolocationAvailable) {
      const origin = `${coords?.latitude},${coords?.longitude}`;
      const destination = `${data.latitude},${data.longitude}`;
      const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;
      window.open(url, '_blank');
    } else {
      enqueueSnackbar({
        message: 'Please enable geolocation service',
        variant: 'error',
      });
      return;
    }
  }, [
    data?.latitude,
    data?.longitude,
    isGeolocationAvailable,
    isGeolocationEnabled,
    coords,
  ]);

  const handleCheckIn = useCallback(() => {
    if (data?.checked || (!data?.latitude && !data?.longitude)) {
      return;
    }

    if (
      window.location.origin.includes('localhost') ||
      window.location.origin.includes('dev')
    ) {
      dispatch(
        updatePlace({
          id: data.id,
          checked: true,
        }),
      );
      enqueueSnackbar({
        message: 'Checked in successfully!',
        variant: 'success',
      });
      return;
    }

    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      const distance = haversineDistance(
        { latitude: data.latitude, longitude: data.longitude },
        { latitude: coords.latitude, longitude: coords.longitude },
      );

      if (distance < (level < 7 ? 1 : 5)) {
        dispatch(
          updatePlace({
            id: data.id,
            checked: true,
          }),
        );
        enqueueSnackbar({
          message: 'Checked in successfully!',
          variant: 'success',
        });
      } else {
        handleToggleModal('check');
      }
    } else {
      enqueueSnackbar({
        message: 'Please enable geolocation service',
        variant: 'error',
      });
      return;
    }
  }, [
    dispatch,
    data?.id,
    data?.checked,
    data?.latitude,
    data?.longitude,
    isGeolocationAvailable,
    isGeolocationEnabled,
    coords,
    level,
    handleToggleModal,
  ]);

  const handlePhotos = useCallback(() => {
    if (level > 5) {
      // todo
    } else {
      handleToggleModal('image');
    }
  }, [handleToggleModal, level]);

  const handleChangeInfoModalSetting = useCallback(
    (checked) => {
      dispatch(
        updateVariables({
          isM2EInfoModalDisabled: checked,
        }),
      );
    },
    [dispatch],
  );

  const handleClaim = useCallback(async () => {
    if (status.general && status.info && data?.checked) {
      setSaving(true);
      const result = await apiClaimPlace({
        place_id: data.id,
        contact_name: data.general.contact_name,
        contact_email: data.general.contact_email,
        contact_phone_number: data.general.contact_phone_number,
        charge_name: data.general.charge_name,
        charge_email: data.general.charge_email,
        photo_url: data.image,
        waste_info: data.info.waste_info,
        is_collected_by_wmo: data.info.is_collected_by_wmo,
        is_company: data.info.is_company,
        is_compensating: data.info.is_compensating,
        collector_name: data.info.collector_name,
        collector_email: data.info.collector_email,
        collector_phone_number: data.info.collector_phone_number,
        spoken_language: data.general.spoken_language,
      });
      setSaving(false);
      if (result) {
        navigate(`/m2e/places/${data.id}/contributed`, {
          replace: true,
        });
      } else {
        handleToggleModal('claimed');
      }
    } else {
      handleToggleModal('validation');
      setError(true);
    }
  }, [navigate, data, status, handleToggleModal]);

  useEffect(() => {
    if (
      dayjs().format('YYYY-MM-DD') !== lastM2EInfoModalShownAt &&
      !isM2EInfoModalDisabled
    ) {
      setModals((prev) => ({
        ...prev,
        info: true,
      }));
      dispatch(
        updateVariables({
          lastM2EInfoModalShownAt: dayjs().format('YYYY-MM-DD'),
        }),
      );
    }
  }, [isM2EInfoModalDisabled, lastM2EInfoModalShownAt, dispatch]);

  useEffect(() => {
    if (!id) {
      navigate('/m2e/places');
      return;
    }

    if (data) {
      setStatus((values) => {
        const result = { ...values };
        if (
          data.general?.contact_name &&
          data.general?.contact_email &&
          data.general?.charge_name &&
          data.general?.charge_email &&
          data.general?.spoken_language
        ) {
          result.general = true;
        } else {
          result.general = false;
        }
        if (data.info?.waste_info) {
          result.info = true;
        } else {
          result.info = false;
        }
        return result;
      });
      dispatch(
        updatePlace({
          initial_xp: location.state?.initial_xp ?? 25,
          total_xp: location.state?.total_xp ?? 150,
        }),
      );
    } else {
      if (
        location.state?.name &&
        location.state?.latitude !== undefined &&
        location.state?.longitude !== undefined
      ) {
        dispatch(
          insertPlace({
            id: id,
            externalId: location.state?.id ?? '',
            name: location.state.name,
            type: location.state?.type ?? 'restaurant',
            initial_xp: location.state?.initial_xp ?? 25,
            total_xp: location.state?.total_xp ?? 150,
            latitude: location.state.latitude,
            longitude: location.state.longitude,
            checked: false,
            image: undefined,
          }),
        );
      } else {
        navigate(-1);
      }
    }
  }, [data, id, dispatch, navigate, location.state]);

  return (
    <Scaffold height="100vh" bgcolor="raisinBlack">
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={2}
          pb={2}
          minHeight={56}
          sx={{
            position: 'fixed',
            top: 0,
            left: '50%',
            right: 0,
            maxWidth: 640,
            width: '100%',
            transform: 'translateX(-50%)',
            boxSizing: 'border-box',
            zIndex: 10,
          }}
        >
          <IconButton
            color="isabelline"
            size="small"
            sx={{ mr: 2 }}
            onClick={() => handleToggleModal('close')}
          >
            <ArrowLeftIcon />
          </IconButton>
          <XpIndicator />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Box height={64} />
        <Stack direction="row" justifyContent="center">
          <Stack
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              width: 160,
              height: 160,
              overflow: 'hidden',
              bgcolor: 'isabelline',
              color: 'spanishGray',
              borderRadius: 6,
              transform: 'rotate(5deg)',
              position: 'relative',
            }}
          >
            {data?.image ? (
              <img
                src={data.image}
                alt="restaurant"
                style={{
                  width: '100%',
                  height: '100%',
                  transform: 'rotate(-5deg) scale(110%)',
                }}
              />
            ) : (
              <ImageIcon />
            )}
          </Stack>
          {level > 5 ? (
            <Box
              sx={{
                position: 'absolute',
                top: 210,
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <LoadingButton
                variant="contained"
                color="beauBlue"
                size="small"
                endIcon={<UploadCloudIcon width={16} height={16} />}
                loading={uploading}
              >
                Upload logo
              </LoadingButton>
            </Box>
          ) : null}
        </Stack>
        <Box mt={7} px={3}>
          <Typography
            fontSize={26}
            lineHeight={1.5}
            color="white"
            textAlign="center"
          >
            {data?.name}
          </Typography>
          <Stack direction="row" justifyContent="center" spacing={3} mt={1}>
            <Typography fontSize={14} lineHeight={1.7} color="shadowBlue">
              Restaurant
            </Typography>
            <Link
              fontSize={14}
              lineHeight={1.7}
              color="shadowBlue"
              onClick={handleGetDirections}
            >
              Get directions
            </Link>
          </Stack>
        </Box>
        <Box px={2} mt={5}>
          <Stack direction="column" spacing={1}>
            <SectionButton
              title="UCO Details"
              subtitle="Person in charge, quantity produced, and who collects it"
              completed={status.info}
              showError={error}
              onClick={handleUcoDetails}
            />
            <SectionButton
              title="Restaurant Details"
              subtitle="Contacts, photos, language spoken"
              completed={status.general}
              showError={error}
              onClick={handleRestaurantDetails}
            />
            <SectionButton
              title="Check-in"
              completed={!!data?.checked}
              showError={error}
              onClick={handleCheckIn}
            />
            <SectionButton
              title="Photos"
              help={level > 5 ? null : 'Picture Uploads Restricted'}
              completed={!!data?.image}
              onClick={handlePhotos}
            />
          </Stack>
        </Box>
        <Modal open={modals.close} onClose={() => handleToggleModal('close')}>
          <Box>
            <Typography fontSize={32} color="raisinBlack" lineHeight={1.25}>
              The data will be kept for 30 days
            </Typography>
            <Typography
              mt={3}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              Hurry up, before someone claims this place before you.
            </Typography>
            <Box mt={11}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={handleBack}
              >
                Ok, I understand
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal open={modals.info} onClose={() => handleToggleModal('info')}>
          <Box>
            <Typography fontSize={32} color="raisinBlack" lineHeight={1.25}>
              How to Request
              <br />
              UCO Info
            </Typography>
            <Typography
              mt={3}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              <b>Tip:</b> Most of the restaurant details you can input by
              looking out the info online.But we really need your help to know
              if their Used Cooking Oil is being collected and by whom :)
              <br />
              <br />
              <b>
                1. First, introduce yourself and explain the challenge -
                something like this:
              </b>
              &nbsp; &quot;I&apos;m using Oily that rewards me for mapping
              restaurants that use cooking oil! The goal is to map every drop of
              used cooking oil in the world to know if it&apos;s being
              recycled!&quot;
              <br />
              <br />
              <b>2. Now strike with the questions!</b>
              &nbsp;If it&apos;s okay for you, I would like to know if you fry
              food here and if you recycle the Used Cooking Oil. Can I have a
              2min of your time?&quot;
              <br />& thank you for mapping!
            </Typography>
            <Box mt={4}>
              <FormControlLabel
                control={<Checkbox />}
                label="Don't show this again"
                onChange={(ev, checked) =>
                  handleChangeInfoModalSetting(checked)
                }
              />
            </Box>
            <Box mt={11}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={() => handleToggleModal('info')}
              >
                Ok, I understand
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={modals.validation}
          onClose={() => handleToggleModal('validation')}
        >
          <Box>
            <Typography fontSize={32} color="raisinBlack" lineHeight={1.25}>
              Please fill all the mandatory fields to claim a place
            </Typography>
            <Box mt={11}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={() => handleToggleModal('validation')}
              >
                Ok, I understand
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={modals.claimed}
          onClose={() => handleToggleModal('claimed')}
        >
          <Box>
            <Typography fontSize={32} color="raisinBlack" lineHeight={1.25}>
              Apologies. Someone claimed the place before.
            </Typography>
            <Typography
              mt={3}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              Don&apos;t give up; plenty of places remain to be claimed.
            </Typography>
            <Box mt={11}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={() => handleToggleModal('claimed')}
              >
                Ok, I understand
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal open={modals.check} onClose={() => handleToggleModal('check')}>
          <Box>
            <Typography fontSize={32} color="raisinBlack" lineHeight={1.25}>
              You&apos;re not at the location!
            </Typography>
            <Typography
              mt={3}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              Please click on “get directions”, go to the location and check-in
              the information.
            </Typography>
            <Stack mt={11} direction="row" spacing={1}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={() => handleToggleModal('check')}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="raisinBlack"
                onClick={handleGetDirections}
              >
                Get directions
              </Button>
            </Stack>
          </Box>
        </Modal>
        <Modal open={modals.image} onClose={() => handleToggleModal('image')}>
          <Box>
            <Typography fontSize={32} color="raisinBlack" lineHeight={1.25}>
              Picture uploads restricted at this level
            </Typography>
            <Typography
              mt={3}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              Picture uploads are not available at your current level. Please
              continue mapping textual information.
            </Typography>
            <Box mt={11}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={() => handleToggleModal('image')}
              >
                Ok, I understand
              </Button>
            </Box>
          </Box>
        </Modal>
      </Scaffold.Content>
      <Scaffold.Footer>
        <Stack p={3} direction="row" spacing={2}>
          <Button
            variant="contained"
            color="charcoal"
            sx={{
              flex: 1,
            }}
            onClick={() => handleToggleModal('close')}
          >
            Close
          </Button>
          <LoadingButton
            variant="contained"
            color="icterine"
            sx={{
              flex: 2,
            }}
            loading={saving}
            onClick={handleClaim}
          >
            Claim
          </LoadingButton>
        </Stack>
      </Scaffold.Footer>
    </Scaffold>
  );
};

SectionButton.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  completed: PropTypes.bool,
  showError: PropTypes.bool,
  onClick: PropTypes.func,
  help: PropTypes.string,
};

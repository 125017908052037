import React, { useCallback } from 'react';
import {
  Box,
  ButtonBase,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { EnergySymbol } from '../../components/core/EnergySymbol';
import { ArrowRightIcon } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { apiGetChapters, apiGetDemoChapters } from '../../apis/l2e';
import { XpGauge } from '../../components/core/XpGauge';

export const RecommendedActionSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { isAnonymousLogin } = useSelector((state) => state.auth);
  const { username, gender, age, country, city } = useSelector(
    (state) => state.profile,
  );

  const { data, isLoading } = useQuery({
    queryKey: ['l2e', 'checkpoint'],
    queryFn: async () => {
      const chapters = isAnonymousLogin
        ? await apiGetDemoChapters()
        : await apiGetChapters();

      for (let i = 0; i < chapters.length; i++) {
        const chapter = chapters[i];
        if (chapter.progress < 100) {
          return {
            index: i,
            type:
              chapter.progress > 0
                ? 'Resume challenge:'
                : 'Your next challenge:',
            title: chapter.title,
            lessons:
              chapter.progress === 0
                ? chapter.total_lessons
                : `${Math.round((chapter.total_lessons * chapter.progress) / 100)}/${chapter.total_lessons}`,
            userXp: chapter.user_xp,
            totalXp: chapter.total_xp,
          };
        }
      }

      return null;
    },
  });

  const handleClick = useCallback(() => {
    if (!username || !gender || !age || !country || !city) {
      navigate('/profile');
      return;
    }

    if (data) {
      navigate(`/l2e/chapters/${data.index}`);
    }
  }, [navigate, username, gender, age, country, city, data]);

  if (!isLoading && !data) {
    return null;
  }

  return (
    <Box px={1} mb={1}>
      <ButtonBase
        sx={{
          borderRadius: 4,
          bgcolor: 'yellow.500',
          display: 'block',
          width: '100%',
          boxSizing: 'border-box',
          px: 3,
          py: 4,
          textAlign: 'left',
        }}
        onClick={handleClick}
      >
        {!username || !gender || !age || !country || !city ? (
          <>
            <Typography
              fontSize={24}
              fontWeight={500}
              lineHeight={1.3}
              color="purpleNavy"
            >
              Your next challenge:
            </Typography>
            <Typography
              fontSize={40}
              fontWeight={500}
              lineHeight={1.3}
              color="royalBlueDark"
            >
              Complete your Profile
            </Typography>
            <Stack direction="row" justifyContent="flex-end">
              <EnergySymbol
                width={32}
                height={32}
                color={theme.palette.raisinBlack}
                style={{
                  alignSelf: 'center',
                }}
              />
              <Typography fontSize={100} lineHeight={1} color="raisinBlack">
                90
              </Typography>
              <Typography
                fontSize={32}
                lineHeight={1.25}
                color="raisinBlack"
                mt={1}
              >
                XP
              </Typography>
            </Stack>
            <Typography
              fontSize={24}
              fontWeight={500}
              lineHeight={1.3}
              color="purpleNavy"
            >
              Start building your Oily journey with this first step
            </Typography>
            <Stack mt={3} direction="row" alignItems="center" spacing={2}>
              <Typography fontSize={32} lineHeight={1.25} color="royalBlueDark">
                Start now
              </Typography>
              <ArrowRightIcon color={theme.palette.royalBlueDark} />
            </Stack>
          </>
        ) : (
          <>
            {isLoading ? (
              <>
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={24}
                  sx={{
                    bgcolor: `${theme.palette.raisinBlack}40`,
                  }}
                />
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={120}
                  sx={{
                    mt: 2,
                    bgcolor: `${theme.palette.raisinBlack}40`,
                  }}
                />
              </>
            ) : (
              <>
                <Typography
                  fontSize={24}
                  fontWeight={500}
                  lineHeight={1.3}
                  color="purpleNavy"
                >
                  {data?.type}
                </Typography>
                <Typography
                  fontSize={40}
                  fontWeight={500}
                  lineHeight={1.3}
                  color="royalBlueDark"
                >
                  {data?.title}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography fontSize={16} lineHeight={1.75} color="pennBlue">
                    {data?.lessons} Lessons
                  </Typography>
                  <Typography fontSize={16} lineHeight={1.75} color="pennBlue">
                    -
                  </Typography>
                  <XpGauge
                    xp={data?.userXp}
                    max={data?.totalXp}
                    iconProps={{
                      width: 16,
                      height: 16,
                      color: theme.palette.pennBlue,
                    }}
                    xpProps={{
                      fontSize: 16,
                      lineHeight: 1.75,
                      color: 'pennBlue',
                    }}
                    maxProps={{
                      fontSize: 16,
                      lineHeight: 1.75,
                      color: 'pennBlue',
                    }}
                  />
                </Stack>
                <Stack mt={3} direction="row" alignItems="center" spacing={2}>
                  <Typography
                    fontSize={32}
                    lineHeight={1.25}
                    color="royalBlueDark"
                  >
                    Start now
                  </Typography>
                  <ArrowRightIcon color={theme.palette.royalBlueDark} />
                </Stack>
              </>
            )}
          </>
        )}
      </ButtonBase>
    </Box>
  );
};

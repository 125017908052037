import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  username: null,
  avatar: null,
  address: null,
  level: 0,
  chain: null,
  scammed: false,
  disabled: false,
  telegramId: null,
  zealyId: null,
  joinedTelegram: false,
  joinTelegramLink: null,
  followedTwitter: false,
  xp: 0,
  mintNftXp: 0,
  totalXp: 0,
  reward: null,
  country: null,
  city: null,
  gender: null,
  referralCode: null,
  age: null,
  m2eLocked: false,
  ucoOilToCook: null,
  ucoRecycling: null,
  ucoRecyclingAmount: null,
  ucoProductionPeriod: null,
  ratio: 1,
  tgeOptIn: false,
  mintedNftLevel: 0,
  lockedXp: 0,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    setProfile(state, action) {
      return action.payload;
    },
    updateProfile(state, action) {
      return { ...state, ...action.payload };
    },
    removeProfile() {
      return initialState;
    },
  },
});

export const { setProfile, updateProfile, removeProfile } =
  profileSlice.actions;

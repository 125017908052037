import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, IconButton, Stack } from '@mui/material';
import { Scaffold } from '../../../components/layouts/Scaffold';
import { OptionButton } from './M2EPlacesPage';
import { XIcon } from 'lucide-react';
import { useSelector } from 'react-redux';

export const M2EOptionsDrawer = ({
  open,
  onClose,
  onAddRestaurant,
  onAddWmo,
}) => {
  const { m2eCreatePlaceXp, m2eCreateWmoXp } = useSelector(
    (state) => state.app.constants,
  );

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Scaffold width="100vw" height="100vh" bgcolor="raisinBlack">
        <Scaffold.Header>
          <Stack direction="row" justifyContent="flex-end" p={3}>
            <IconButton color="isabelline" size="small" onClick={onClose}>
              <XIcon />
            </IconButton>
          </Stack>
        </Scaffold.Header>
        <Scaffold.Content>
          <Stack direction="column" px={3} mt={3} spacing={2}>
            <OptionButton
              title="Add a Restaurant to Map"
              subtitle="Helps us map the unmapped restaurants"
              xp={m2eCreatePlaceXp}
              onClick={onAddRestaurant}
            />
            <OptionButton
              title="Add a WMO"
              subtitle="Gives us a direct contact of a WMO"
              xp={m2eCreateWmoXp}
              onClick={onAddWmo}
            />
          </Stack>
        </Scaffold.Content>
      </Scaffold>
    </Drawer>
  );
};

M2EOptionsDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAddRestaurant: PropTypes.func,
  onAddWmo: PropTypes.func,
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import imgOily from '../../assets/onboarding/oily-8.png';

export const ClaimRestaurantDisabledModal = ({ open, onClose }) => {
  const navigate = useNavigate();

  const { username } = useSelector((state) => state.profile);

  const handleAction = useCallback(() => {
    onClose();
    navigate('/l2e/chapters');
  }, [navigate, onClose]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Stack direction="row" justifyContent="center">
          <img src={imgOily} alt="Oily" />
        </Stack>
        <Box mt={1}>
          <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
            {username ? username : 'Hi'},
          </Typography>
          <Typography fontSize={32} lineHeight={1.25} color="shadowBlue">
            This feature is locked!
          </Typography>
          <Typography mt={2} fontSize={16} lineHeight={1.75} color="charcoal">
            At your current level, claiming restaurants isn&apos;t available
            yet. Reach Level 5 to unlock this feature and expand your
            contributions. Keep completing challenges and earning XP to level up
            and access more exciting opportunities!
          </Typography>
        </Box>
        <Box mt={10}>
          <Button
            variant="contained"
            color="raisinBlack"
            onClick={handleAction}
          >
            Ok, take me there
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ClaimRestaurantDisabledModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Scaffold } from '../layouts/Scaffold';
import { XIcon } from 'lucide-react';

import imgOily from '../../assets/onboarding/oily-level-10.png';
import imgBadge from '../../assets/badges/badge-lvl10.png';

export const LevelUp10Dialog = ({ open, onClose }) => {
  const handleContinue = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Scaffold bgcolor="levels.10.bgcolor" height="100vh">
        <Confetti recycle={false} numberOfPieces={1000} />
        <Scaffold.Header>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={2}
            pt={1}
            pb={2}
            minHeight={56}
          >
            <Box width={26} />
            <Typography fontSize={14} color="isabelline" lineHeight={1}>
              Level Completed
            </Typography>
            <IconButton color="isabelline" size="small" onClick={onClose}>
              <XIcon />
            </IconButton>
          </Stack>
        </Scaffold.Header>
        <Scaffold.Content>
          <Stack direction="row" justifyContent="center">
            <img src={imgOily} alt="Oily" />
          </Stack>
          <Box px={3}>
            <Typography
              mt={3}
              fontSize={32}
              lineHeight={1.25}
              color="isabelline"
            >
              You Did It!
              <br />
              You&apos;ve Reached
            </Typography>
            <Typography fontSize={32} lineHeight={1.25} color="beauBlue">
              Level 10: Oily Ambassador
            </Typography>
            <Stack direction="row" alignItems="flex-start" spacing={2} mt={2}>
              <img
                src={imgBadge}
                alt="Badge"
                style={{
                  width: 72,
                  height: 72,
                }}
              />
              <Typography
                flex={1}
                fontSize={20}
                lineHeight={1.4}
                color="isabelline"
              >
                Your precision and expertise are unmatched—you&apos;re setting
                the standard for mapping excellence!
              </Typography>
            </Stack>

            <Typography
              mt={4}
              fontSize={20}
              fontWeight={500}
              lineHeight={1.4}
              color="beauBlue"
            >
              Your Achievements:
            </Typography>
            <Typography
              mt={1}
              fontSize={18}
              fontWeight={700}
              lineHeight={1.75}
              color="isabelline"
            >
              Mapped
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="isabelline"
              component="div"
            >
              <ul>
                <li>150+ verified locations</li>
              </ul>
            </Typography>
            <Typography
              mt={1}
              fontSize={18}
              fontWeight={700}
              lineHeight={1.75}
              color="isabelline"
            >
              Unlocked
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="isabelline"
              component="div"
            >
              <ul>
                <li>
                  <b>Exclusive rewards</b> for posting on socials—show your
                  impact on X or LinkedIn and tag us to gain recognition
                </li>
                <li>
                  Ability to <b>redeem your Oily Ambassador Kit</b> (includes
                  stickers, sweaters, aprons, caps, and more)
                </li>
                <li>XP multiplier: 1.3</li>
              </ul>
            </Typography>
            <Typography
              mt={4}
              fontSize={20}
              fontWeight={500}
              lineHeight={1.4}
              color="beauBlue"
            >
              What&apos;s Next on Your Path?
            </Typography>
            <Typography
              mt={1}
              fontSize={16}
              lineHeight={1.75}
              color="isabelline"
            >
              <b>Your journey doesn&nbsp;t end here.</b>
              <br />
              Continue mapping, inspiring others, and making a sustainable
              impact with Oily. The future is in your hands!
            </Typography>
          </Box>
          <Box mt={5} px={4} pb={9}>
            <Button
              variant="contained"
              color="icterine"
              fullWidth
              onClick={handleContinue}
            >
              Continue
            </Button>
          </Box>
        </Scaffold.Content>
      </Scaffold>
    </Dialog>
  );
};

LevelUp10Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

import React, { useCallback } from 'react';
import Confetti from 'react-confetti';
import { Button, Stack, Box, IconButton, Typography } from '@mui/material';
import { Scaffold } from '../../components/layouts/Scaffold';
import { XpIndicator } from '../../components/core/XpIndicator';
import { EnergySymbol } from '../../components/core/EnergySymbol';
import { ArrowLeftIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import imgOily from '../../assets/onboarding/oily-4.png';

export const CongratulationsPage = () => {
  const navigate = useNavigate();

  const { xp } = useSelector((state) => state.profile);

  const handleContinue = useCallback(() => {
    navigate('/onboarding/settings', {
      replace: true,
    });
  }, [navigate]);

  return (
    <Scaffold>
      <Confetti recycle={false} numberOfPieces={1000} />
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={1}
          minHeight={56}
          pt={1}
        >
          <IconButton color="isabelline" sx={{ mr: 2 }}>
            <ArrowLeftIcon />
          </IconButton>
          <XpIndicator />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Stack
          direction="row"
          justifyContent="center"
          bgcolor="#44547c"
          p={2}
          mt={1}
        >
          <img
            src={imgOily}
            alt="oily"
            style={{
              width: 140,
              height: 'auto',
            }}
          />
        </Stack>
        <Box p={3}>
          <Typography fontSize={32} lineHeight={1.25} color="isabelline">
            Congratulations
          </Typography>
          <Typography fontSize={32} lineHeight={1.25} color="beauBlue">
            You&apos;ve earned
          </Typography>
          <Stack direction="row" justifyContent="flex-end">
            <EnergySymbol
              width={32}
              height={32}
              style={{
                alignSelf: 'center',
              }}
            />
            <Typography fontSize={100} lineHeight={1} color="isabelline">
              {xp}
            </Typography>
            <Typography
              fontSize={32}
              lineHeight={1.25}
              color="isabelline"
              mt={1}
            >
              XP
            </Typography>
          </Stack>
          <Typography fontSize={16} lineHeight={1.75} color="isabelline" mt={2}>
            Continue earning more XPs by completing the challenges ahead.
          </Typography>
        </Box>
      </Scaffold.Content>
      <Scaffold.Footer>
        <Stack direction="row" justifyContent="center" px={3} pb={3}>
          <Button variant="contained" color="icterine" onClick={handleContinue}>
            Continue
          </Button>
        </Stack>
      </Scaffold.Footer>
    </Scaffold>
  );
};

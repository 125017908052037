import React, { useCallback, useState } from 'react';
import { Scaffold } from '../../../components/layouts/Scaffold';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { ArrowLeftIcon } from 'lucide-react';
import { XpIndicator } from '../../../components/core/XpIndicator';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import {
  apiGetChapters,
  apiGetContents,
  apiGetDemoChapters,
  apiGetDemoContents,
  apiGetDemoLessons,
  apiGetLessons,
} from '../../../apis/l2e';

export const L2ELessonContentsPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const chapterIndex = params?.chapterIndex
    ? parseInt(params.chapterIndex)
    : null;
  const lessonIndex = params?.lessonIndex ? parseInt(params.lessonIndex) : null;

  const isAnonymousLogin = useSelector((state) => state.auth.isAnonymousLogin);

  const { data: chapter } = useQuery({
    queryKey: ['l2e', 'chapters', chapterIndex],
    queryFn: async () => {
      const data = isAnonymousLogin
        ? await apiGetDemoChapters()
        : await apiGetChapters();
      if (data && data.length > chapterIndex) {
        return data[chapterIndex];
      } else {
        return null;
      }
    },
  });

  const { data: lesson } = useQuery({
    queryKey: ['l2e', 'chapters', chapterIndex, 'lessons', lessonIndex],
    queryFn: async () => {
      const data = isAnonymousLogin
        ? await apiGetDemoLessons(chapter._id)
        : await apiGetLessons(chapter._id);
      if (data && data.length > lessonIndex) {
        return data[lessonIndex];
      } else {
        return null;
      }
    },
    enabled: !!(chapter && chapter._id),
  });

  const { data: contents } = useQuery({
    queryKey: [
      'l2e',
      'chapters',
      chapterIndex,
      'lessons',
      lessonIndex,
      'contents',
    ],
    queryFn: async () => {
      const data = isAnonymousLogin
        ? await apiGetDemoContents(lesson._id)
        : await apiGetContents(lesson._id);
      return data;
    },
    enabled: !!(lesson && lesson._id),
  });

  const [page, setPage] = useState(0);
  const [modal, setModal] = useState(false);

  const handleBack = useCallback(() => {
    navigate(`/l2e/chapters/${chapterIndex}`, {
      replace: true,
    });
  }, [navigate, chapterIndex]);

  const handleContinue = useCallback(() => {
    if (page < contents.length - 1) {
      setPage(page + 1);
    } else {
      setModal(true);
    }
  }, [page, contents]);

  const handleConfirm = useCallback(() => {
    setModal(false);
    navigate('../questions', {
      replace: true,
    });
  }, [navigate]);

  const handleStay = useCallback(() => {
    setModal(false);
  }, []);

  return (
    <Scaffold bgcolor="raisinBlack" height="100vh">
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={2}
          pb={2}
          minHeight={56}
          sx={{
            position: 'fixed',
            top: 0,
            left: '50%',
            right: 0,
            maxWidth: 640,
            width: '100%',
            transform: 'translateX(-50%)',
            boxSizing: 'border-box',
            zIndex: 10,
          }}
        >
          <IconButton
            color="isabelline"
            size="small"
            sx={{ mr: 2 }}
            onClick={handleBack}
          >
            <ArrowLeftIcon />
          </IconButton>
          <XpIndicator />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Box height={88} />
        {contents ? (
          <>
            {contents[page].image ? (
              <Box
                sx={{
                  '& img': {
                    width: '100%',
                    height: 'auto',
                    borderRadius: 2,
                  },
                }}
                mb={3}
                px={3}
              >
                <img alt="page description" src={contents[page].image} />
              </Box>
            ) : null}
            <Box px={3}>
              <Typography fontSize={32} lineHeight={1.25} color="white">
                {contents[page].title}
              </Typography>
              <Typography
                mt={2}
                fontSize={16}
                lineHeight={1.75}
                color="isabelline"
                component="div"
                dangerouslySetInnerHTML={{
                  __html: contents[page].content,
                }}
                sx={{
                  '& p': {
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
                  },
                }}
              />
            </Box>
          </>
        ) : (
          <Box px={3}>
            <Skeleton variant="rounded" height={32} />
            <Skeleton
              variant="rounded"
              height={32}
              width="60%"
              sx={{
                mt: 1,
              }}
            />
            <Skeleton
              variant="rounded"
              height="40vh"
              sx={{
                mt: 2,
              }}
            />
            <Skeleton
              variant="rounded"
              height="40vh"
              sx={{
                mt: 2,
              }}
            />
          </Box>
        )}
      </Scaffold.Content>
      <Scaffold.Footer>
        <Stack direction="row" pb={2} px={3} pt={2} spacing={2}>
          <Button
            variant="contained"
            color="charcoal"
            sx={{ flex: 1 }}
            onClick={handleBack}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="icterine"
            sx={{ flex: 2 }}
            onClick={handleContinue}
          >
            Continue
          </Button>
        </Stack>
      </Scaffold.Footer>
      <Modal open={modal} onClose={handleStay}>
        <Box>
          <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
            Stay Sharp, Score Big!
          </Typography>
          <Typography
            fontSize={16}
            lineHeight={1.75}
            color="raisinBlack"
            mt={3}
          >
            Every question counts. Incorrect answers will result in fewer
            points! Maximize your score by answering carefully.
            <br />
            <br />
            <b>Ready to prove your knowledge?</b>
          </Typography>
          <Box mt={8}>
            <Button
              variant="outlined"
              color="raisinBlack"
              onClick={handleConfirm}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Modal>
    </Scaffold>
  );
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  isAnonymousLogin: false,

  id: null,
  username: null,
  avatar: null,
  accessToken: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setAuth(state, action) {
      return action.payload;
    },
    updateAuth(state, action) {
      return { ...state, ...action.payload };
    },
    removeAuth() {
      return initialState;
    },
  },
});

export const { setAuth, updateAuth, removeAuth } = authSlice.actions;

/* eslint-disable no-undef */
import React, { useCallback, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { Scaffold } from '../../components/layouts/Scaffold';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import {
  LoadingButton,
  Timeline as MuiTimeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineConnectorClasses,
  timelineContentClasses,
  timelineDotClasses,
  timelineItemClasses,
} from '@mui/lab';
import { EnergySymbol } from '../../components/core/EnergySymbol';
import {
  ArrowLeftIcon,
  CheckIcon,
  CloudUploadIcon,
  CopyIcon,
  DotIcon,
  EditIcon,
  ExternalLinkIcon,
  ImageIcon,
  InfoIcon,
  XIcon,
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { getCountryData } from 'countries-list';
import { apiUploadFile } from '../../apis/files';
import { apiGetProfile, apiUpdateProfile } from '../../apis/profile';

const Timeline = styled(MuiTimeline)(({ theme }) => ({
  padding: 0,
  paddingInlineStart: 0,
  marginBlockStart: 0,
  marginBlockEnd: 0,

  [`& .${timelineItemClasses.root}:before`]: {
    flex: 0,
    padding: 0,
  },
  [`& .${timelineDotClasses.root}`]: {
    width: 40,
    height: 40,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,

    [`&.${timelineDotClasses.filled}`]: {
      backgroundColor: theme.palette.green[600],
      color: theme.palette.white,
    },
    [`&.${timelineDotClasses.outlined}`]: {
      backgroundColor: theme.palette.white,
      color: theme.palette.green[600],
      borderWidth: 2,
      borderStyle: 'solid',
    },
    [`&.${timelineDotClasses.outlinedPrimary}`]: {
      borderColor: theme.palette.green[600],
    },
    [`&.${timelineDotClasses.outlinedGrey}`]: {
      borderColor: theme.palette.spanishGray,
    },
  },
  [`& .${timelineConnectorClasses.root}`]: {
    width: 3,
    height: 50,
    backgroundColor: theme.palette.spanishGray,
    '&.success': {
      backgroundColor: theme.palette.green[600],
    },
  },
  [`& .${timelineContentClasses.root}`]: {
    paddingTop: 0,
    '& > .MuiBox-root': {
      '& > .MuiStack-root': {
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& > .MuiTypography-root:nth-child(1)': {
          fontSize: 16,
          fontWeight: 700,
          lineHeight: 1.75,
          color: theme.palette.spanishGray,
        },
        '& > svg': {
          width: 16,
          height: 16,
          color: theme.palette.raisinBlack,
          marginLeft: 12,
        },
        '& > .MuiTypography-root:nth-child(3)': {
          fontSize: 14,
          lineHeight: 1.75,
          color: theme.palette.spanishGray,
        },
        '& > .MuiIconButton-root': {
          marginLeft: 12,
          color: theme.palette.raisinBlack,
        },
      },
      '& > .MuiBox-root': {
        '& > .MuiTypography-root': {
          fontSize: 14,
          lineHeight: 1.75,
          color: theme.palette.spanishGray,
        },
      },
    },
    '&.done > .MuiBox-root': {
      '& > .MuiStack-root': {
        '& > .MuiTypography-root:nth-child(1)': {
          color: theme.palette.green[600],
        },
      },
      '& > .MuiBox-root': {
        '& > .MuiTypography-root': {
          color: theme.palette.raisinBlack,
        },
      },
    },
    '&.ongoing > .MuiBox-root': {
      '& > .MuiStack-root': {
        '& > .MuiTypography-root:nth-child(1)': {
          color: theme.palette.green[600],
        },
      },
    },
  },
}));

export const ProfilePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    id,
    username,
    country,
    avatar,
    referralCode,
    age,
    gender,
    city,
    zealyId,
    level,
    joinedTelegram,
    joinTelegramLink,
    followedTwitter,
  } = useSelector((state) => state.profile);
  const { account } = useSelector((state) => state.wallet);

  useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      return await apiGetProfile();
    },
    refetchInterval: 10000,
  });

  const [uploading, setUploading] = useState(false);
  const [step, setStep] = useState(1);
  const [modals, setModals] = useState({
    referralEnabled: false,
    referralDisabled: false,
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDropAccepted: async (acceptedFiles) => {
      if (!acceptedFiles || acceptedFiles.length === 0) {
        return;
      }

      setUploading(true);
      const file = acceptedFiles[0];
      const urls = await apiUploadFile({
        key: 'avatars',
        files: [file],
      });
      if (urls) {
        const [url] = urls;
        const result = await apiUpdateProfile({
          profile_image: url,
        });
        if (result !== false) {
          await apiGetProfile();
          setUploading(false);
          enqueueSnackbar({
            message: 'Updated your profile image successfully',
            variant: 'success',
          });
        } else {
          setUploading(false);
          enqueueSnackbar({
            message: 'Failed to update your profile',
            variant: 'error',
          });
        }
      } else {
        setUploading(false);
        enqueueSnackbar({
          message: 'Failed to upload the selected image',
          variant: 'error',
        });
      }
    },
  });

  const handleToggleModal = useCallback((name) => {
    setModals((values) => ({
      ...values,
      [name]: !values[name],
    }));
  }, []);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleEditProfile = useCallback(() => {
    navigate('/profile/identity');
  }, [navigate]);

  const handleEditZealyId = useCallback(() => {
    navigate('/profile/zealy-id');
  }, [navigate]);

  const handleEditTelegramId = useCallback(() => {
    if (joinTelegramLink) {
      window.open(joinTelegramLink, '_blank');
    } else {
      enqueueSnackbar({
        message:
          'Failed to open the Telegram group link, please try again later',
        variant: 'error',
      });
    }
  }, [joinTelegramLink]);

  const handleCopyWallet = useCallback(() => {
    if (account) {
      copy(account);
      enqueueSnackbar({
        message: 'Your wallet address copied to clipboard',
        variant: 'success',
      });
    }
  }, [account]);

  const handleCopyReferral = useCallback(() => {
    if (referralCode) {
      copy(`${window.location.origin}/referral?code=${referralCode}`);
      enqueueSnackbar({
        message: 'Your referral URL copied to clipboard',
        variant: 'success',
      });
    }
  }, [referralCode]);

  useEffect(() => {
    if (!username || !age || !city || !gender || !country) {
      setStep(1);
      return;
    }
    if (!zealyId) {
      setStep(2);
      return;
    }
    if (!joinedTelegram) {
      setStep(3);
      return;
    }
    if (!followedTwitter) {
      setStep(4);
      return;
    }
    setStep(5);
  }, [
    username,
    age,
    city,
    gender,
    country,
    zealyId,
    joinedTelegram,
    followedTwitter,
  ]);

  return (
    <Scaffold height="100vh" bgcolor="isabelline">
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={1}
          pb={2}
          minHeight={56}
        >
          <IconButton color="raisinBlack" size="small" onClick={handleBack}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography fontSize={14} color="raisinBlack" lineHeight={1}>
            Settings
          </Typography>
          <IconButton color="raisinBlack" size="small" onClick={handleBack}>
            <XIcon />
          </IconButton>
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Stack
          mt={4}
          direction="row"
          justifyContent="center"
          position="relative"
        >
          <Box
            position="relative"
            width={160}
            height={160}
            borderRadius="100%"
            bgcolor="royalBlueDark"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="isabelline"
            overflow="hidden"
            boxSizing="border-box"
          >
            {avatar ? (
              <img src={avatar} alt="profile" width="100%" height="100%" />
            ) : (
              <ImageIcon />
            )}
          </Box>
          <Box position="absolute" bottom={-10} {...getRootProps()}>
            <input {...getInputProps()} />
            <LoadingButton
              variant="contained"
              color="beauBlue"
              size="small"
              loading={uploading}
              endIcon={<CloudUploadIcon />}
            >
              Upload image
            </LoadingButton>
          </Box>
        </Stack>
        <Box mt={4} px={3}>
          <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
            Hi, {username}
          </Typography>
          <Typography fontSize={18} lineHeight={1.3} color="raisinBlack" mt={1}>
            Complete your profile and earn
          </Typography>
          <Stack mt={1} direction="row" alignItems="center">
            <EnergySymbol
              width={16}
              height={16}
              color={theme.palette.raisinBlack}
            />
            <Typography fontSize={18} lineHeight={1.3} color="raisinBlack">
              60 XP:
            </Typography>
          </Stack>
        </Box>
        <Box mt={4} px={3}>
          <Timeline>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot variant="filled">
                  <CheckIcon />
                </TimelineDot>
                <TimelineConnector className="success" />
              </TimelineSeparator>
              <TimelineContent className="done">
                <Box>
                  <Stack direction="row">
                    <Typography mr="auto">UCO Details</Typography>
                    <EnergySymbol />
                    <Typography>20 XP</Typography>
                  </Stack>
                  <Box>
                    <Typography>You&apos;ve Added!</Typography>
                  </Box>
                </Box>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  variant={step > 1 ? 'filled' : 'outlined'}
                  color="primary"
                >
                  {step > 1 ? <CheckIcon /> : <DotIcon strokeWidth={10} />}
                </TimelineDot>
                <TimelineConnector className={step > 1 ? 'success' : ''} />
              </TimelineSeparator>
              <TimelineContent className={step === 1 ? 'ongoing' : 'done'}>
                <Box>
                  <Stack direction="row">
                    <Typography mr="auto">Complete Profile</Typography>
                    <EnergySymbol />
                    <Typography>20 XP</Typography>
                    <IconButton
                      size="small"
                      color="raisinBlack"
                      onClick={handleEditProfile}
                    >
                      <EditIcon />
                    </IconButton>
                  </Stack>
                  <Box>
                    <Typography>
                      {username && age && city && gender && country ? (
                        <>
                          <span>{username}</span>
                          <br />
                          <span>
                            {age === '18_35'
                              ? '18 to 35 years old'
                              : age === '35_60'
                                ? '35 to 60 years old'
                                : age === 'gt_60'
                                  ? 'more than 60 years old'
                                  : ''}
                          </span>
                          <br />
                          <span>
                            {city ? city : ''}{' '}
                            {country ? getCountryData(country).name : ''}
                          </span>
                          <br />
                          <span>
                            {gender === 'male' && 'Male'}
                            {gender === 'female' && 'Female'}
                            {gender === 'other' && 'Other'}
                          </span>
                        </>
                      ) : (
                        'Undefined'
                      )}
                    </Typography>
                  </Box>
                </Box>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  color={step >= 2 ? 'primary' : 'grey'}
                  variant={
                    step === 2 ? 'outlined' : step > 2 ? 'filled' : 'outlined'
                  }
                >
                  {step === 2 ? (
                    <DotIcon strokeWidth={10} />
                  ) : step > 2 ? (
                    <CheckIcon />
                  ) : null}
                </TimelineDot>
                <TimelineConnector className={step > 2 ? 'success' : ''} />
              </TimelineSeparator>
              <TimelineContent
                className={step > 2 ? 'done' : step === 2 ? 'ongoing' : ''}
              >
                <Box>
                  <Stack direction="row">
                    <Typography mr="auto">Add Zealy ID</Typography>
                    <EnergySymbol />
                    <Typography>20 XP</Typography>
                    <IconButton
                      size="small"
                      color="raisinBlack"
                      disabled={step < 2}
                      onClick={handleEditZealyId}
                    >
                      <EditIcon />
                    </IconButton>
                  </Stack>
                  <Box>
                    <Typography>{zealyId ? zealyId : 'Undefined'}</Typography>
                  </Box>
                </Box>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  color={step >= 3 ? 'primary' : 'grey'}
                  variant={
                    step === 3 ? 'outlined' : step > 3 ? 'filled' : 'outlined'
                  }
                >
                  {step === 3 ? (
                    <DotIcon strokeWidth={10} />
                  ) : step > 3 ? (
                    <CheckIcon />
                  ) : null}
                </TimelineDot>
                <TimelineConnector className={step > 3 ? 'success' : ''} />
              </TimelineSeparator>
              <TimelineContent
                className={step > 3 ? 'done' : step === 3 ? 'ongoing' : ''}
              >
                <Box>
                  <Stack direction="row">
                    <Typography mr="auto">Join Telegram Channel</Typography>
                    <EnergySymbol />
                    <Typography>20 XP</Typography>
                    <IconButton
                      size="small"
                      color="raisinBlack"
                      disabled={step < 3 || joinedTelegram}
                      onClick={handleEditTelegramId}
                    >
                      <ExternalLinkIcon />
                    </IconButton>
                  </Stack>
                  <Box>
                    <Typography>
                      {joinedTelegram ? `You've joined!` : 'Undefined'}
                    </Typography>
                  </Box>
                </Box>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  color={step >= 4 ? 'primary' : 'grey'}
                  variant={
                    step === 4 ? 'outlined' : step > 4 ? 'filled' : 'outlined'
                  }
                >
                  {step === 4 ? (
                    <DotIcon strokeWidth={10} />
                  ) : step > 4 ? (
                    <CheckIcon />
                  ) : null}
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent
                className={step > 4 ? 'done' : step === 4 ? 'ongoing' : ''}
              >
                <Box>
                  <Stack direction="row">
                    <Typography mr="auto">Follow Oily on X</Typography>
                    <EnergySymbol />
                    <Typography>20 XP</Typography>
                    <form
                      action={
                        process.env.REACT_APP_API_SERVER +
                        `/api/client/v2/user/auth/x`
                      }
                      method="GET"
                    >
                      <input type="hidden" hidden name="userId" value={id} />
                      <IconButton
                        size="small"
                        color="raisinBlack"
                        disabled={step < 4 || followedTwitter}
                        type="submit"
                      >
                        <ExternalLinkIcon />
                      </IconButton>
                    </form>
                  </Stack>
                  <Box>
                    <Typography>Undefined</Typography>
                  </Box>
                </Box>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Box>
        <Stack mt={4} px={3} direction="row" alignItems="center">
          <Typography
            mr="auto"
            fontSize={16}
            fontWeight={700}
            lineHeight={1.75}
            color="raisinBlack"
          >
            Referral URL
          </Typography>
          <EnergySymbol
            width={16}
            height={16}
            color={theme.palette.raisinBlack}
          />
          <Typography fontSize={14} lineHeight={1.75} color="spanishGray">
            100XP
          </Typography>
          {level < 6 ? (
            <IconButton
              size="small"
              color="raisinBlack"
              sx={{
                ml: 1.5,
              }}
              onClick={() => handleToggleModal('referralDisabled')}
            >
              <InfoIcon />
            </IconButton>
          ) : null}
        </Stack>
        <Box px={3}>
          <Stack direction="row" alignItems="flex-start" mt={0.5}>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="raisinBlack"
              flex={1}
              mr={2}
              sx={{
                wordBreak: 'break-all',
              }}
            >
              {referralCode
                ? `${window.location.origin}/referral?code=${referralCode}`
                : 'Undefined'}
            </Typography>
            {referralCode ? (
              <IconButton
                size="small"
                color="raisinBlack"
                onClick={handleCopyReferral}
              >
                <CopyIcon />
              </IconButton>
            ) : null}
          </Stack>
        </Box>
        <Box mt={4} px={3}>
          <Typography
            fontSize={16}
            fontWeight={700}
            lineHeight={1.75}
            color="raisinBlack"
          >
            Wallet address
          </Typography>
          <Stack direction="row" alignItems="flex-start" mt={0.5}>
            <Typography
              fontSize={14}
              lineHeight={1.75}
              color="raisinBlack"
              flex={1}
              mr={2}
              sx={{
                wordBreak: 'break-all',
              }}
            >
              {account}
            </Typography>
            <IconButton
              size="small"
              color="raisinBlack"
              onClick={handleCopyWallet}
            >
              <CopyIcon />
            </IconButton>
          </Stack>
        </Box>
        <Box height={200} />
        <Modal
          open={modals.referralDisabled}
          onClose={() => handleToggleModal('referralDisabled')}
        >
          <Box>
            <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
              Unlock Your Referral Power! 🎯
            </Typography>
            <Typography
              mt={3}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              Reach Level 6 to unlock your referral link and earn 100 XP for
              each friend who joins and reaches Level 6!
            </Typography>
            <Box mt={11}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={() => handleToggleModal('referralDisabled')}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={modals.referralEnabled}
          onClose={() => handleToggleModal('referralEnabled')}
        >
          <Box>
            <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
              Ready to grow our impact?
            </Typography>
            <Typography
              mt={3}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              Share your link with friends who care about sustainability!
              You&apos;ll earn 100 XP for each new mapper who reaches Level 6.
              Together, we can map more restaurants and boost UCO recycling! 💪
            </Typography>
            <Box mt={11}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={() => handleToggleModal('referralEnabled')}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      </Scaffold.Content>
    </Scaffold>
  );
};

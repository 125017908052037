import React, { useCallback } from 'react';
import { Scaffold } from '../../components/layouts/Scaffold';
import {
  ButtonBase,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import { ArrowLeftIcon, XIcon } from 'lucide-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { apiGetArticles } from '../../apis/articles';

export const ArticlesPage = () => {
  const navigate = useNavigate();

  const { data: articles, isLoading } = useQuery({
    queryKey: ['articles', { limit: 100, skip: 0 }],
    queryFn: async () => {
      return await apiGetArticles({
        limit: 100,
        skip: 0,
      });
    },
  });

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Scaffold bgcolor="raisinBlack" height="100vh">
      <Scaffold.Header>
        <Stack
          direction="row"
          alignItems="center"
          px={2}
          pt={1}
          pb={2}
          minHeight={56}
        >
          <IconButton color="isabelline" size="small" onClick={handleBack}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography
            fontSize={14}
            color="isabelline"
            flex={1}
            mx={2}
            textAlign="center"
          >
            Latest News
          </Typography>
          <IconButton color="isabelline" size="small" onClick={handleBack}>
            <XIcon />
          </IconButton>
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Stack direction="column" spacing={1} mt={2} px={isLoading ? 3 : 0}>
          {isLoading
            ? Array(5)
                .fill(true)
                .map((item, index) => (
                  <Skeleton
                    key={`loading-${index}`}
                    variant="rounded"
                    width="100%"
                    height={90}
                  />
                ))
            : articles?.map((item, index) => (
                <ButtonBase
                  key={`article-${index}`}
                  sx={{
                    textAlign: 'left',
                    px: 3,
                    py: 1,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                  component={NavLink}
                  to={item.url}
                  target="_blank"
                >
                  <Box flex={1} mr={3}>
                    <Typography
                      fontSize={16}
                      lineHeight={1.5}
                      color="isabelline"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      fontSize={14}
                      lineHeight={1.25}
                      color="spanishGray"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                      }}
                    >
                      {item.description}
                    </Typography>
                  </Box>
                  <img
                    src={item.image_url ? item.image_url : '/logo512.png'}
                    alt="article"
                    style={{
                      aspectRatio: 1,
                      width: 80,
                      height: 80,
                      objectFit: 'cover',
                      borderRadius: 8,
                    }}
                  />
                </ButtonBase>
              ))}
        </Stack>
        <Box height={200} />
      </Scaffold.Content>
    </Scaffold>
  );
};

import axios from 'axios';

export const apiGetRaffles = async () => {
  try {
    const response = await axios.get('/api/client/v2/user/rounds');
    const data = response.data.data;
    if (data.data[0]?.type === 'four_week') {
      data.four_week_raffle = {
        wallet_address: data.data[0].wallet_address,
        amount: data.data[0].amount,
        next_datetime: data.for_week_raffle_end_datetime,
      };
      data.data = data.data.slice(1);
    } else if (data.in_this_four_week_raffle) {
      data.four_week_raffle = {
        wallet_address: null,
        amount: null,
        next_datetime: data.for_week_raffle_end_datetime,
      };
    }

    return data;
  } catch {
    return null;
  }
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isReady: false,
  isGlobalLoading: false,

  constants: {
    m2eCreatePlaceXp: 0,
    m2eCreateWmoXp: 0,
    l2eTotalXp: 0,
    l2eTotalChapters: 0,
    levelUpXp: 0,
  },

  dialogs: {
    levelUp1: false,
    levelUp2: false,
    levelUp3: false,
    levelUp4: false,
    levelUp5: false,
    levelUp6: false,
    levelUp7: false,
    levelUp8: false,
    levelUp9: false,
    levelUp10: false,
    mintNft: false,
    completeNft: false,
    prepareLevelUp1: false,
    prepareLevelUp2: false,
    prepareLevelUp3: false,
    prepareLevelUp4: false,
    prepareLevelUp5: false,
    prepareLevelUp6: false,
    prepareLevelUp7: false,
    prepareLevelUp8: false,
    prepareLevelUp9: false,
    prepareLevelUp10: false,
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setAppStatus(state, action) {
      return action.payload;
    },
    updateAppStatus(state, action) {
      return { ...state, ...action.payload };
    },
    setAppConstants(state, action) {
      state.constants = {
        ...state.constants,
        ...action.payload,
      };
    },
    toggleLevelUpDialog(state, action) {
      state.dialogs = {
        ...state.dialogs,
        prepareLevelUp1: false,
        prepareLevelUp2: false,
        prepareLevelUp3: false,
        prepareLevelUp4: false,
        prepareLevelUp5: false,
        prepareLevelUp6: false,
        prepareLevelUp7: false,
        prepareLevelUp8: false,
        prepareLevelUp9: false,
        prepareLevelUp10: false,
        [`levelUp${action.payload.level}`]:
          !state.dialogs[`levelUp${action.payload.level}`],
      };
    },
    toggleFeatureDialog(state, action) {
      state.dialogs = {
        ...state.dialogs,
        [action.payload.name]: !state.dialogs[action.payload.name],
      };
    },
  },
});

export const {
  setAppStatus,
  updateAppStatus,
  setAppConstants,
  toggleLevelUpDialog,
  toggleFeatureDialog,
} = appSlice.actions;
